import { Action, createSelector } from '@reduxjs/toolkit';
import { PlanModel } from '@app/modules/soft-launch/models/PlanModel';
import { FeatureModel } from '@app/modules/soft-launch/models/FeatureModel';
import { TargetPhaseModel } from '@app/modules/soft-launch/models/TargetPhaseModel';
import {
  StoreTargetCountryModel,
  TargetCountryModel,
} from '@app/modules/soft-launch/models/TargetCountryModel';
import { put, takeLatest, select, call, debounce } from 'redux-saga/effects';
import { RootState } from '@app/setup';
import update from 'immutability-helper';
import { CampaignTypeModel } from '@app/modules/soft-launch/models/CampaignTypeModel';
import { CampaignGoalModel } from '@app/modules/soft-launch/models/CampaignGoalModel';
import { pick } from 'lodash';
import { CampaignModel } from '@app/modules/soft-launch/models/CampaignModel';
import { CreateCampaignRequestModel } from '@app/modules/soft-launch/models/CreateCampaignRequestModel';
import services from '@app/modules/soft-launch/services';

export interface ActionWithPayload<T> extends Action {
  payload?: T;
  key?: string;
}

export interface SoftLaunchState {
  data: {
    plans: PlanModel[];
    features: FeatureModel[];
    targetPhases: TargetPhaseModel[];
    targetCountries: TargetCountryModel[];
  };
  draft: boolean;
  draftId: number | null;
  game: number | null;
  type: CampaignTypeModel | null;
  goal: CampaignGoalModel | null;
  plan: PlanModel | null;
  features: number[];
  actionsCount: number | null;
  startDate: string | null;
  manualTargeting: boolean;
  technicalPhaseActionsCount: number | null;
  retentionPhaseActionsCount: number | null;
  targetCountries: StoreTargetCountryModel[];
  otherRequirementsFromUser: string | null;
  totalCost: number;
  totalActionsCost: number;
  adClickDestination: string | null;
  adClickDestinationLink: string | null;
}

const initialState: SoftLaunchState = {
  data: {
    plans: [],
    features: [],
    targetPhases: [],
    targetCountries: [],
  },
  draft: false,
  draftId: null,
  game: null,
  type: null,
  goal: null,
  plan: null,
  features: [],
  actionsCount: null,
  startDate: null,
  manualTargeting: true,
  technicalPhaseActionsCount: null,
  retentionPhaseActionsCount: null,
  targetCountries: [],
  otherRequirementsFromUser: null,
  totalCost: 0,
  totalActionsCost: 0,
  adClickDestination: null,
  adClickDestinationLink: null,
};

export enum SoftLaunchDataTypes {
  Plans = 'plans',
  Features = 'features',
  TargetPhases = 'targetPhases',
  TargetCountries = 'targetCountries',
}

export const actionTypes = {
  Reset: '[SOFT_LAUNCH][RESET]',
  SetData: '[SOFT_LAUNCH][SET_DATA]',
  ChangeGame: '[SOFT_LAUNCH][CHANGE_GAME]',
  SetGame: '[SOFT_LAUNCH][SET_GAME]',
  LoadDraft: '[SOFT_LAUNCH][LOAD_DRAFT]',
  SetDraft: '[SOFT_LAUNCH][SET_DRAFT]',
  SetType: '[SOFT_LAUNCH][SET_TYPE]',
  SetGoal: '[SOFT_LAUNCH][SET_GOAL]',
  ChangeTypeAndGoal: '[SOFT_LAUNCH][CHANGE_TYPE_AND_GOAL]',
  SetPlan: '[SOFT_LAUNCH][SET_PLAN]',
  ChangeFeature: '[SOFT_LAUNCH][CHANGE_FEATURE]',
  SetFeatures: '[SOFT_LAUNCH][SET_FEATURES]',
  ChangeActionsCount: '[SOFT_LAUNCH][CHANGE_ACTIONS_COUNT]',
  SetActionsCount: '[SOFT_LAUNCH][SET_ACTIONS_COUNT]',
  SetStartDate: '[SOFT_LAUNCH][SET_START_DATE]',
  SetManualTargeting: '[SOFT_LAUNCH][SET_MANUAL_TARGETING]',
  ChangeTargetCountry: '[SOFT_LAUNCH][CHANGE_TARGET_COUNTRY]',
  SetTargetCountries: '[SOFT_LAUNCH][SET_TARGET_COUNTRIES]',
  SetOtherRequirementsFromUser: '[SOFT_LAUNCH][SET_OTHER_REQUIREMENTS_FROM_USER]',
  ChangePhaseActionsCount: '[SOFT_LAUNCH][CHANGE_PHASE_ACTIONS_COUNT]',
  SetPhaseActionsCount: '[SOFT_LAUNCH][SET_PHASE_ACTIONS_COUNT]',
  SetCost: '[SOFT_LAUNCH][SET_COST]',
  RefreshCost: '[SOFT_LAUNCH][REFRESH_COST]',
  SetAdClickDestination: '[SOFT_LAUNCH][SET_AD_CLICK_DESTINATION]',
  SetAdClickDestinationLink: '[SOFT_LAUNCH][SET_AD_CLICK_DESTINATION_LINK]',
};

export const reducer = (
  state: SoftLaunchState = initialState,
  action: ActionWithPayload<SoftLaunchState | any>
) => {
  switch (action.type) {
    case actionTypes.Reset: {
      return {
        ...initialState,
        ...pick(state, action.payload.keep),
      };
    }
    case actionTypes.SetData: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: action.payload.data,
        },
      };
    }
    case actionTypes.SetGame: {
      return {
        ...state,
        game: action.payload.game,
      };
    }
    case actionTypes.SetDraft: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.SetType: {
      return {
        ...state,
        type: action.payload.type,
      };
    }
    case actionTypes.SetGoal: {
      return {
        ...state,
        goal: action.payload.goal,
      };
    }
    case actionTypes.SetPlan: {
      return {
        ...state,
        plan: action.payload.plan,
      };
    }
    case actionTypes.SetFeatures: {
      return {
        ...state,
        features: action.payload.features,
      };
    }
    case actionTypes.SetActionsCount: {
      return {
        ...state,
        actionsCount: action.payload.actionsCount,
      };
    }
    case actionTypes.SetStartDate: {
      return {
        ...state,
        startDate: action.payload.startDate,
      };
    }
    case actionTypes.SetManualTargeting: {
      return {
        ...state,
        manualTargeting: action.payload.manualTargeting,
      };
    }
    case actionTypes.SetPhaseActionsCount: {
      return {
        ...state,
        technicalPhaseActionsCount: action.payload.technicalPhaseActionsCount,
        retentionPhaseActionsCount: action.payload.retentionPhaseActionsCount,
      };
    }
    case actionTypes.SetTargetCountries: {
      return {
        ...state,
        targetCountries: action.payload.targetCountries,
      };
    }
    case actionTypes.SetOtherRequirementsFromUser: {
      return {
        ...state,
        otherRequirementsFromUser: action.payload.otherRequirementsFromUser,
      };
    }
    case actionTypes.SetCost: {
      return {
        ...state,
        totalCost: action.payload.totalCost,
        totalActionsCost: action.payload.totalActionsCost,
      };
    }
    case actionTypes.SetAdClickDestination: {
      return {
        ...state,
        adClickDestination: action.payload.adClickDestination,
      };
    }
    case actionTypes.SetAdClickDestinationLink: {
      return {
        ...state,
        adClickDestinationLink: action.payload.adClickDestinationLink,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  reset: (keep: string[]) => ({ type: actionTypes.Reset, payload: { keep } }),
  setData: (key: string, value: object[]) => ({
    type: actionTypes.SetData,
    payload: { data: value },
    key,
  }),
  changeGame: (game: number) => ({
    type: actionTypes.ChangeGame,
    payload: { game },
  }),
  setGame: (game: number) => ({ type: actionTypes.SetGame, payload: { game } }),
  setDraft: (payload) => ({
    type: actionTypes.SetDraft,
    payload,
  }),
  loadDraft: (campaign, draftId) => ({
    type: actionTypes.LoadDraft,
    payload: { campaign, draftId },
  }),
  setType: (type: CampaignTypeModel) => ({
    type: actionTypes.SetType,
    payload: { type },
  }),
  setGoal: (goal: CampaignGoalModel) => ({
    type: actionTypes.SetGoal,
    payload: { goal },
  }),
  changeTypeAndGoal: (type: CampaignTypeModel, goal: CampaignGoalModel) => ({
    type: actionTypes.ChangeTypeAndGoal,
    payload: { type, goal },
  }),
  setPlan: (plan: PlanModel) => ({
    type: actionTypes.SetPlan,
    payload: { plan },
  }),
  changeFeature: (feature: number) => ({
    type: actionTypes.ChangeFeature,
    payload: { feature },
  }),
  setFeatures: (features: number[]) => ({
    type: actionTypes.SetFeatures,
    payload: { features },
  }),
  changeActionsCount: (actionsCount: number) => ({
    type: actionTypes.ChangeActionsCount,
    payload: { actionsCount },
  }),
  setActionsCount: (actionsCount: number) => ({
    type: actionTypes.SetActionsCount,
    payload: { actionsCount },
  }),
  setStartDate: (startDate: string) => ({
    type: actionTypes.SetStartDate,
    payload: { startDate },
  }),
  setManualTargeting: (manualTargeting: boolean) => ({
    type: actionTypes.SetManualTargeting,
    payload: { manualTargeting },
  }),
  changePhaseActionsCount: (
    technicalPhaseActionsCount: number,
    retentionPhaseActionsCount: number
  ) => ({
    type: actionTypes.ChangePhaseActionsCount,
    payload: { technicalPhaseActionsCount, retentionPhaseActionsCount },
  }),
  setPhaseActionsCount: (
    technicalPhaseActionsCount: number,
    retentionPhaseActionsCount: number
  ) => ({
    type: actionTypes.SetPhaseActionsCount,
    payload: { technicalPhaseActionsCount, retentionPhaseActionsCount },
  }),
  setOtherRequirementsFromUser: (otherRequirementsFromUser: string) => ({
    type: actionTypes.SetOtherRequirementsFromUser,
    payload: { otherRequirementsFromUser },
  }),
  changeTargetCountry: (targetCountry: StoreTargetCountryModel) => ({
    type: actionTypes.ChangeTargetCountry,
    payload: { targetCountry },
  }),
  setTargetCountries: (targetCountries: StoreTargetCountryModel[]) => ({
    type: actionTypes.SetTargetCountries,
    payload: { targetCountries },
  }),
  setCost: (totalCost: number, totalActionsCost: number) => ({
    type: actionTypes.SetCost,
    payload: { totalCost, totalActionsCost },
  }),
  refreshCost: () => ({
    type: actionTypes.RefreshCost,
  }),
  setAdClickDestination: (adClickDestination: string | null) => ({
    type: actionTypes.SetAdClickDestination,
    payload: { adClickDestination },
  }),
  setAdClickDestinationLink: (adClickDestinationLink: string | null) => ({
    type: actionTypes.SetAdClickDestinationLink,
    payload: { adClickDestinationLink },
  }),
};

const selfSelector = (state: RootState) => state.softLaunch;

export const selectorData = createSelector(selfSelector, (softLaunch) => softLaunch.data);
export const selectorFeatures = createSelector(selfSelector, (softLaunch) => softLaunch.features);
export const selectorActionsCount = createSelector(
  selfSelector,
  (softLaunch) => softLaunch.actionsCount
);
export const selectorTargetCountries = createSelector(
  selfSelector,
  (softLaunch) => softLaunch.targetCountries
);
export const selectorCampaign = createSelector(
  selfSelector,
  ({
    draft,
    draftId,
    game,
    type,
    goal,
    plan,
    features,
    actionsCount,
    startDate,
    manualTargeting,
    technicalPhaseActionsCount,
    retentionPhaseActionsCount,
    targetCountries,
    otherRequirementsFromUser,
    totalCost,
    totalActionsCost,
    adClickDestination,
    adClickDestinationLink,
  }) => ({
    draft,
    draftId,
    game,
    type,
    goal,
    plan,
    features,
    actionsCount,
    startDate,
    manualTargeting,
    technicalPhaseActionsCount,
    retentionPhaseActionsCount,
    targetCountries,
    otherRequirementsFromUser,
    totalCost,
    totalActionsCost,
    adClickDestination,
    adClickDestinationLink,
  })
);

export function* saga() {
  yield takeLatest(
    actionTypes.ChangeGame,
    function* changeGameSaga({ payload: { game } }: ActionWithPayload<{ game: number }>) {
      yield put(actions.reset([]));
      yield put(actions.setGame(game));
    }
  );
  yield takeLatest(
    actionTypes.LoadDraft,
    function* loadDraftSaga({
      payload: { campaign, draftId },
    }: ActionWithPayload<{
      campaign: CampaignModel;
      draftId: number | null;
    }>) {
      yield put(actions.reset(['data']));

      yield put(
        actions.setDraft({
          draft: true,
          draftId,
          game: campaign.game.id,
          type: campaign.type,
          goal: campaign.goal,
          plan: campaign.plan,
          features: campaign.features ? campaign.features.map((v) => v.feature.id) : [],
          actionsCount: campaign.purchasedActionsCount,
          startDate: campaign.startDate,
          manualTargeting: campaign.manualTargeting,
          technicalPhaseActionsCount: campaign.technicalPhaseActionsCount || 0,
          retentionPhaseActionsCount: campaign.retentionPhaseActionsCount || 0,
          targetCountries: campaign.targetCountries
            ? campaign.targetCountries.map((v) => ({
                targetCountry: v.targetCountry.id,
                phase: v.phase ? v.phase.id : null,
                pricePerAction: v.targetCountry.pricePerAction,
              }))
            : [],
          otherRequirementsFromUser: campaign.otherRequirementsFromUser,
          adClickDestination: campaign.adClickDestination
            ? String(campaign.adClickDestination.id)
            : null,
          adClickDestinationLink: campaign.adClickDestinationLink,
        })
      );
    }
  );
  yield takeLatest(
    actionTypes.ChangeTypeAndGoal,
    function* changeTypeAndGoalSaga({
      payload: { type, goal },
    }: ActionWithPayload<{ type: CampaignTypeModel; goal: CampaignGoalModel }>) {
      yield put(actions.reset(['game', 'draftId']));
      yield put(actions.setType(type));
      yield put(actions.setGoal(goal));
    }
  );
  yield takeLatest(
    actionTypes.ChangeFeature,
    function* changeFeatureSaga({ payload: { feature } }: ActionWithPayload<{ feature: number }>) {
      const features = yield select(selectorFeatures);
      const index = features.indexOf(feature);

      yield put(
        actions.setFeatures(
          index !== -1
            ? update(features, { $splice: [[index, 1]] })
            : update(features, { $push: [feature] })
        )
      );

      yield put(actions.refreshCost());
    }
  );
  yield takeLatest(
    actionTypes.ChangeActionsCount,
    function* changeActionsCountSaga({
      payload: { actionsCount },
    }: ActionWithPayload<{ actionsCount: number }>) {
      yield put(actions.setActionsCount(actionsCount));
      yield put(
        actions.changePhaseActionsCount((actionsCount * 10) / 100, (actionsCount * 50) / 100)
      );
    }
  );
  yield takeLatest(
    actionTypes.ChangePhaseActionsCount,
    function* changePhaseActionsCountSaga({
      payload: { technicalPhaseActionsCount, retentionPhaseActionsCount },
    }: ActionWithPayload<{
      technicalPhaseActionsCount: number;
      retentionPhaseActionsCount: number;
    }>) {
      yield put(
        actions.setPhaseActionsCount(
          Math.round(technicalPhaseActionsCount),
          Math.round(retentionPhaseActionsCount - technicalPhaseActionsCount)
        )
      );

      yield put(actions.refreshCost());
    }
  );
  yield takeLatest(
    actionTypes.ChangeTargetCountry,
    function* changeTargetCountrySaga({
      payload: { targetCountry },
    }: ActionWithPayload<{ targetCountry: StoreTargetCountryModel }>) {
      const countries = yield select(selectorTargetCountries);

      const index = countries.findIndex(
        (c) => c.targetCountry === targetCountry.targetCountry && c.phase == targetCountry.phase
      );

      yield put(
        actions.setTargetCountries(
          index !== -1
            ? update(countries, { $splice: [[index, 1]] })
            : update(countries, { $push: [targetCountry] })
        )
      );

      yield put(actions.refreshCost());
    }
  );
  yield debounce(300, actionTypes.RefreshCost, function* refreshCostSaga() {
    try {
      const campaign = yield select(selectorCampaign);

      const body: CreateCampaignRequestModel = {
        game: campaign.game,
        type: campaign.type.id,
        goal: campaign.goal.id,
        plan: campaign.plan.id,
        manualTargeting: campaign.manualTargeting,
        features: campaign.features.map((id) => ({
          feature: id,
        })),
        purchasedActionsCount: campaign.actionsCount,
        preventSave: true,
      };

      if (campaign.manualTargeting) {
        body.technicalPhaseActionsCount = campaign.technicalPhaseActionsCount;
        body.retentionPhaseActionsCount = campaign.retentionPhaseActionsCount;
        body.targetCountries = campaign.targetCountries;
      } else {
        body.technicalPhaseActionsCount = null;
        body.retentionPhaseActionsCount = null;
        body.targetCountries = [];
      }

      const response = yield call(services.campaign.createCampaign, body);

      yield put(actions.setCost(response.totalCost, response.totalActionsCost));
    } catch (error) {
      console.log(error);
    }
  });
}
