import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useLayout } from '@shared/metronic/layout/core';
import { KTSVG } from '@shared/metronic/helpers';
import { AsideMenu } from '@shared/metronic/layout/components/aside/AsideMenu';
import { RootState, useAppSelector } from '@app/setup';
import { UserModel } from '@app/modules/auth/models/UserModel';
import { UserMenu } from '@shared/metronic/layout/components/aside/UserMenu';
import { assetApiPath, assetPath } from '@shared/utils/asset';
import CurrentPlan from '@shared/metronic/layout/components/aside/CurrentPlan';
import { useSelector } from 'react-redux';

const AsideDefault: FC = () => {
  const user: UserModel = useAppSelector(({ auth }) => auth.user) as UserModel;
  const currentPlan = useSelector(({ auth }: RootState) => auth.plan);
  const { config, classes } = useLayout();
  const ref = useRef(null);
  const { aside } = config;

  return (
    <div>
      <div
        id="kt_aside"
        className={clsx('aside', classes.aside.join(' '))}
        style={{ backgroundImage: `url(${assetPath('/asort/bg.jpg')}` }}
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      >
        <div className="overflow-lg-hidden">
          {/* begin::Brand */}
          <div className="aside-logo flex-column-auto" id="kt_aside_logo">
            {/* begin::Logo */}
            <Link to="/dashboard">
              <img alt="Logo" className="h-24 logo" src={assetPath('/logos/logo-white.png')} />
              <img
                alt="Logo"
                className="h-24 logo-minimize"
                src={assetPath('/logos/logo-short-white.png')}
              />
            </Link>
            {/* end::Logo */}
          </div>
          {/* end::Brand */}
          {currentPlan && <CurrentPlan />}
          {/* begin::Aside menu */}
          <div className="aside-menu flex-column-fluid">
            <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
          </div>
          {/* end::Aside menu */}

          {/* begin::Footer */}
          {user && (
            <div className="aside-footer flex-column-auto" id="kt_aside_footer">
              <button
                type="button"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="top-start"
                className="fs-14 lh-f14 text-white text-hover-white ps-0 text-start d-flex align-items-center"
              >
                <div className="symbol symbol-circle symbol-35 bg-white">
                  <img
                    src={
                      user.avatar
                        ? assetApiPath(user.avatar)
                        : assetPath('/asort/user-avatar-blank.png')
                    }
                    alt="user avatar"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;

                      target.src = assetPath('/asort/user-avatar-blank.png');
                    }}
                  />
                </div>
                <span className="ms-16 user-name">{user.nick || user.email}</span>
              </button>
              <UserMenu />
            </div>
          )}

          {/* end::Footer */}
        </div>

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <button
            type="button"
            id="kt_aside_toggle"
            className="aside-toggle d-none d-lg-inline-flex btn btn-icon min-h-auto"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
            ref={ref}
          >
            <KTSVG
              path="/menu/arrow.svg"
              className="d-inline-block svg-icon svg-icon-8 rotate-180 lh-0 svg-icon-primary"
              svgClassName="h-8"
            />
          </button>
        )}
        {/* end::Aside toggler */}
      </div>
    </div>
  );
};

export { AsideDefault };
