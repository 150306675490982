import React from 'react';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import { addOne as addNotification } from '@shared/features/notifications/notificationsSlice';
import { AlertType } from '@shared/enums/AlertType';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

type Props = {
  code: string;
  large?: boolean;
};

const defaultProps = {
  large: false,
};

const CodeMarkup = ({ code, large }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCopy = () => {
    navigator.clipboard.writeText(code);

    dispatch(
      addNotification({
        id: `copy.${new Date().getTime()}`,
        type: AlertType.Success,
        message: t('common:alerts.copied', 'Copied!'),
      })
    );
  };

  return (
    <div className="alert border border-dashed bg-light-info border-info px-20 py-12 mb-0 d-flex justify-content-between align-items-center">
      <p
        className={clsx('text-gray-900 text-break', {
          'fs-12 lh-f12': !large,
          'fs-14 lh-f14': large,
        })}
      >
        {code}
      </p>
      <button type="button" onClick={handleCopy} className="ms-10">
        <SVG src={assetPath('/icons/copy.svg')} />
      </button>
    </div>
  );
};

CodeMarkup.defaultProps = defaultProps;

export default CodeMarkup;
