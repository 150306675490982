import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '@shared/metronic/partials';
import { useKompassify } from '@shared/hooks';

export function PrivateRoutes() {
  useKompassify([{ path: '/dashboard', id: '82d4049a-a9cf-47f7-a369-0f17bb6e4a34' }]);

  const InfoModule = lazy(() => import('@shared/modules/info/InfoModule'));
  const DashboardModule = lazy(() => import('@app/modules/dashboard/DashboardModule'));
  const AccountModule = lazy(() => import('@app/modules/account/AccountModule'));
  const SoftLaunchModule = lazy(() => import('@app/modules/soft-launch/SoftLaunchModule'));
  const GameCheckerModule = lazy(() => import('@app/modules/game-checker/GameCheckerModule'));
  const GamesModule = lazy(() => import('@app/modules/games/GamesModule'));
  const FinancesModule = lazy(() => import('@app/modules/finances/FinancesModule'));
  const ReportsModule = lazy(() => import('@app/modules/reports/ReportsModule'));
  const DigitalFirstModule = lazy(() => import('@app/modules/digitalfirst/DigitalFirstModule'));
  const ProductsModule = lazy(() => import('@app/modules/products/ProductsModule'));
  const CartModule = lazy(() => import('@app/modules/cart/CartModule'));
  const MarketingStrategyModule = lazy(
    () => import('@app/modules/marketing-strategy/MarketingStrategyModules')
  );

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardModule} />
        <Route path="/account" component={AccountModule} />
        <Route path="/soft-launch" component={SoftLaunchModule} />
        <Route path="/games" component={GamesModule} />
        <Route path="/info" component={InfoModule} />
        <Route path="/game-checker" component={GameCheckerModule} />
        <Route path="/finances" component={FinancesModule} />
        <Route path="/reports" component={ReportsModule} />
        <Route path="/digitalfirst" component={DigitalFirstModule} />
        <Route path="/marketplace" component={ProductsModule} />
        <Route path="/cart" component={CartModule} />
        <Route path="/marketing-strategy" component={MarketingStrategyModule} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
