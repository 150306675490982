import React from 'react';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';

type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
  color?: string;
};

const KTSVG: React.FC<Props> = ({ className = '', path, color, svgClassName = 'mh-50px' }) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={assetPath(path)} className={svgClassName} color={color} />
    </span>
  );
};

export { KTSVG };
