import React from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './style.css';
import { LeftArrow, RightArrow } from './Arrows/Arrows';
import { onWheel, usePreventBodyScroll } from './helpers';
import { ItemType } from './models/SliderModels';

const Slider = ({ children }: { children: React.ReactNode }) => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  return (
    <>
      <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
          {children as ItemType}
        </ScrollMenu>
      </div>
    </>
  );
};

export default Slider;
