import React from 'react';
import { KTSVG } from '@shared/metronic/helpers';
import clsx from 'clsx';
import { StatsModel } from '@shared/models/StatsModel';

type Props = {
  stats: StatsModel;
  short?: boolean;
  small?: boolean;
};

const defaultProps = {
  short: false,
  small: false,
};

const Item = ({ stats, short, small }: Props) => {
  return (
    <div>
      <p className="fs-12 lh-f12 fw-bold text-gray-400 mb-4">{stats.name || '\u00A0'}</p>
      <div className="d-flex flex-column flex-xxxl-row align-items-baseline">
        <p
          className={clsx('fw-bold me-12', {
            'fs-18 lh-f18': !small,
            'fs-14 lh-f14': small,
          })}
        >
          {stats.value}
        </p>
        {!short && stats.changePercentage !== null && (
          <div className="d-flex">
            <KTSVG
              path={
                stats.changePercentage > 0
                  ? '/asort/icons/arrow-short-up.svg'
                  : '/asort/icons/arrow-short-down.svg'
              }
              className={clsx('svg-icon-8 me-4', {
                'svg-icon-success': stats.changePercentage > 0,
                'svg-icon-danger': stats.changePercentage < 0,
              })}
            />
            <p
              className={clsx('fs-12 lh-f12', {
                'text-success': stats.changePercentage > 0,
                'text-danger': stats.changePercentage < 0,
                'text-info': stats.changePercentage === 0,
              })}
            >
              {stats.changePercentage}%
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

Item.defaultProps = defaultProps;

export default Item;
