import React, { useCallback, useEffect, useState } from 'react';
import styles from './Counter.module.scss';
import clsx from 'clsx';

type Props = {
  quantity: number;
  className?: string;
  onUpdate: (count: number) => void;
};

const Counter: React.FC<Props> = ({ quantity, className, onUpdate }) => {
  const [count, setCount] = useState<number>(0);

  const increase = useCallback(() => {
    setCount((prevCount) => {
      const newCount = prevCount + 1;
      onUpdate(newCount);
      return newCount;
    });
  }, [onUpdate]);

  const decrease = useCallback(() => {
    setCount((prevCount) => {
      if (prevCount === 0) return 0;
      const newCount = prevCount - 1;
      onUpdate(newCount);
      return newCount;
    });
  }, [onUpdate]);

  useEffect(() => {
    if (!Number.isNaN(quantity)) {
      setCount(quantity);
    }
  }, [quantity]);

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.char} onClick={decrease}>
        -
      </div>
      <div className={styles.count}>{count}</div>
      <div className={styles.char} onClick={increase}>
        +
      </div>
    </div>
  );
};

export default Counter;
