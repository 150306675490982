import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { assetPath } from '@shared/utils/asset';
import SVG from 'react-inlinesvg';

const UserMenu: FC = () => {
  const { t } = useTranslation(['common']);

  return (
    <div
      className="menu menu-user menu-sub menu-sub-dropdown menu-column menu-rounded py-16 px-12 mb-12"
      data-kt-menu="true"
      style={{ width: '200px', maxWidth: '200px' }}
    >
      <div className="menu-item">
        <Link to={'/account'} className="menu-link">
          <span className="menu-icon">
            <SVG src={assetPath('/icons/settings.svg')} />
          </span>
          <span className="menu-title">{t('common:user_menu.settings', 'Settings')}</span>
        </Link>
      </div>
      <div className="menu-item">
        <Link to={'/logout'} className="menu-link text-body fs-14 lh-f14">
          <span className="menu-icon">
            <SVG src={assetPath('/icons/logout.svg')} />
          </span>
          <span className="menu-title">{t('common:user_menu.logout', 'Log out')}</span>
        </Link>
      </div>
    </div>
  );
};

export { UserMenu };
