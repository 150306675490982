import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import style from './Accordion.module.scss';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import { useLocation } from 'react-router';
import { MenuComponent } from '@shared/metronic/assets/ts/components';

type Props = {
  title: string | React.ReactNode;
  children: string | React.ReactNode;
  className?: string | React.ReactNode;
};

const defaultProps = {
  className: '',
};

const Accordion = ({ title, children, className }: Props) => {
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [pathname]);

  return (
    <div
      id="#kt_aside_menu"
      data-kt-menu="true"
      className={`menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 my-auto ${style.wrapper} ${className}`}
    >
      <div
        className={clsx('menu-item', { 'here show': false }, 'menu-accordion', `${style.item}`)}
        data-kt-menu-trigger="click"
        onClick={() => setIsActive((prev) => !prev)}
      >
        <span className="menu-link">
          <span className="menu-title fs-14 lh-f14 fw-bold">{title}</span>
          <SVG
            src={assetPath('/icons/arrow-accordion-big.svg')}
            className={`${style.icon} ${isActive ? style.iconActive : ''}`}
          />
        </span>
        <div className={clsx('menu-sub menu-sub-accordion px-10', { 'menu-active-bg': false })}>
          {children}
        </div>
      </div>
    </div>
  );
};

Accordion.defaultProps = defaultProps;

export default Accordion;
