import React, { FC } from 'react';
import { assetPath } from '@shared/utils/asset';
import { useHistory } from 'react-router-dom';

const Error500: FC = () => {
  const history = useHistory();
  const redirectToDashboard = () => {
    history.push('/');
  };
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-column-fluid text-center mt-96 p-16">
          <a href="/dashboard" className="mb-32">
            <img alt="Logo" src={assetPath('/logos/logo.png')} className="mh-48" />
          </a>
          <div className="pt-lg-10 mb-10">
            <h1 className="fs-18 mb-16">System Error</h1>

            <div className="mb-32">
              Something went wrong! <br /> Please try again later.
            </div>
          </div>
          <div className="text-center">
            <a onClick={redirectToDashboard} className="btn btn-lg btn-primary fw-bolder">
              Go to homepage
            </a>
          </div>
        </div>

        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://keenthemes.com" className="text-muted text-hover-primary px-2">
              About
            </a>
            <a href="mailto:support@keenthemes.com" className="text-muted text-hover-primary px-2">
              Contact
            </a>
            <a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error500;
