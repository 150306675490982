export const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      border: '1px solid #3e51e50d',
      minHeight: '40px',
      borderRadius: '10px',
      backgroundColor: '#3e51e50d',
      opacity: state.isDisabled ? '0.5' : 1,
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#3e51e50d',
      },
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: '4px 0 4px 16px',
      fontSize: '14px',
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: '#172047b3',
      padding: 0,
      marginRight: '8px',
      width: '16px',
    };
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      color: '#172047b3',
      padding: 0,
      marginRight: '8px',
      width: '16px',
    };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      marginRight: '8px',
    };
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#3E51E51A',
      borderRadius: '10px',
      padding: '1px 4px',
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: '#172047b3',
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      color: '#172047b3',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#172047b3',
      },
    };
  },
  option: (provided) => {
    return {
      ...provided,
      color: '#172047b3',
      fontSize: '14px',
      borderRadius: '10px',
      padding: '6px 16px',
      '&:hover': {
        backgroundColor: '#3E51E51A',
      },
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      borderRadius: '10px',
      border: '1px solid #3E51E51A',
      boxShadow: 'none',
      backgroundColor: '#fff',
      padding: '4px',
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      fontSize: '14px',
      color: '#172047b3',
      fontWeight: '400',
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      backgroundColor: 'transparent',
    };
  },
  noOptionsMessage: (provided) => {
    return {
      ...provided,
      fontSize: '14px',
      color: '#172047b3',
      fontWeight: '400',
      padding: '4px 16px',
    };
  },
  loadingMessage: (provided) => {
    return {
      ...provided,
      fontSize: '14px',
      color: '#172047b3',
      fontWeight: '400',
      padding: '4px 16px',
    };
  },
};
