import React, { useState } from 'react';
import { ProductModel } from '../../models/ProductModel';
import styles from './AddToCartModal.module.scss';
import { useTranslation } from 'react-i18next';
import { checkout } from '@app/modules/cart/services';
import apiErrorParser from '@shared/utils/apiErrorParser';
import { addOne as addNotification } from '@shared/features/notifications/notificationsSlice';
import { useDispatch } from 'react-redux';
import { AlertType } from '@shared/enums/AlertType';
import { assetApiPath } from '@shared/utils/asset';

type AddToCartModalProps = {
  product: ProductModel;
  quantity: number;
  formatter: (currency: string) => Intl.NumberFormat;
  handleClose: () => void;
  noSummary?: boolean;
};

const AddToCartModal: React.FC<AddToCartModalProps> = ({
  product,
  quantity,
  formatter,
  handleClose,
  noSummary,
}) => {
  const { t } = useTranslation('products');
  const dispatch = useDispatch();
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

  const goToCheckout = async () => {
    try {
      setIsCheckoutLoading(true);
      const response = await checkout();

      if (response.url) {
        window.location.replace(response.url);
      }
    } catch (error) {
      const { message } = apiErrorParser(error, dispatch);

      dispatch(
        addNotification({
          id: 'cart.update',
          type: AlertType.Danger,
          message,
        })
      );
      setIsCheckoutLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('products.addedToCart', 'Successfully added to cart')}</div>
      <div className={styles.product}>
        {product.image?.path ? (
          <img src={assetApiPath(product.image?.path)} alt={product.name} />
        ) : null}
        <div className={styles.name}>{product.name}</div>
        <span className={styles.quantity}>Qty: {quantity}</span>
        <span className={styles.price}>
          {formatter(product.currency).format(Number(product.formattedPrice))}
        </span>
      </div>
      {!noSummary ? (
        <div className={styles.total}>
          <span>
            {t('products.total_items', 'Total ({{count}} items)', { count: quantity })}:{' '}
            <b>{formatter(product.currency).format(Number(product.formattedPrice) * quantity)}</b>
          </span>
        </div>
      ) : null}
      <div className={styles.buttons}>
        <button className="btn btn-outline btn-sm" onClick={handleClose}>
          {t('products.continue_shoping', 'Continue shopping')}
        </button>
        <button
          className="btn btn-primary btn-sm"
          onClick={goToCheckout}
          disabled={isCheckoutLoading}
        >
          {t('products.go_to_checkout', 'Go to checkout')}
        </button>
      </div>
    </div>
  );
};

export default AddToCartModal;
