import React from 'react';
import clsx from 'clsx';
import type { FieldValues, UseFormRegister } from 'react-hook-form';

type Props = {
  type?: 'text';
  name: string;
  register?: UseFormRegister<FieldValues>;
  error?: string;
  placeholder?: string;
  label?: string;
  secondary?: boolean;
  rows?: number;
  [x: string]: any;
};

const defaultProps = {
  register: () => {},
  error: '',
  placeholder: '',
  label: '',
  secondary: false,
  rows: 8,
};

const TextArea = ({
  name,
  register,
  error,
  placeholder,
  label,
  secondary,
  className,
  rows,
  ...rest
}: Props) => (
  <div>
    {label && (
      <label htmlFor={`input-id-${name}`} className="fs-12 lh-f12 fw-bold mb-10">
        {label}
      </label>
    )}
    <textarea
      style={{ resize: 'none' }}
      rows={rows}
      id={`input-id-${name}`}
      placeholder={placeholder}
      className={clsx('form-control h-auto', {
        'is-invalid': !!error,
        'form-control-solid': !secondary,
      })}
      {...register(name)}
      {...rest}
    />
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
);

TextArea.defaultProps = defaultProps;

export default TextArea;
