import axios from 'axios';
import { ProductModel } from './models/ProductModel';
import { List, ListParams } from '@shared/hooks';
import { StageItem } from './models/Stages';
import { CategoryItem } from './models/Categories';
import { PurchaseModel } from './models/PurchaseModel';

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

interface ListParamsCartItem {
  product: number;
  qty: number;
}

interface ListParamsProduct {
  productId: string;
}

interface ListParamsOrder {
  orderId: string;
}

export interface ListParamWithFilters extends ListParams {
  categories?: Number[];
  stages?: Number[];
}

export const fetchProducts = async (params: ListParamWithFilters): Promise<List<ProductModel>> => {
  try {
    const response = await axios
      .get(`${API_URL}/api/game-dev/product`, { params })
      .then((res) => res.data);
    return {
      records: response.records,
      total: response.total,
    };
  } catch (error) {
    return {
      records: [],
      total: 0,
    };
  }
};

export const fetchProduct = async ({ productId }: ListParamsProduct): Promise<ProductModel> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/product/${productId}`).then((res) => res.data);
  } catch (error) {
    return null;
  }
};

export const fetchPromotedProducts = async (): Promise<ProductModel[]> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/product/promoted`).then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const addToCart = async (cartItems: ListParamsCartItem[]) => {
  try {
    return await axios
      .post(`${API_URL}/api/game-dev/cart/items`, { ...cartItems })
      .then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchOrder = async ({ orderId }: ListParamsOrder): Promise<PurchaseModel> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/orders/${orderId}`).then((res) => res.data);
  } catch (error) {
    return null;
  }
};

export const fetchOrders = async (params: ListParams): Promise<List<PurchaseModel>> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/orders`, { params }).then((res) => res.data);
  } catch (error) {
    return null;
  }
};

export const fetchStagies = async (): Promise<StageItem[]> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/game-stage`).then((res) => res.data);
  } catch (error) {
    return null;
  }
};

export const fetchCategories = async (): Promise<CategoryItem[]> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/product-category`).then((res) => res.data);
  } catch (error) {
    return null;
  }
};
