import React from 'react';
import style from './Accordion.module.scss';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';

type Props = {
  children: React.ReactNode;
};

const Accordion = ({ children }: Props) => {
  return (
    <div className="accordion accordion-icon-toggle" id="accordion">
      {children}
    </div>
  );
};

type ItemProps = {
  id: number | string;
  title: any;
  children: React.ReactNode;
};

const Item = ({ id, title, children }: ItemProps) => {
  return (
    <div className={clsx(style.item, 'p-16 py-lg-24 px-lg-40')}>
      <button
        type="button"
        className="cursor-pointer d-flex align-items-center"
        data-bs-toggle="collapse"
        data-bs-target={`#item_${id}`}
      >
        <span className={clsx(style.icon, 'd-flex align-items-center me-12 svg-icon-primary')}>
          <SVG src={assetPath('/icons/arrow-right.svg')} />
        </span>
        <h3
          className={clsx(style.title, 'fs-16 fw-boldest text-start')}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </button>
      <div
        id={`item_${id}`}
        className={clsx(style.description, id === 0 ? 'show' : '', 'collapse ms-18')}
        data-bs-parent="#accordion"
      >
        <div className="mt-12">{children}</div>
      </div>
    </div>
  );
};

Accordion.Item = Item;

export default Accordion;
