import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useLayout } from '@shared/metronic/layout/core/LayoutProvider';
import { usePageData } from '@shared/metronic/layout/core/PageData';

type Props = {
  button?: React.ReactNode;
  buttonRight?: boolean;
  customContent?: (title: string) => JSX.Element;
};

const defaultProps = {
  button: null,
  buttonRight: false,
};

const DefaultTitle = ({ button, buttonRight, customContent }: Props) => {
  const { pageTitle, pageBreadcrumbs } = usePageData();
  const { config, attributes, classes } = useLayout();

  const renderTitle = useCallback(() => {
    if(customContent) {
      return (
        <div className="w-full">
          {customContent(pageTitle)}
        </div>
      )
    }

    if(button) {
      return (
        <div
          className={clsx('d-flex align-items-center w-full flex-wrap', {
            'justify-content-between': buttonRight,
            'justify-content-end': !pageTitle && button,
          })}
        >
          {pageTitle && <h1 className="fw-bolderest fs-25 lh-f25 me-32 mb-8">{pageTitle}</h1>}
          <div className="mb-8">{button}</div>
        </div>
      )
    }

    if(pageTitle) {
      return (
        <h1 className="fw-bolderest fs-25 lh-f25 me-32 mb-8">{pageTitle}</h1>
      )
    }
  }, [pageTitle, customContent, button])


  return (
    <div
      {...attributes.pageTitle}
      className={clsx('page-title w-full d-flex mb-24', classes.pageTitle.join(' '))}
    >
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className="h-20px border-gray-100 border-start mx-4" />
            )}
            <ul className="breadcrumb fs-12 lh-f12">
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link className="text-muted text-hover-primary" to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className="bullet bg-200 w-5px h-2px" />
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      {renderTitle()}
    </div>
  );
};

DefaultTitle.defaultProps = defaultProps;
export { DefaultTitle };
