import { useEffect } from 'react';
import { assetPath } from '@shared/utils/asset';

const useLayoutImage = () => {
  useEffect(() => {
    const content = document.querySelector('#kt_content') as HTMLDivElement;
    content.style.backgroundImage = `url(${assetPath('/images/body-bg.png')})`;
    content.classList.remove('fixed-icon');

    return () => {
      content.style.backgroundImage = 'none';
      content.classList.add('fixed-icon');
    };
  });
};

export default useLayoutImage;
