import React from 'react';
import { useSelector } from 'react-redux';
import { loadingSelectors } from './loadingSlice';
import { Loader } from '@shared/components';

type Props = {
  children: React.ReactNode;
  items: string[];
  white?: boolean;
};

const defaultProps = {
  white: false,
};

const Loading = ({ children, items, white }: Props): JSX.Element => {
  const activeItems = useSelector(loadingSelectors.selectIds);
  const isLoading = items.some((item) => activeItems.includes(item));

  if (isLoading) return <Loader page large white={white} />;

  return <>{children}</>;
};
Loading.defaultProps = defaultProps;
export default Loading;
