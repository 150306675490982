import axios from 'axios';
import { List } from '@shared/hooks';
import { GameListModel, GameModel } from '@app/modules/soft-launch/models/GameModel';
import { PlanModel } from '@app/modules/soft-launch/models/PlanModel';
import { FeatureModel } from '@app/modules/soft-launch/models/FeatureModel';
import { TargetPhaseModel } from '@app/modules/soft-launch/models/TargetPhaseModel';
import { TargetCountryModel } from '@app/modules/soft-launch/models/TargetCountryModel';
import { RevshareProgramModel } from '@app/modules/soft-launch/models/RevshareProgramModel';
import { AdClickDestinationModel } from '@app/modules/soft-launch/models/AdClickDestinationModel';

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

export const fetchGames = async (): Promise<List<GameListModel>> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/game`).then((res) => res.data);
  } catch (error) {
    return {
      records: [],
      total: 0,
    };
  }
};

export const fetchGame = async (id: string): Promise<GameModel> => {
  return axios.get(`${API_URL}/api/game-dev/game/${id}`).then((res) => res.data);
};

export const fetchPlans = async (campaignGoal: number): Promise<PlanModel[]> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/campaign/plan`, { params: { campaignGoal } })
      .then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchFeatures = async (): Promise<FeatureModel[]> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/campaign/feature`).then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchTargetPhrases = async (): Promise<TargetPhaseModel[]> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/target/campaign-phase`).then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchTargetCountries = async (campaignGoal: number): Promise<TargetCountryModel[]> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/target/country`, { params: { campaignGoal } })
      .then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchAdClickDestination = async (): Promise<AdClickDestinationModel[]> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/campaign/campaign-ad-click-destination`)
      .then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const revshareProgram = async (body: RevshareProgramModel) => {
  return axios
    .post(`${API_URL}/api/game-dev/campaign/rev-share-program`, body)
    .then((res) => res.data);
};

export const fetchBestGameScore = async (
  id: number | string
): Promise<{
  id: number;
  maxScore: number;
  totalScore: number;
} | null> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/game-score/game/${String(id)}/best-game-score`)
      .then((res) => res.data);
  } catch (error) {
    return null;
  }
};
