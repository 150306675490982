import { AxiosError, AxiosResponse } from 'axios';
import i18n from '../../i18n';
import { isPlainObject } from 'lodash';
import { UseFormSetError } from 'react-hook-form';
import { actions as authActions } from '@app/modules/auth';
import { Dispatch } from 'redux';

export interface FieldError {
  name: string;
  message: string;
}

export interface ErrorParserResponse {
  status: number;
  messageCode: string | null;
  message: string;
  errors: FieldError[] | null;
  file: {
    fileName: string;
    message: string;
    id: string;
    apiId: string | null;
  } | null;
}

const parseError = (key: string, error: any): FieldError[] => {
  if (Array.isArray(error)) {
    return [
      {
        name: key,
        message: error[0],
      },
    ];
  } else if (isPlainObject(error)) {
    const list: FieldError[] = [];

    Object.keys(error).forEach((skey) => {
      list.push(...parseError(`${key}.${skey}`, error[skey]));
    });

    return [...list];
  }

  return [];
};

const parseErrors = (errors: any): FieldError[] | null => {
  if (!isPlainObject(errors)) return null;

  const list: FieldError[] = [];

  Object.keys(errors).forEach((key) => {
    list.push(...parseError(key, errors[key]));
  });

  return list;
};

export const setRHFErrors = <T>(
  errors: FieldError[] | null | undefined,
  setError: UseFormSetError<T>
): void => {
  errors?.forEach((v) => {
    setError(
      // @ts-ignore
      v.name,
      {
        type: 'api',
        message: v.message,
      },
      { shouldFocus: true }
    );
  });
};

const apiErrorParser = (err: any, dispatch?: Dispatch): ErrorParserResponse => {
  if (err.response && err.isAxiosError) {
    const { response } = err as AxiosError;

    const {
      status,
      data: { error },
    } = response as AxiosResponse;
    const { data, messageCode = 'default' } = error || {};

    if (status === 409 || status === 423) dispatch(authActions.setIsModuleExceeded(true));

    return {
      status,
      messageCode,
      message: i18n!.t(`common:errors.${messageCode}`, 'Something went wrong!'),
      errors: parseErrors(data),
      file: null,
    };
  }

  if (err instanceof FileApiError) {
    return {
      status: 400,
      messageCode: 'File',
      message: i18n!.t('common:errors.default', 'Something went wrong!'),
      errors: null,
      file: {
        fileName: err.fileName,
        message: err.message,
        id: err.id,
        apiId: err.apiId || null,
      },
    };
  }

  return {
    status: 500,
    messageCode: 'Undefined',
    message: i18n!.t('common:errors.default', 'Something went wrong!'),
    errors: null,
    file: null,
  };
};

export class FileApiError extends Error {
  constructor(message: string, public fileName: string, public id?: string, public apiId?: string) {
    super(message);

    this.fileName = fileName;
    this.id = id;
    this.apiId = apiId;
  }
}

export default apiErrorParser;
