import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import type { FieldValues, UseFormRegister } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  register?: UseFormRegister<FieldValues>;
  error?: string;
  placeholder?: string;
  label?: string | React.ReactNode;
  secondary?: boolean;
  current?: number;
  currentLength?: number;
  maxLength?: number;
  [x: string]: any;
};

const defaultProps = {
  register: () => {},
  error: '',
  placeholder: '',
  label: '',
  secondary: false,
  currentLength: 0,
};

const InputTextArea = ({
  name,
  register,
  error,
  placeholder,
  label,
  secondary,
  currentLength,
  maxLength,
  className,
  ...rest
}: Props) => {
  const [count, setCount] = useState(currentLength);

  const context = useFormContext();
  const watch = context ? context.watch(name) : null;

  useEffect(() => {
    if (typeof watch !== 'undefined' && watch !== null) {
      setCount(watch.length);
    }
  }, [watch]);

  return (
    <div>
      {label && (
        <label htmlFor={`textarea-id-${name}`} className="fs-12 lh-f12 fw-bold mb-10">
          {label}
        </label>
      )}
      <textarea
        id={`textarea-id-${name}`}
        name={name}
        placeholder={placeholder}
        className={clsx('form-control pt-10', {
          'is-invalid': !!error,
          'form-control-solid': !secondary,
        })}
        maxLength={maxLength}
        rows={3}
        {...register(name)}
        {...rest}
      />
      {!!maxLength && (
        <div className="fs-12 lh-f12 text-gray-700 mt-4 text-end">{`${count}/${maxLength}`}</div>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

InputTextArea.defaultProps = defaultProps;

export default InputTextArea;
