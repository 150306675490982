import React from 'react';
import { Loader } from '@shared/components';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  loader?: boolean;
  className?: string;
};

const defaultProps = {
  disabled: false,
  loader: false,
  className: '',
};

const Disabled = ({ children, disabled, loader, className }: Props) => {
  if (!disabled) return <span className={className}>{children}</span>;

  if (loader)
    return (
      <span className="d-block position-relative">
        <div className="position-absolute top-50 start-50 translate-middle z-index-1">
          <Loader large />
        </div>
        <div className="position-relative z-index-0 opacity-25 pe-none">{children}</div>
      </span>
    );

  return <span className={`d-block opacity-25 pe-none ${className}`}>{children}</span>;
};

Disabled.defaultProps = defaultProps;

export default Disabled;
