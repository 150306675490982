import { get } from 'lodash';
import { UserModel } from '@shared/models/UserModel';
import { LanguageModel } from '@shared/models/LanguageModel';
import i18n from '@app/i18n';

export const getUserFromLocalStorage = (): UserModel | null => {
  return JSON.parse(get(JSON.parse(localStorage.getItem('persist:app')), 'user', null));
};

export const getUserStorageLanguage = () => {
  const user = getUserFromLocalStorage();

  return user ? window.localStorage.getItem('language') || 'en' : 'en';
};

export const setUserStorageLanguage = (code) => {
  return window.localStorage.setItem('language', code);
};

export const refreshUserStorageLanguage = (language: LanguageModel): boolean => {
  if (language && language.isoCode !== getUserStorageLanguage()) {
    setUserStorageLanguage(language.isoCode);
    i18n.changeLanguage(language.isoCode);

    return true;
  }

  return false;
};

export const profileCompletion = (obj: UserModel) => {
  const arr = [
    obj.avatarFile,
    obj.email,
    obj.firstName,
    obj.lastName,
    obj.phone,
    obj.studio,
    obj.website,
  ];

  const filtered = arr.filter(Boolean);

  return +((100 / arr.length) * filtered.length).toFixed(0);
};
