import React from 'react';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import ReactTooltip from 'react-tooltip';

type Props = {
  children: React.ReactNode;
  label: string;
  tooltip?: {
    id: string;
    content: string;
  } | null;
};

const defaultProps = {
  tooltip: null,
};

const Skip = ({ children, label, tooltip }: Props) => (
  <div className="d-flex align-items-center">
    {children}
    <p className="fs-14 lh-f14 fw-bold mx-8">{label}</p>
    {tooltip && (
      <div>
        <div data-tip="tooltip" data-for={tooltip.id}>
          <SVG src={assetPath(`/icons/alerts/info.svg`)} />
        </div>
        <ReactTooltip place="top" type="info" effect="solid" id={tooltip.id}>
          {tooltip.content}
        </ReactTooltip>
      </div>
    )}
  </div>
);

Skip.defaultProps = defaultProps;

export default Skip;
