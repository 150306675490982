export const configPagination = [
  {
    id: 4,
    name: '4',
  },
  {
    id: 20,
    name: '20',
  },
  {
    id: 50,
    name: '50',
  },
  {
    id: 100,
    name: '100',
  },
];
