export interface UserPlanModel {
  isExpired: any;
  id: number;
  name: string;
  stripeToken: string;
  permissionGameUpload: boolean;
  gameUploadLimit: number;
  permissionGameChecker: boolean;
  gameCheckerLimit: number | null;
  permissionCampaignManager: boolean;
  permissionReports: boolean;
  interval: PlanInterval;
  reportsLimit: number | null;
  level: number;
  permissionMarketingStrategy: boolean;
  permissionDigitalFirst: boolean;
  permissionFinances: boolean;
  permissionHelpCenter: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  purchasedAt: string;
  expiresAt: string;
  isExpirationMessageSent: boolean;
  isSubscriptionActive: boolean;
  isTrial: boolean;
  planDaysLeft: number;
}

export enum PlanLevels {
  BASIC = 1,
  PRO = 2,
  ADVANCED = 3,
}

export enum PlanInterval {
  MONTH = 'month',
  YEAR = 'year',
}
