import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { assetApiPath } from '@shared/utils/asset';
import { AlertType } from '@shared/enums/AlertType';
import { FileModel } from '@shared/models/FileModel';
import { addOne as addNotification } from '@shared/features/notifications/notificationsSlice';
import Uppy, { Restrictions, UppyFile } from '@uppy/core';
import { useUppy, Dashboard, DragDrop } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import clsx from 'clsx';

type Props = {
  initialFiles: FileModel[];
  onChangeFiles: (files: UppyFile[]) => void;
  restrictions?: Restrictions;
  error?: string;
  label?: string;
  placeholder?: string;
  [x: string]: any;
};

const defaultProps = {
  restrictions: {},
  error: '',
  label: '',
  placeholder: '',
};

const UploadFile = ({
  initialFiles,
  onChangeFiles,
  restrictions,
  error,
  label,
  placeholder,
}: Props) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const uppy = useUppy(() => {
    return new Uppy({
      restrictions,
      locale: {
        strings: {
          save: t('common:upload_file_save_button', 'Save'),
          cancel: t('common:upload_file_cancel_button', 'Cancel'),
        },
      },
    })
      .on('files-added', () => {
        handleChangeFiles();
      })
      .on('file-removed', () => {
        handleChangeFiles();
      })
      .on('restriction-failed', (file, error) => {
        dispatch(
          addNotification({
            id: `upload.${new Date().getTime()}`,
            type: AlertType.Danger,
            message: error.message,
          })
        );
      });
  });

  const fillInitialFiles = async () => {
    try {
      for (const file of initialFiles) {
        const blob = await fetch(assetApiPath(file.path)).then((response) => response.blob());

        uppy.addFile({
          name: file.userOriginalFilename,
          type: blob.type,
          data: blob,
          source: 'api',
          meta: {
            apiId: file.id,
            apiEdited: false,
          },
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    fillInitialFiles();
  }, []);

  const handleChangeFiles = () => {
    onChangeFiles(uppy.getFiles());
    forceUpdate();
  };

  return (
    <div>
      {label && <label className="fs-12 lh-f12 fw-bold mb-10">{label}</label>}
      <div
        className={clsx('custom-uppy', {
          'custom-uppy--single': uppy.getFiles().length === 1,
        })}
      >
        {uppy.getFiles().length ? (
          <Dashboard uppy={uppy} width="100%" height="360px" hideUploadButton hideCancelButton />
        ) : (
          <DragDrop
            width="100%"
            height="360px"
            note={placeholder}
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: t(
                  'common:upload_file_drop_placeholder',
                  'Drop files here or click to upload.'
                ),
              },
            }}
          />
        )}
      </div>
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};

UploadFile.defaultProps = defaultProps;

export default UploadFile;
