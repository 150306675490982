import React from 'react';
import { ContentBlockModel, ContentModel } from '@shared/hooks';
import SVG from 'react-inlinesvg';
import { assetApiPath } from '@shared/utils/asset';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  content: ContentModel;
  children?: React.ReactNode;
  small?: boolean;
};

const defaultProps = {
  children: null,
  small: false,
};

const EmptyContent = ({ content, children, small }: Props) => {
  if (!content) return null;

  const renderContent = (contentBlock: ContentBlockModel) => {
    const iconProps: any = {};

    if (small) {
      iconProps.width = contentBlock.contentBlocks.length <= 1 ? '128px' : '48px';
      iconProps.height = contentBlock.contentBlocks.length <= 1 ? '128px' : '48px';
    }

    switch (contentBlock.type) {
      case 'header':
        return (
          <h1
            className={clsx('fw-bold', {
              'fs-40 lh-f-40 mb-20': !small,
              'fs-18 lh-f18 mb-8': small,
            })}
          >
            {contentBlock.value}
          </h1>
        );
      case 'paragraph':
        return (
          <p
            className={clsx('px-lg-64', {
              'fs-18 lh-f-18': !small,
              'fs-12 lh-f12': small,
            })}
            dangerouslySetInnerHTML={{ __html: contentBlock.value }}
          />
        );
      case 'row_of_columns':
        return (
          <div className="row mt-48 gx-32 mb-n32 justify-content-center">
            {contentBlock.contentBlocks.map((contentBlock, index) => (
              <React.Fragment key={`cb-row-of-columns-${index}`}>
                {contentBlock.type === 'column' && (
                  <div
                    className={clsx('mb-32', {
                      'col-md-4': contentBlock.contentBlocks.length > 1,
                      'col-md-10': contentBlock.contentBlocks.length <= 1,
                    })}
                    key={contentBlock.id}
                  >
                    <div className="h-full d-flex flex-column align-items-center justify-content-between">
                      <div>
                        {contentBlock.contentBlocks.map((contentBlock, index) => (
                          <React.Fragment key={`cb-row-of-columns-sub-${index}`}>
                            {contentBlock.type === 'image' && (
                              <SVG
                                src={assetApiPath(contentBlock.file.path)}
                                className="img-fluid"
                                {...iconProps}
                              />
                            )}
                            {contentBlock.type === 'header' && (
                              <h3
                                className={clsx('fw-bold mt-12', {
                                  'fs-16 lh-f16': !small,
                                  'fs-12 lh-f12': small,
                                })}
                              >
                                {contentBlock.value}
                              </h3>
                            )}
                            {contentBlock.type === 'paragraph' && (
                              <p
                                className="fs-12 lh-f12 mt-12"
                                dangerouslySetInnerHTML={{ __html: contentBlock.value }}
                              />
                            )}
                            {contentBlock.type === 'link' && (
                              <Link
                                className="fs-12 lh-f12 fw-bold text-primary mt-10"
                                to={contentBlock.value}
                              >
                                {contentBlock.value}
                              </Link>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        );
    }
  };

  return (
    <div className="h-full d-flex align-items-center">
      <div className="row justify-content-center w-full">
        <div className="col-xxl-8 text-center">
          {content.contentBlocks.map((contentBlock: ContentBlockModel, index) => (
            <React.Fragment key={`cb-main-${index}`}>{renderContent(contentBlock)}</React.Fragment>
          ))}
          {children}
        </div>
      </div>
    </div>
  );
};

EmptyContent.defaultProps = defaultProps;

export default EmptyContent;
