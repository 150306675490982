import { useEffect, useRef, useState } from 'react';
import { ChartGranularity } from '@shared/enums/ChartGranularity';
import { DatepickerValue } from '@shared/components/Datepicker/Datepicker';

export type ChartFiltersValues = {
  date: DatepickerValue;
  granularity: ChartGranularity | null;
  custom?: any;
};

export type ChartFiltersChangeFn = (key: keyof ChartFiltersValues, value: any) => void;

export type ChartFilters = {
  values: ChartFiltersValues;
  change: ChartFiltersChangeFn;
};

const useChartFilters = (
  date: ChartFiltersValues['date'] = null,
  granularity: ChartFiltersValues['granularity'] = ChartGranularity.Day,
  watchInitialDate = false,
  customFilter = null
): ChartFilters => {
  const firstRender = useRef(true);
  const [values, setValues] = useState<ChartFiltersValues>({
    date,
    granularity,
    custom: customFilter,
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (watchInitialDate) {
      handleChangeFilter('date', date);
    }
  }, [date]);

  const handleChangeFilter = (key: keyof ChartFiltersValues, value: any) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return {
    values,
    change: handleChangeFilter,
  };
};

export default useChartFilters;
