import React from 'react';
import clsx from 'clsx';
import type { FieldValues, UseFormRegister } from 'react-hook-form';
import { OptionItemModel } from '@app/models/OptionItemModel';
import { useTranslation } from 'react-i18next';

type Props = {
  items: OptionItemModel[];
  name: string;
  register?: UseFormRegister<FieldValues>;
  error?: string;
  placeholder?: string;
  label?: string;
  large?: boolean;
  small?: boolean;
  secondary?: boolean;
  withoutPlaceholder?: boolean;
  [x: string]: any;
  disabled?: boolean;
  raw?: boolean;
  onChange?: (event: any) => void;
  onChangeEmit?: (event: any) => void;
};

const defaultProps = {
  register: () => {},
  error: '',
  placeholder: '',
  label: '',
  large: false,
  small: false,
  secondary: false,
  withoutPlaceholder: false,
  disabled: false,
  raw: false,
  onChange: null,
  onChangeEmit: null,
};

const Select = ({
  items,
  name,
  register,
  error,
  placeholder,
  label,
  large,
  small,
  secondary,
  withoutPlaceholder,
  disabled,
  raw,
  onChange,
  onChangeEmit,
  ...rest
}: Props) => {
  const { t } = useTranslation(['common']);
  const { onChange: onChangeRHF, ...registerProps } = register(name) || {};

  return (
    <div>
      {label && (
        <label htmlFor={`select-id-${name}`} className="fs-12 lh-f12 fw-bold mb-10">
          {label}
        </label>
      )}
      <select
        id={`select-id-${name}`}
        className={clsx('', {
          'form-select-raw': raw,
          'form-select': !raw,
          'is-invalid': !!error,
          'form-select-lg': large,
          'form-select-sm': small,
          'form-select-solid': !secondary,
        })}
        name={name}
        {...registerProps}
        {...rest}
        onChange={(v) => {
          if (onChange) {
            onChange(v);
          }

          if (onChangeRHF) {
            onChangeRHF(v);
          }

          if (onChangeEmit) {
            onChangeEmit(v);
          }
        }}
        disabled={disabled}
      >
        {!withoutPlaceholder && (
          <option value="">{placeholder || t('common:select_placeholder', 'Select...')}</option>
        )}
        {items?.map((item) => (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

Select.defaultProps = defaultProps;

export default Select;
