import React from 'react';
import style from './StatList.module.scss';
import clsx from 'clsx';
import { StatsModel } from '@shared/models/StatsModel';
import Item from './Item';

type Props = {
  items: StatsModel[];
  short?: boolean;
  small?: boolean;
};

const defaultProps = {
  short: false,
  small: false,
};

const StatsList = ({ items, short, small }: Props) => {
  return (
    <div className="row gx-40 gy-20">
      {items.map((item) => (
        <div className={clsx('col-6 col-sm-auto', style.item)} key={item.id || item.referenceName}>
          <Item stats={item} short={short} small={small} />
        </div>
      ))}
    </div>
  );
};

StatsList.defaultProps = defaultProps;

export default StatsList;
