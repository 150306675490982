import React from 'react';
import { useTranslation } from 'react-i18next';
import { KTSVG } from '@shared/metronic/helpers';
import style from './QuickHelp.module.scss';
import Accordion from './Accordion/Accordion';
import FooterLinkExternal from './FooterLink/FooterLinkExternal';
import Loading from '@app/shared/features/loading/Loading';
import { ContentModel } from '@shared/hooks';

export const parseQuickHelpContent = (content: ContentModel): QuickHelpItemModel[] => {
  return content.contentBlocks
    .map(({ id, contentBlocks }) => {
      const header = contentBlocks.find((r) => r.type === 'accordion_header');
      const content = contentBlocks.find((r) => r.type === 'accordion_content');

      if (!header || !content) return null;

      return {
        id,
        header: header.value,
        content: content.value,
      };
    })
    .filter(Boolean);
};

export interface QuickHelpItemModel {
  id: number;
  header: string;
  content: string;
}

type Props = {
  name: string;
  items: QuickHelpItemModel[];
};

const QuickHelp = ({ name, items }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div
      id={name}
      className="bg-white"
      data-kt-drawer="true"
      data-kt-drawer-name="quick-help"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'lg': '500px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle={`#${name}_toggle`}
      data-kt-drawer-close={`#${name}_close`}
    >
      <div className={style.quickHelp}>
        <div className={style.header}>
          <h3 className="fw-boldest fs-25">{t('common:quick_help.title', 'Quick help')}</h3>
          <button type="button" className="btn btn-sm btn-icon" id={`${name}_close`}>
            <KTSVG path="/icons/close.svg" className="svg-icon svg-icon-24 svg-icon-primary" />
          </button>
        </div>
        <div className={style.body}>
          <Loading items={['FETCH_QUICK_HELP']}>
            <Accordion>
              {items.map((item) => (
                <Accordion.Item key={item.id} id={item.id} title={item.header}>
                  {item.content}
                </Accordion.Item>
              ))}
            </Accordion>
          </Loading>
        </div>
        <div className={style.footer}>
          <div className="row px-n10 my-n10">
            <div className="col-lg-6 px-10 my-10">
              <FooterLinkExternal
                to={window.__RUNTIME_CONFIG__.REACT_APP_HELP_CENTER_URL}
                title={t('common:quick_help.help_center_link', 'Help Center')}
                iconPath="/icons/help-center.svg"
              />
            </div>
            <div className="col-lg-6 px-10 my-10">
              <FooterLinkExternal
                to={window.__RUNTIME_CONFIG__.REACT_APP_CONTACT_URL}
                title={t('common:quick_help.contact_link', 'Contact us')}
                iconPath="/icons/contact.svg"
                secondary
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickHelp;
