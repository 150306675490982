import React, { forwardRef, ForwardedRef } from 'react';
import DP, { ReactDatePickerProps } from 'react-datepicker';
import Trigger from './Trigger';

export type DatepickerValue = Date | [Date | null, Date | null] | null;

interface Props extends ReactDatePickerProps {
  onClear?: () => void;
  placeholder?: string;
  label?: string;
  error?: string;
  large?: boolean;
  small?: boolean;
  secondary?: boolean;
}

const defaultProps = {
  placeholder: '',
  label: '',
  error: '',
  large: false,
  small: false,
  secondary: false,
};

const Datepicker = forwardRef(
  (
    { onClear, placeholder, label, error, large, small, secondary, dateFormat, ...rest }: Props,
    ref: ForwardedRef<any>
  ) => {
    return (
      <DP
        ref={ref}
        popperPlacement="bottom-start"
        dateFormat={dateFormat || 'dd.MM.yyyy'}
        customInput={
          <Trigger
            label={label}
            error={error}
            large={large}
            small={small}
            secondary={secondary}
            onClear={onClear}
          />
        }
        placeholderText={placeholder || 'dd.MM.yyyy'}
        {...rest}
      />
    );
  }
);

Datepicker.defaultProps = defaultProps;

export default Datepicker;
