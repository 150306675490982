import React, { FC, useMemo, useRef, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import PricingTableModal from '@shared/partials/StripePricingTable/PricingTableModal';
import { useSelector } from 'react-redux';
import { RootState } from '@app/setup';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { PlanInterval } from '@app/modules/auth/models/UserPlanModel';

export const getCurrentPlanIcon = (level: number) => {
  switch (level) {
    case 1:
      return <SVG src={assetPath('/icons/basic_plan.svg')} />;
    case 2:
      return <SVG src={assetPath('/icons/pro_plan.svg')} />;
    case 3:
      return <SVG src={assetPath('/icons/advanced_plan.svg')} />;
  }
};

const CurrentPlan: FC = () => {
  const { t } = useTranslation(['plan']);
  const { name, level, planDaysLeft, interval } = useSelector(({ auth }: RootState) => auth.plan);
  const [openPlanBox, setOpenPlanBox] = useState(false);
  const [target, setTarget] = useState(null);
  const [openPricingModal, setOpenPricingModal] = useState(false);
  const ref = useRef(null);
  const isPlanExpiring = useMemo(() => planDaysLeft <= 7 && planDaysLeft > 0, [planDaysLeft]);
  const isTheBestPlan = level === 3 && interval === PlanInterval.YEAR;
  const planExpired = planDaysLeft <= 0;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setOpenPlanBox(!openPlanBox);
    setTarget(event.currentTarget);
  };

  const handleClose = () => {
    setOpenPlanBox(false);
    setTarget(null);
  };

  const getCurrentPlanInfo = [
    {
      text: t('plan.basic.text', 'Unlock your full potential!'),
      icon: '/icons/dart.svg',
      button: t('plan.basic.button', 'Upgrade now'),
    },
    {
      text: t('plan.pro.text', 'Upgrade your plan and take your game to the next level.'),
      icon: '/icons/dart.svg',
      button: t('plan.pro.button', 'Upgrade now'),
    },
    {
      text: t('plan.advanced.text', `You've upgraded to the complete plan.`),
      icon: '/icons/fireworks.svg',
      button: t('plan.advanced.button', 'Check plans'),
    },
  ];

  const expiringPlanCopy = {
    text: t('plan.plan_expiring', `Don't let your plan expire.`),
    icon: '/icons/dart.svg',
    button: t('plan.renew_plan', 'Renew your plan'),
  };

  const { text, button, icon } =
    isPlanExpiring || planDaysLeft <= 0 ? expiringPlanCopy : getCurrentPlanInfo[level - 1];

  return (
    <>
      <div
        className={clsx(`aside-plan my-14 rounded-pill d-flex align-items-center`, {
          'aside-plan--expired': planDaysLeft <= 0,
        })}
        onClick={handleClick}
        role="button"
        ref={ref}
      >
        {planDaysLeft <= 0 ? (
          <span className="fw-bolder text-uppercase fs-12 aside-plan-expired">
            {t('plan.your_plan_expired', 'Your plan expired')}
          </span>
        ) : (
          <>
            {getCurrentPlanIcon(level)}
            <span className="fw-bolder text-uppercase fs-12 aside-plan-title">{name}</span>
          </>
        )}
      </div>
      <Overlay
        show={openPlanBox}
        target={target}
        placement="bottom-start"
        container={ref}
        containerPadding={20}
        onHide={handleClose}
        rootClose
      >
        <Popover id="popover-contained" style={{ width: '320px', maxWidth: 'unset' }}>
          <Popover.Body className="plan-popover">
            <div className="plan-popover-header">
              <h3>{t('plan.your_plan', 'Your plan')}</h3>
              <div onClick={handleClose} />
            </div>
            <div className="plan-popover-body">
              <div className="plan-popover-body-info">
                <div>
                  {getCurrentPlanIcon(level)}
                  <span className="plan-name text-capitalize">
                    {name} / <span>{interval}</span>
                  </span>
                </div>
                <div
                  className={clsx('plan-popover-body-info-time', {
                    'plan-popover-body-info-time--isExpiring': isPlanExpiring || planDaysLeft <= 0,
                  })}
                >
                  {planDaysLeft > 0 && (
                    <SVG src={assetPath(`/icons/${isPlanExpiring ? 'red-clock' : 'clock'}.svg`)} />
                  )}
                  <span>
                    {planDaysLeft <= 0
                      ? t('plan.plan_expired', 'Plan expired')
                      : t('plan.days_left', 'Left: {{days}} days', { days: planDaysLeft })}
                  </span>
                </div>
              </div>
              <div className="plan-popover-body-upgrade">
                <div
                  className={clsx('upgrade-info', {
                    'upgrade-info--bestPlan': isTheBestPlan && !isPlanExpiring && !planExpired,
                  })}
                >
                  <span className="upgrade-text">{text}</span>
                  {(!isTheBestPlan || isPlanExpiring || planExpired) && (
                    <button
                      onClick={() => setOpenPricingModal(true)}
                      className="btn btn-primary btn-sm text-uppercase"
                    >
                      {button}
                    </button>
                  )}
                </div>
                <div className="upgrade-icon">
                  <SVG src={assetPath(icon)} />
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <PricingTableModal show={openPricingModal} handleClose={() => setOpenPricingModal(false)} />
    </>
  );
};

export default CurrentPlan;
