import React from 'react';
import style from './Table.module.scss';
import clsx from 'clsx';

type Props = {
  head?: React.ReactNode;
  children: React.ReactNode;
  breakpoint?: 'md' | 'lg' | 'xl';
  inline?: boolean;
};

const defaultProps = {
  head: null,
  breakpoint: '',
  inline: false,
};

const Table = ({ head, children, breakpoint, inline }: Props) => {
  return (
    <>
      <table
        className={clsx(style.table, {
          [style.md]: breakpoint === 'md',
          [style.lg]: breakpoint === 'lg',
          [style.xl]: breakpoint === 'xl',
          [style.mdInline]: inline && breakpoint === 'md',
          [style.lgInline]: inline && breakpoint === 'lg',
          [style.xlInline]: inline && breakpoint === 'xl',
        })}
      >
        {head && <thead>{head}</thead>}
        <tbody>{children}</tbody>
      </table>
    </>
  );
};

Table.defaultProps = defaultProps;

export default Table;
