import React from 'react';
import clsx from 'clsx';
import type { FieldValues, UseFormRegister } from 'react-hook-form';
import { KTSVG } from '@shared/metronic/helpers';

type Props = {
  name: string;
  register?: UseFormRegister<FieldValues>;
  error?: string;
  placeholder?: string;
  label?: string;
  large?: boolean;
  small?: boolean;
  secondary?: boolean;
  [x: string]: any;
};

const defaultProps = {
  register: () => {},
  error: '',
  placeholder: '',
  label: '',
  large: false,
  small: false,
  secondary: false,
};
const InputQuickSearch = ({
  name,
  register,
  error,
  placeholder,
  label,
  large,
  small,
  secondary,
  ...rest
}: Props) => {
  return (
    <div>
      {label && (
        <label htmlFor={`input-id-${name}`} className="fs-12 lh-f12 fw-bold mb-10">
          {label}
        </label>
      )}
      <div className="input-group has-validation">
        <span
          className={clsx(
            'input-group-text border-right-0 border-top-right-radius-0 border-bottom-right-radius-0',
            {
              'input-group-text-solid': !secondary,
            }
          )}
        >
          <KTSVG
            path="/icons/search.svg"
            color="#172047"
            className="svg-icon-16 svg-icon-gray-600"
          />
        </span>
        <input
          id={`input-id-${name}`}
          type="text"
          placeholder={placeholder}
          className={clsx('form-control border-left-0 ms-0 ps-0', {
            'is-invalid': !!error,
            'form-control-lg': large,
            'form-control-sm': small,
            'form-control-solid': !secondary,
          })}
          {...register(name)}
          {...rest}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

InputQuickSearch.defaultProps = defaultProps;

export default InputQuickSearch;
