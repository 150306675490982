import React from 'react';
import style from './StageGoal.module.scss';
import clsx from 'clsx';

interface Props {
  header: string;
  paragraph: string;
  counter: number;
  type: string;
  buttonTitle: string;
  handleButton: () => void;
}

const StageGoal = ({ header, paragraph, counter, type, buttonTitle, handleButton }: Props) => {
  return (
    <div className="section container-fluid">
      <div className="d-flex flex-column">
        <h5>{header}</h5>
        <p className="fs-9">{paragraph}</p>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-end pt-20">
        <div className="d-flex align-items-end d-inline ">
          <span className={style.valueContainer}>
            <b className={style.firstValueContainer}>{counter}</b>
            {type}
          </span>
        </div>
        <button type="button" onClick={handleButton} className="btn btn-secondary h-10">
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default StageGoal;
