import React, { useState } from 'react';
import style from '@app/modules/game-checker/shared/Report/Report.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { HintModel } from '@app/modules/game-checker/models/HintModel';
import Hint from '@app/modules/game-checker/components/Hint/Hint';
import Revshare from '@app/modules/game-checker/shared/Report/Revshare/Revshare';

type Props = {
  warningsCount: number;
  suggestionsCount: number;
  warnings: HintModel[];
  suggestions: HintModel[];
};

const defaultProps = {};

const Report = ({ warningsCount, warnings, suggestionsCount, suggestions }: Props) => {
  const { t } = useTranslation(['gc']);
  const [showFullReport, setShowFullReport] = useState(false);

  if (!warningsCount && !suggestionsCount) return null;

  return (
    <>
      {!!warningsCount && (
        <div className="my-64">
          <p className="fs-16 lh-f16 text-danger border-bottom pb-16 mb-20 fw-bold">
            {t('gc:c_report.warnings_detected', '{{count}} warnings detected', {
              count: warningsCount,
            })}
          </p>
          <div className="row mb-n20 gx-40 gx-lg-20 gx-xl-40">
            {warnings.slice(0, 2).map((item) => (
              <div className="col-md-6 mb-20" key={item.id}>
                <Hint {...item} />
              </div>
            ))}
          </div>
          <div className="row mb-n20 gx-40 gx-lg-20 gx-xl-40 mt-20 collapse" id="report">
            {warnings.slice(2, warnings.length).map((item) => (
              <div className="col-md-6 mb-20" key={item.id}>
                <Hint {...item} />
              </div>
            ))}
          </div>
        </div>
      )}
      {!!suggestionsCount && (
        <div className="my-64">
          <p className="fs-16 lh-f16 border-bottom pb-16 mb-20 fw-bold">
            {t('gc:c_report.suggestions_detected', '{{count}} new suggestions to improve', {
              count: suggestionsCount,
            })}
          </p>
          <div className="row gx-40 gx-lg-20 gx-xl-40 collapse" id="report">
            <div className="col-md-7 col-lg-12 col-xl-7 col-xxl-8">
              {suggestions.map((item) => (
                <div className="mb-20" key={item.id}>
                  <Hint {...item} suggestion />
                </div>
              ))}
            </div>
            <div className="col-md-5 col-lg-12 col-xl-5 col-xxl-4">
              <div className="d-flex justify-content-center">
                <Revshare />
              </div>
            </div>
          </div>
        </div>
      )}
      {warningsCount > 2 || !!suggestionsCount ? (
        <div className={clsx(style.buttonCollapse, 'text-center')}>
          <button
            className={clsx('btn btn-primary btn-sm')}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#report"
            onClick={() => setShowFullReport(!showFullReport)}
          >
            {showFullReport
              ? t('gc:c_report.hide_report', 'Hide full report')
              : t('gc:c_report.show_report', 'Show full report')}
          </button>
        </div>
      ) : null}
    </>
  );
};

Report.defaultProps = defaultProps;

export default Report;
