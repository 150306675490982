import React from 'react';
import clsx from 'clsx';
import style from './Badge.module.scss';

type Props = {
  children: React.ReactNode;
};

const Badge = ({ children }: Props) => {
  return <span className={`${style.badge} py-2 px-8 fs-12 lh-f12`}>{children}</span>;
};

type ListProps = {
  children: React.ReactNode;
  className?: string;
};

const List = ({ children, className = '' }: ListProps) => {
  return <div className={clsx('mx-n2', className)}>{children}</div>;
};

Badge.List = List;

export default Badge;
