import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import axios from 'axios';
import { getUserStorageLanguage } from '@shared/utils/user';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getUserStorageLanguage(),
    fallbackLng: 'en',
    supportedLngs: ['en', 'pl'],
    debug: false,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
    ns: [
      'common',
      'api_error',
      'auth',
      'account',
      'info',
      'dashboard',
      'soft_launch',
      'game',
      'gc',
      'finances',
      'reports',
    ],
    defaultNS: 'common',
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {},
        {
          loadPath: `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/game-dev/translation/{{lng}}/{{ns}}`,
        },
      ],
    },
    saveMissing: window.__RUNTIME_CONFIG__.REACT_APP_TRANSLATIONS_SAVE_MISSING === 'true',
    appendNamespaceToCIMode: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      axios.post(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/game-dev/translation/missing`,
        {
          language: 'en',
          namespace: ns,
          name: key,
          translation: fallbackValue,
        }
      );
    },
  });

export default i18n;
