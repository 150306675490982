import React from 'react';

type Props = {
  title?: string;
  items?: {
    color: string;
    title: string;
  }[];
};

const ChartLegend = ({ title = '', items = [] }: Props) => {
  if (!title && !items.length) return null;

  return (
    <div>
      {title && <p className="fs-16 lh-18 fw-bold mb-4">{title}</p>}
      {!!items.length && (
        <ul className="list-none d-flex flex-wrap mb-n4 mx-n8">
          {items.map((item) => (
            <li key={item.title} className="mb-4 px-8 d-flex align-items-center">
              <span
                className="d-block flex-shrink-0 rounded-circle w-12 h-12 me-4"
                style={{ backgroundColor: item.color }}
              />
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ChartLegend;
