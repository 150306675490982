import axios from 'axios';
import { PaymentMethodModel } from '@app/modules/soft-launch/models/PaymentMethodModel';

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

interface FetchPaymentResponse {
  id: number;
  totalAmount: number;
  paid: boolean;
  gatewayStatus: string;
  currency: string;
  gatewayAmountPaid: string;
}

export const fetchPayment = async (id: number | string) => {
  return axios
    .get<FetchPaymentResponse>(`${API_URL}/api/game-dev/payment/${String(id)}`)
    .then((res) => res.data);
};

interface FetchUserFinancesResponse {
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  companyStreet: string | null;
  companyBuildingNumber: string | null;
  companyApartmentNumber: string | null;
  companyTown: string | null;
  companyZipCode: string | null;
  companyTaxId: string | null;
  companyCountry: string | null;
}

export const fetchUserFinanceSettings = async () => {
  return axios
    .get<FetchUserFinancesResponse>(`${API_URL}/api/game-dev/finance/settings`)
    .then((res) => res.data);
};

export const fetchCampaignPaymentMethods = async () => {
  return axios
    .get<PaymentMethodModel[]>(`${API_URL}/api/game-dev/campaign/payment-method`)
    .then((res) => res.data);
};
