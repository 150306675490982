import React from 'react';
import clsx from 'clsx';
import style from './Avatar.module.scss';
import { assetPath } from '@shared/utils/asset';

type Props = {
  avatar: string | null;
  type?: 'user' | 'game' | 'website' | 'widget' | 'widget-api';
  name?: string;
  className?: string;
};

const defaultProps = {
  type: '',
  name: '',
  className: '',
};

const Avatar = ({ avatar, type, name, className }: Props) => {
  const getDefaultAvatar = () => {
    switch (type) {
      case 'user':
        return assetPath('/asort/user-avatar-blank.png');
      case 'game':
        return assetPath('/asort/game-avatar-blank.png');
      case 'website':
        return assetPath('/asort/website-avatar-blank.png');
      case 'widget':
        return assetPath('/asort/widget-avatar-blank.png');
      case 'widget-api':
        return assetPath('/asort/widget-api-avatar-blank.png');
    }

    return '/asort/avatar-blank.png';
  };

  return (
    <div className={clsx(style.avatar, className)}>
      <img
        src={avatar || getDefaultAvatar()}
        alt={name}
        onError={(e) => {
          const target = e.target as HTMLImageElement;

          target.parentElement.classList.add('d-none');
        }}
      />
    </div>
  );
};

Avatar.defaultProps = defaultProps;

export default Avatar;
