import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  secondary?: boolean;
  danger?: boolean;
  outlineLight?: boolean;
  large?: boolean;
  small?: boolean;
  className?: string;
  [x: string]: any;
};

const defaultProps = {
  type: 'button',
  disabled: false,
  loading: false,
  secondary: false,
  danger: false,
  outlineLight: false,
  large: false,
  small: false,
  className: '',
};

const ButtonAction = ({
  children,
  type,
  disabled,
  loading,
  secondary,
  danger,
  outlineLight,
  large,
  small,
  className,
  ...rest
}: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx(
        'btn',
        {
          'btn-primary': !secondary && !danger && !outlineLight,
          'btn-secondary': secondary,
          'btn-danger': danger,
          'btn-outline-light': outlineLight,
          'btn-sm': small,
          'btn-lg': large,
          disabled: disabled || loading,
        },
        className
      )}
      data-kt-indicator={loading ? 'on' : ''}
      {...rest}
    >
      <span className="indicator-label">{children}</span>
      <span className="indicator-progress">
        {t('common:button_action_loading', 'Please wait...')}{' '}
        <span className="spinner-border spinner-border-sm align-middle ms-4" />
      </span>
    </button>
  );
};

ButtonAction.defaultProps = defaultProps;

export default ButtonAction;
