import React, { useEffect } from 'react';
import { removeOne } from '../notificationsSlice';
import { AlertType } from '@shared/enums/AlertType';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';

export type Props = {
  type: AlertType;
  id: string;
  message: string;
  description?: string;
};

const defaultProps = {
  description: '',
};

function Notification({ type, id, message, description }: Props): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeOne(id));
    }, 5000);
  }, []);

  return (
    <div
      role="alert"
      className={clsx(
        'notification-animation alert border border-dashed d-flex align-items-center min-h-58 mb-8',
        `bg-light-${type} border-${type}`
      )}
    >
      <div className="me-10 flex-shrink-0">
        <SVG src={assetPath(`/icons/alerts/${type}.svg`)} />
      </div>
      <div>
        <p className="fw-bold">{message}</p>
        {description && <p>{description}</p>}
      </div>
    </div>
  );
}

Notification.defaultProps = defaultProps;

export default Notification;
