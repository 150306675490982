import React, { useEffect, useMemo, useState } from 'react';
import Score from '@app/modules/game-checker/components/Score/Score';
import { DefaultTitle } from '@app/shared/metronic/layout/components/header/page-title/DefaultTitle';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { PageTitle } from '@app/shared/metronic/layout/core';
import { useTranslation } from 'react-i18next';
import Loading from '@app/shared/features/loading/Loading';
import * as services from '@app/modules/game-checker/services';
import {
  addOne as addLoading,
  removeOne as removeLoading,
} from '@app/shared/features/loading/loadingSlice';
import { Report, ReportChart } from '@app/modules/game-checker/shared';
import style from './DashboardGame.module.scss';
import clsx from 'clsx';
import StageGoal from '../../components/StageGoal/StageGoal';
import { GameScoreModel } from '../../models/GameScoreModel';
import { useDispatch, useSelector } from 'react-redux';
import { GameModel } from '../../models/GameModel';
import ChangeGoalModal from '../../components/Modals/ChangeGoalModal/ChangeGoalModal';
import StrategyChangeModal from '../../components/Modals/StrategyChangeModal/StrategyChangeModal';
import {
  ElementType,
  Liveop,
  MarketingStrategyDetailsModel,
  MarketingStrategyStatus,
  Product,
} from '../../models/MarketingStrategyDetailsModel';
import { actions as authActions } from '@app/modules/auth';
import { Alert, SampleProduct, SampleTactic } from '@shared/components';
import { addOne as addNotification } from '@app/shared/features/notifications/notificationsSlice';
import { RootState } from '@app/setup';
import { GameScoreShortModel } from '../../models/GameScoreShortModel';
import { ListParams, useList } from '@shared/hooks';
import ScoreThumb from '../Dashboard/ScoreThumb/ScoreThumb';
import { Modal, Pagination } from '@shared/partials';
import { AlertType } from '@shared/enums/AlertType';
import { configPagination } from '../../shared/constants';
import { useSortedTacticsAndProducts } from '@shared/hooks';
import PricingTableModal from '@shared/partials/StripePricingTable/PricingTableModal';
import { getUserCurrentPlan } from '@app/modules/auth/redux/AuthCRUD';

interface LocationProps {
  entryShowStrategyProposal?: boolean;
}
export type TacticAndProduct = Liveop & Product;

export const isTactic = (b: TacticAndProduct) => 'tactic' in b;

export const isProduct = (b: TacticAndProduct) => 'product' in b;

const DashboardGame = () => {
  const { t } = useTranslation(['common', 'gc']);
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const dispatch = useDispatch();
  const { gcId } = useParams<{ gcId: string }>();
  const [score, setScore] = useState<GameScoreModel>();
  const [game, setGame] = useState<GameModel>();
  const [marketingStrategyDetails, setMarketingStrategyDetails] =
    useState<MarketingStrategyDetailsModel>();
  const [showStrategyProposal, setShowStrategyProposal] = useState(
    location?.state?.entryShowStrategyProposal ?? false
  );

  const [showStrategyChangeSoftModal, setShowStrategyChangeSoftModal] = useState(false);
  const [showStrategyChangeHardModal, setShowStrategyChangeHardModal] = useState(false);
  const [showChangeGoalModal, setShowChangeGoalModal] = useState(false);
  const [modalId, setModalId] = useState<number | null>(null);
  const { sortedArrayOfElements } = useSortedTacticsAndProducts(marketingStrategyDetails);
  const { isSubscriptionActive, permissionMarketingStrategy, isExpired } = useSelector(
    ({ auth }: RootState) => auth.plan
  );
  const [plan, setPlan] = useState(null);
  const [showPricingTable, setShowPricingTable] = useState(false);
  const list = useList<GameScoreShortModel>(4);

  const isHardChange =
    marketingStrategyDetails?.status &&
    marketingStrategyDetails.status === MarketingStrategyStatus.InExecution;

  useEffect(() => {
    fetchPlan();
  }, []);

  useEffect(() => {
    fetchScores();
  }, [list.page, list.perPage]);

  useEffect(() => {
    fetchScore();
  }, [gcId]);

  useEffect(() => {
    if (score) {
      fetchGame();
    }
  }, [score]);

  useEffect(() => {
    if (game) {
      fetchMarketingStrategyDetails();
    }
  }, [game]);

  const fetchPlan = async () => {
    try {
      const fetchedPlan = await getUserCurrentPlan();
      setPlan(fetchedPlan);

      return fetchedPlan.isExpired;
    } catch (error) {
      console.error(t('common:alerts.error'), error);
    }
  };

  const fetchScore = async () => {
    try {
      dispatch(addLoading({ id: 'FETCH_SCORE' }));
      const response = await services.fetchScore(gcId);
      setScore(response);
    } catch (error) {
      dispatch(
        addNotification({
          id: 'gc.dashboard.remove',
          type: AlertType.Danger,
          message: t('common:alerts.error'),
        })
      );
    } finally {
      dispatch(removeLoading('FETCH_SCORE'));
    }
  };

  const fetchScores = async () => {
    try {
      dispatch(addLoading({ id: list.isFetched ? 'FETCH_SCORES' : 'FETCH_FIRST_SCORES' }));

      const params: ListParams = {
        records: list.perPage,
        page: list.page,
      };

      const response = await services.fetchScores(params);

      if (!response.records.length) {
        history.push('/game-checker/empty');
        return;
      }

      list.handleFetch(response);
    } catch (error) {
      dispatch(
        addNotification({
          id: 'gc.dashboard.remove',
          type: AlertType.Danger,
          message: t('common:alerts.error'),
        })
      );
    } finally {
      dispatch(removeLoading(list.isFetched ? 'FETCH_SCORES' : 'FETCH_FIRST_SCORES'));
    }
  };

  const fetchGame = async () => {
    try {
      dispatch(addLoading({ id: 'FETCH_GAME' }));
      const response = await services.fetchGame(`${score.game.id}`);
      setGame(response);
    } catch (error) {
      dispatch(
        addNotification({
          id: 'gc.dashboard.remove',
          type: AlertType.Danger,
          message: t('common:alerts.error'),
        })
      );
    } finally {
      dispatch(removeLoading('FETCH_GAME'));
    }
  };

  const handleRemove = async (id) => {
    try {
      dispatch(addLoading({ id: 'REMOVE_SCORE' }));

      setModalId(null);

      await services.remove(id);

      if (list.page === 1) {
        fetchScores();
      } else {
        list.setPage(1);
      }

      dispatch(
        addNotification({
          id: 'gc.dashboard.remove',
          type: AlertType.Success,
          message: t('gc:dashboard.remove_alert_success', 'Removed'),
        })
      );
    } catch (error) {
      dispatch(
        addNotification({
          id: 'gc.dashboard.remove',
          type: AlertType.Danger,
          message: t('common:alerts.error'),
        })
      );
    } finally {
      dispatch(removeLoading('REMOVE_SCORE'));
    }
  };

  const fetchMarketingStrategyDetails = async () => {
    try {
      dispatch(addLoading({ id: 'FETCH_MARKETING_DETAILS' }));
      const response = await services.fetchMarketingStrategyDetails(game.id);
      setMarketingStrategyDetails(response);
    } catch (error) {
      console.error('error: ', error);
    } finally {
      dispatch(removeLoading('FETCH_MARKETING_DETAILS'));
    }
  };

  const handleShowStrategy = async () => {
    await fetchPlan();
    if (plan?.isExpired || !plan?.permissionMarketingStrategy) {
      setShowPricingTable(true);
      return;
    }
    if (marketingStrategyDetails.status === MarketingStrategyStatus.Proposed) {
      history.push(`/game-checker/execute/${gcId}`);
    } else {
      history.push(`/marketing-strategy/my-strategies/${game.id}`);
    }
  };

  const handleChangeStage = () =>
    isHardChange ? setShowStrategyChangeHardModal(true) : setShowStrategyChangeSoftModal(true);

  const show = showStrategyProposal ? 'collapse show' : 'collapse';

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: t('gc:dashboard.breadcrumb.game_checker', 'Game checker'),
            path: '/game-checker',
            isSeparator: false,
            isActive: false,
          },
          {
            title: t('gc:dashboard.breadcrumb.dashboard', 'Dashboard'),
            path: '/game-checker/dashboard',
            isSeparator: false,
            isActive: true,
          },
        ]}
      >
        {t('gc:dashboard.main_header', 'Game checker')}
      </PageTitle>
      <DefaultTitle
        buttonRight={true}
        button={
          <Link to="/game-checker/new" className="btn btn-primary btn-sm">
            {t('gc:dashboard.run_button', 'Run generator')}
          </Link>
        }
      />
      <Loading items={['FETCH_SCORE']}>
        {score ? (
          <section className={clsx(style.section, 'py-40 px-xxl-40 position-relative')}>
            <div className="row align-items-center">
              <div className="col-xl-7 col-xxl-8">
                <h2 className="fs-16 lh-f16 mb-4">
                  {t(
                    'gc:dashboard.score_title',
                    'Your highest score compared to all participants of Growth Generator'
                  )}
                </h2>
                <div className="mx-n12">
                  <ReportChart totalScore={score.totalScore} />
                </div>
              </div>
              <div className="col-xl-5 col-xxl-4">
                <Score {...score} />
              </div>
            </div>
          </section>
        ) : null}
      </Loading>
      <Loading items={['FETCH_GAME']}>
        {score && game ? (
          <section
            className={clsx(
              style.container,
              'py-40 px-xxl-40 border-bottom border-top border-gray-600 position-relative'
            )}
          >
            <h2 className="fs-23 text-center text-primary">
              {t('gc:dashboard.header_strategy', 'Your  marketing strategy')}
            </h2>
            <p className="fs-14 pt-20 text-center">
              {t(
                'gc:dashboard.description_strategy',
                `Here's your tailored marketing strategy designed to turbocharge your mobile game's success.`
              )}
            </p>
            <div className={clsx('row pt-20 strategyProposal', show)}>
              <div className="d-flex justify-content-center col-sm p-20">
                <StageGoal
                  header={t('gc:dashboard.stage_header', 'Your stage')}
                  paragraph={t(
                    'gc:dashboard.stage_paragraph',
                    'A symbol level on your marketing journey'
                  )}
                  counter={score.gameStage?.stageNumber}
                  type={score.gameStage?.name}
                  buttonTitle={t('gc:dashboard.stage_button', 'New test')}
                  handleButton={handleChangeStage}
                />
              </div>
              <div className="d-flex justify-content-center col-sm p-20">
                <StageGoal
                  header={t('gc:dashboard.goal_header', 'Your goal')}
                  paragraph={t('gc:dashboard.stage_parapgraph', 'You can change it anytime')}
                  counter={game.goalCalculatorResult?.goalValue}
                  type={game.goalCalculatorResult?.goalCategory?.unit}
                  buttonTitle={t('gc:dashboard.goal_button', 'Change goal')}
                  handleButton={() => setShowChangeGoalModal(true)}
                />
              </div>
            </div>
            <div className={clsx('row gx-40 gx-lg-20 gx-xl-40 strategyProposal', show)}>
              <p className="fs-14 pt-20 text-center">
                {t(
                  'gc:dashboard.proposal_strategy',
                  'Get ready to elevate your mobile game to new heights with this customized strategy.'
                )}
              </p>
            </div>
            <div className={clsx('row gx-40 gx-lg-20 gx-xl-40 mt-40 strategyProposal', show)}>
              <Loading items={['FETCH_MARKETING_DETAILS']}>
                <PricingTableModal
                  show={showPricingTable}
                  handleClose={() => setShowPricingTable(false)}
                />
                <div className="d-flex flex-row py-16">
                  <span className="d-flex">
                    {t('gc:dashboard.sample_tactics', 'Sample tactics and products for')}&nbsp;
                  </span>
                  <span className="d-flex fw-bold text-break">
                    {`${t('gc:dashboard.stage', 'stage')} ${
                      marketingStrategyDetails?.currentGameScore?.stageNumber
                    }`}
                  </span>
                </div>
                <div className="row">
                  {sortedArrayOfElements.slice(0, 4).map((el: TacticAndProduct) =>
                    el.elementType === ElementType.Tactic && isTactic(el) ? (
                      <div key={el.tactic.id} className="col align-self-stretch">
                        <SampleTactic
                          id={el.tactic.id}
                          author={el.tactic.author}
                          authorAvatar={el.tactic.authorAvatar}
                          description={el.tactic.description}
                          image={el.tactic.image}
                          name={el.tactic.name}
                          segments={el.tactic.segments}
                          stages={el.tactic.stages}
                          type={el.tactic.type}
                        />
                      </div>
                    ) : el.elementType === ElementType.Product && isProduct(el) ? (
                      <div key={el.product.id} className="col align-self-stretch">
                        <SampleProduct
                          categories={el.product.categories}
                          description={el.product.description}
                          formattedPrice={el.product.formattedPrice}
                          currency={el.product.currency}
                          id={el.product.id}
                          name={el.product.name}
                          price={el.product.price}
                          image={el.product.image}
                          stages={el.product.stages}
                        />
                      </div>
                    ) : null
                  )}
                </div>
                <div className="d-flex py-40 justify-content-end   ">
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={handleShowStrategy}
                  >
                    {t('gc:dashboard.show_strategy', 'Show strategy')}
                  </button>
                </div>
              </Loading>
            </div>
            <div className={clsx(style.buttonCollapse, 'text-center')}>
              <button
                className={clsx('btn btn-primary btn-sm')}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=".strategyProposal"
                onClick={() => setShowStrategyProposal((prev) => !prev)}
              >
                {showStrategyProposal
                  ? t('gc:dashboard.hide_report', 'Hide strategy proposal')
                  : t('gc:dashboard.show_report', 'Show strategy proposal')}
              </button>
            </div>
          </section>
        ) : null}
      </Loading>
      {isHardChange ? (
        <StrategyChangeModal
          type="hard"
          show={showStrategyChangeHardModal}
          clearModal={() => setShowStrategyChangeHardModal(false)}
          handleAccept={() => history.push(`/game-checker/new/${game.id}`)}
        />
      ) : (
        <StrategyChangeModal
          type="soft"
          show={showStrategyChangeSoftModal}
          clearModal={() => setShowStrategyChangeSoftModal(false)}
          handleAccept={() => history.push(`/game-checker/new/${game.id}`)}
        />
      )}
      {game ? (
        <ChangeGoalModal
          show={showChangeGoalModal}
          gameId={game.id}
          gameName={game.name}
          clearModal={() => setShowChangeGoalModal(false)}
        />
      ) : null}
      {score ? (
        <section className={clsx(style.reportContainer, 'position-relative')}>
          <Report
            warningsCount={score.warningsCount}
            warnings={score.warnings}
            suggestionsCount={score.suggestionsCount}
            suggestions={score.suggestions}
          />
        </section>
      ) : null}
      <Loading items={['FETCH_SCORES', 'REMOVE_SCORE']}>
        {list.isFetched ? (
          <section className="section py-40 px-xxl-40 mt-48">
            <h2 className="fs-18 lh-f18 border-bottom pb-16">
              {t('gc:dashboard.prev_title', 'All previous results from Growth Generator')}
            </h2>
            <div className="mb-n20">
              {list.records.length ? (
                <>
                  {list.records.map((item) => (
                    <ScoreThumb key={item.id} {...item} remove={() => setModalId(item.id)} />
                  ))}
                  <Pagination
                    page={list.page}
                    total={list.total}
                    changePage={(v) => list.setPage(v)}
                    perPage={list.perPage}
                    changePerPage={(v) => list.setPerPage(v)}
                    config={configPagination}
                  />
                </>
              ) : (
                <Alert type="info">{t('common:alerts.no_results', 'No results')}</Alert>
              )}
            </div>
          </section>
        ) : null}
      </Loading>

      <Modal show={!!modalId} handleClose={() => setModalId(null)}>
        <h4 className="fs-25 lh-f25 text-center mb-32">
          {t('gc:dashboard.remove_modal_title', 'Are you sure?')}
        </h4>
        <div className="row justify-content-between mt-40">
          <div className="col-auto">
            <button type="button" onClick={() => setModalId(null)} className="btn btn-primary">
              {t('gc:dashboard.remove_modal_cancel_button', 'Cancel')}
            </button>
          </div>
          <div className="col-auto">
            <button type="button" onClick={() => handleRemove(modalId)} className="btn btn-danger">
              {t('gc:dashboard.remove_modal_remove_button', 'Remove')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DashboardGame;
