import React from 'react';
import clsx from 'clsx';
import style from './ButtonAdd.module.scss';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  active?: boolean;
  [x: string]: any;
};

const defaultProps = {
  active: false,
};

const ButtonAdd = ({ value, active, ...rest }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <button
      className={clsx(
        'btn fs-14 px-10 btn-sm d-inline-flex align-items-center justify-content-between',
        style.btn,
        {
          [style.active]: active,
        }
      )}
      type="button"
      {...rest}
    >
      {active ? t('common:buttons.added') : value}
      <span className={clsx(style.icon, 'ms-8')}>
        <SVG src={assetPath(`/icons/${active ? 'check' : 'add'}.svg`)} />
      </span>
    </button>
  );
};

ButtonAdd.defaultProps = defaultProps;

export default ButtonAdd;
