import React from 'react';
import clsx from 'clsx';

type Props = {
  avatar?: React.ReactNode | null;
  header?: React.ReactNode | null;
  badges?: React.ReactNode | null;
  actions?: React.ReactNode | null;
  content?: React.ReactNode | null;
  contentBottom?: React.ReactNode | null;
  disabled?: boolean;
};

const defaultProps = {
  avatar: null,
  header: null,
  badges: null,
  actions: null,
  content: null,
  contentBottom: null,
  disabled: false,
};

const Thumb = ({ avatar, header, badges, actions, content, contentBottom, disabled }: Props) => {
  return (
    <div
      className={clsx('border overflow-hidden mb-20 rounded-lg shadow', {
        'bg-white border-white': !disabled,
        'bg-100 border-gray-200': disabled,
      })}
    >
      <div className="d-flex flex-wrap flex-md-nowrap p-20">
        {avatar && <div className="me-md-20 me-lg-40 mb-20">{avatar}</div>}
        {/*<div className="w-full">*/}
        {/*  <div className="row">*/}
        {/*    <div className="col-xxxl-6 mt-12 mt-md-0">*/}
        {/*      {header && <div className="mb-8">{header}</div>}*/}
        {/*      {badges}*/}
        {/*    </div>*/}
        {/*    <div className="col-xxxl-6 mt-12 mt-xxxl-0">{actions}</div>*/}
        {/*  </div>*/}
        {/*  {content && <div className="mt-20">{content}</div>}*/}
        {/*</div>*/}
        <div className="w-full">
          <div className="row justify-content-between gy-20">
            <div className="col-xxl-auto order-xxl-1">{actions}</div>
            <div className="col-xxl order-xxl-0">{header}</div>
          </div>
          {badges && <div className="mt-20">{badges}</div>}
          {content && <div className="mt-20">{content}</div>}
        </div>
      </div>
      {contentBottom}
    </div>
  );
};

Thumb.defaultProps = defaultProps;

export default Thumb;
