import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type Props = {
  name: string;
  register?: UseFormRegister<FieldValues>;
  [x: string]: any;
};

const defaultProps = {
  register: () => {},
};

const InputSwitch = ({ name, register, ...rest }: Props) => {
  return (
    <div className="form-check form-switch form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="checkbox"
        name={name}
        {...register(name)}
        {...rest}
      />
    </div>
  );
};

InputSwitch.defaultProps = defaultProps;

export default InputSwitch;
