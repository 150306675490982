import React from 'react';
import { AsideDefault } from '@shared/metronic/layout/components/aside/AsideDefault';
import { HeaderWrapper } from '@shared/metronic/layout/components/header/HeaderWrapper';
import { Content } from '@shared/metronic/layout/components/Content';
import { MasterInit } from '@shared/metronic/layout/MasterInit';
import { PageDataProvider } from '@shared/metronic/layout/core';

const MasterLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <HeaderWrapper />

          <div id="kt_content" className="content d-flex flex-column flex-column-fluid fixed-icon">
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Content>{children}</Content>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* end:: Modals */}

      <MasterInit />
    </PageDataProvider>
  );
};

export { MasterLayout };
