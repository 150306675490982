import { useCallback, useState } from 'react';

export interface List<T> {
  records: T[];
  total: number;
}

export interface ListParams {
  records?: number | string;
  page?: number | string;
  search?: string;
  language?: string;
}

const useList = <T, F = null>(initialPerPage = 20, initialFilters?: F) => {
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [records, setRecords] = useState<T[]>([]);
  const [params, setParams] = useState<{
    page: number;
    perPage: number;
    total: number;
    search: string;
    filters?: F;
  }>({
    page: 1,
    perPage: initialPerPage,
    total: 1,
    search: '',
    filters: initialFilters,
  });

  const handleFetch = useCallback((v: List<T>) => {
    if (!isFetched) setIsFetched(true);

    setRecords(v.records);

    setParams((prevState) => ({
      ...prevState,
      total: Math.ceil(v.total / prevState.perPage),
    }));
  }, []);

  const setPage = useCallback((v: number) => {
    setParams((prevState) => ({
      ...prevState,
      page: v,
    }));
  }, []);

  const setPerPage = useCallback((v: number) => {
    setParams((prevState) => ({
      ...prevState,
      page: 1,
      perPage: v,
    }));
  }, []);

  const setSearch = useCallback((v: string) => {
    setParams((prevState) => ({
      ...prevState,
      page: 1,
      search: v,
    }));
  }, []);

  const setFilters = useCallback((v: F) => {
    setParams((prevState) => ({
      ...prevState,
      page: 1,
      filters: v,
    }));
  }, []);

  return {
    isFetched,
    page: params.page,
    perPage: params.perPage,
    total: params.total,
    search: params.search,
    setPage,
    setPerPage,
    setSearch,
    records,
    handleFetch,
    filters: params.filters,
    setFilters,
  };
};

export default useList;
