import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '@app/modules/auth';
import * as softLaunchStore from '@app/modules/soft-launch/store';
import * as gcStore from '@app/modules/game-checker/store';
import * as cartStore from '@app/modules/cart/store';
import loadingReducer from '@app/shared/features/loading/loadingSlice';
import notificationsReducer from '@app/shared/features/notifications/notificationsSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  loading: loadingReducer,
  notifications: notificationsReducer,
  softLaunch: softLaunchStore.reducer,
  gc: gcStore.reducer,
  cart: cartStore.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga(), softLaunchStore.saga(), gcStore.saga(), cartStore.cartSaga()]);
}
