import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addOne as addLoading,
  removeOne as removeLoading,
} from '@shared/features/loading/loadingSlice';

const useDictionary = <T>(fetchCallback: () => Promise<T[]>, loadingId = 'FETCH_DICTIONARY') => {
  const dispatch = useDispatch();
  const [dictionary, setDictionary] = useState<T[]>([]);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: loadingId }));

        const response = await fetchCallback();

        setDictionary(response || []);
      } catch (error) {
      } finally {
        dispatch(removeLoading(loadingId));
      }
    })();
  }, []);

  return [dictionary];
};

export default useDictionary;
