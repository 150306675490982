import React, { useEffect } from 'react';
import style from './ContextMenu.module.scss';
import SVG from 'react-inlinesvg';
import { MenuComponent } from '@shared/metronic/assets/ts/components';
import { assetPath } from '@shared/utils/asset';

type Props = {
  children: React.ReactNode;
  buttonContent?: string | React.ReactNode;
  className?: string;
};

const defaultProps = {
  buttonContent: <SVG src={assetPath('/icons/dots-two.svg')} />,
  className: '',
};

const ContextMenu = ({ children, buttonContent, className }: Props) => {
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
    <div className={className}>
      <button
        type="button"
        className={`btn btn-sm btn-icon btn-color-primary ${style.button}`}
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="top-end"
      >
        {buttonContent}
      </button>
      <div
        className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px ${style.menu}`}
        data-kt-menu="true"
      >
        {children}
      </div>
    </div>
  );
};

ContextMenu.defaultProps = defaultProps;

export default ContextMenu;
