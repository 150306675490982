import React, { useMemo } from 'react';
import styles from './SampleProduct.module.scss';
import { useTranslation } from 'react-i18next';
import {
  ProductClass,
  Stage,
} from '@app/modules/game-checker/models/MarketingStrategyDetailsModel';
import { ifOneAfterAnother } from '@app/modules/products/shared/utils/ifOneAfterAnother';
import { assetApiPath } from '@shared/utils/asset';
import clsx from 'clsx';

interface Props extends ProductClass {
  isHoverable?: boolean;
}

const SampleProduct = ({
  categories,
  description,
  formattedPrice,
  currency,
  id,
  name,
  price,
  image,
  stages,
  isHoverable = false,
}: Props) => {
  const { t } = useTranslation(['products', 'gc']);

  //TODO: move this function to reusable utill
  const formatter = useMemo(
    () => (currency: string) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
      }),
    [id]
  );

  const formattedStages = stages.map((el: Stage) => el.stageNumber).sort();

  return (
    <div
      className={clsx(styles.container, {
        [styles.containerHovered]: isHoverable,
      })}
    >
      <div className={styles.headerWrapper}>
        <img src={image?.path ? assetApiPath(image?.path) : ''} className={styles.imgWrapper} />
        {name ? <div className={styles.name} dangerouslySetInnerHTML={{ __html: name }} /> : null}
      </div>
      <div className={styles.subjectWrapper}>
        {categories.length > 0 ? (
          <span className={styles.category}>
            {t('products.category', 'Category')}:&nbsp;
            <span>{categories.map(({ name }) => name).join(', ')}</span>
          </span>
        ) : null}
        {stages.length > 0 && !stages.find((el: Stage) => el.stageNumber === null) ? (
          <span className={styles.stage}>
            {t('gc:dashboard.stage', 'Stage')}:&nbsp;
            <span>
              {formattedStages.length >= 2 && ifOneAfterAnother(formattedStages)
                ? t('products.stage.numbers', 'from {{first}} to {{second}}', {
                    first: formattedStages[0],
                    second: formattedStages[formattedStages.length - 1],
                  })
                : formattedStages.join(', ')}
            </span>
          </span>
        ) : null}
        {name ? (
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </div>
      <div className={styles.priceWrapper}>
        <span className={styles.price}>{formatter(currency).format(Number(formattedPrice))}</span>
      </div>
    </div>
  );
};

export default SampleProduct;
