import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, Link, useHistory, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Login, Register, Forgot } from './forms';
import Wrapper from './components/Wrapper/Wrapper';
import LoginWrapper from './components/LoginWrapper/LoginWrapper';
import { Alert } from '@shared/components';
import RegisterConfirm from './RegisterConfirm';
import SocialConfirm from './SocialConfirm';

const AuthModule: React.FC = () => {
  const { t } = useTranslation(['auth']);
  const [alert, setAlert] = useState(null);
  const history = useHistory();
  const location = useLocation<{ redirectAlert: boolean }>();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (query.has('alert')) return;

    setAlert(null);
  }, [location]);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (query.has('logout')) {
      setAlert({
        type: 'success',
        content: t('auth:logout.success_alert', 'Logout success'),
      });
    }
  }, []);

  const renderAlert = () => alert && <Alert type={alert.type}>{alert.content}</Alert>;

  return (
    <Wrapper>
      <Switch>
        <Route path="/auth/login">
          <LoginWrapper social>
            <div className="text-center mb-32">
              <h1 className="fs-25 lh-25 fw-normal mb-16">{t('auth:login.title', 'Log in')}</h1>
              <p>
                <Trans
                  i18nKey="auth:login.description"
                  defaults="Don’t have an account? <0>Sign up</0>"
                >
                  <Link to="/auth/register" />
                </Trans>
              </p>
            </div>
            {renderAlert()}
            <Login setAlert={setAlert} />
          </LoginWrapper>
        </Route>
        <Route path="/auth/register">
          <LoginWrapper social>
            <div className="text-center mb-32">
              <h1 className="fs-25 lh-25 fw-normal mb-16">{t('auth:register.title', 'Sign up')}</h1>
              <p>
                <Trans
                  i18nKey="auth:register.description"
                  defaults="Already have an account? <0>Log in</0>"
                >
                  <Link to="/auth/login" />
                </Trans>
              </p>
            </div>
            {renderAlert()}
            <Register setAlert={setAlert} />
          </LoginWrapper>
        </Route>
        <Route path="/auth/forgot-password">
          <LoginWrapper>
            <div className="text-center mb-32">
              <h1 className="fs-25 lh-25 fw-normal mb-16">
                {t('auth:forgot.title', 'Forgot password?')}
              </h1>
              <p>{t('auth:forgot.description', 'Enter your email to reset your password.')}</p>
            </div>
            {renderAlert()}
            <Forgot setAlert={setAlert} />
          </LoginWrapper>
        </Route>
        <Route path="/auth/confirm-registration">
          <RegisterConfirm setAlert={setAlert} />
        </Route>
        <Route path="/auth/provider/facebook">
          <SocialConfirm setAlert={setAlert} />
        </Route>
        <Route path="/auth/provider/google">
          <SocialConfirm setAlert={setAlert} />
        </Route>
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
      </Switch>
    </Wrapper>
  );
};

export { AuthModule };
