import axios from 'axios';
import { List, ListParams } from '@shared/hooks';
import { GameScoreShortModel } from '@app/modules/game-checker/models/GameScoreShortModel';
import { GameScoreModel } from '@app/modules/game-checker/models/GameScoreModel';
import { LiveScoreModel } from '@app/modules/game-checker/models/LiveScoreModel';
import { OtherScoreModel } from '@app/modules/game-checker/models/OtherScoreModel';
import { GameModel } from '@app/modules/game-checker/models/GameModel';
import { AnswerModel } from '@app/modules/game-checker/models/AnswerModel';
import { CustomAnswerModel } from '@app/modules/game-checker/models/CustomAnswerModel';
import { CountryModel } from '@shared/models/CountryModel';
import { GoalCategoryModel } from './models/GoalCategoryModel';
import { MarketingStrategyDetailsModel } from './models/MarketingStrategyDetailsModel';

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

export const fetchScores = async (params: ListParams): Promise<List<GameScoreShortModel>> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/game-score`, { params })
      .then((res) => res.data);
  } catch (error) {
    return {
      records: [],
      total: 0,
    };
  }
};

export const fetchScore = async (id: number | string) => {
  return axios
    .get<GameScoreModel>(`${API_URL}/api/game-dev/game-score/${String(id)}`)
    .then((res) => res.data);
};

export const fetchBestScore = async () => {
  return axios
    .get<GameScoreShortModel>(`${API_URL}/api/game-dev/game-score/user-best-game-score`)
    .then((res) => res.data);
};

export const fetchLiveScores = async (): Promise<LiveScoreModel[]> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/game-score/other-user-results-live`)
      .then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchOtherScores = async (): Promise<OtherScoreModel[]> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/game-score/other-user-score`)
      .then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const downloadPdf = async (id: number | string) => {
  return axios
    .get(`${API_URL}/api/game-dev/game-score/${String(id)}/pdf`, {
      responseType: 'blob',
    })
    .then((res) => res.data);
};

export const remove = async (id: number | string) => {
  return axios.delete(`${API_URL}/api/game-dev/game-score/${String(id)}`).then((res) => res.data);
};

export const fetchGame = async (id: string): Promise<GameModel> => {
  return axios.get(`${API_URL}/api/game-dev/game/${id}`).then((res) => res.data);
};

export const fetchGames = async (): Promise<List<GameModel>> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/game`).then((res) => res.data);
  } catch (error) {
    return {
      records: [],
      total: 0,
    };
  }
};

export interface CreateScoreRequest {
  game?: number;
  gameName?: string;
  category?: number;
  email?: string;
  acceptTerms?: boolean;
  subscribeNewsletter?: boolean;
  acceptAdvertisement?: boolean;
  acceptPlatformContact?: boolean;
}

export interface CreateScoreResponse {
  gameScore: GameScoreShortModel;
  firstUserAnswer: AnswerModel | null;
}

export const createScore = async (body: CreateScoreRequest) => {
  try {
    return await axios
      .post<CreateScoreResponse>(`${API_URL}/api/game-dev/game-score`, body)
      .then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

export interface UpdateAnswerRequest {
  question: number;
  userSelectedAnswerOption: number | null;
  userSelectedAnswerOptions: number[];
  userCustomAnswer: string | null;
  userCustomAnswers: CustomAnswerModel[];
}

export interface CreateScoreUpdateAnswerResponse {
  nextUserAnswer: AnswerModel | null;
  userAnswer: AnswerModel;
  questionsCount: number;
}

export const updateAnswer = async (
  id: number | string,
  body: UpdateAnswerRequest
): Promise<any> => {
  try {
    return await axios
      .put<CreateScoreUpdateAnswerResponse>(
        `${API_URL}/api/game-dev/game-score/answer/${String(id)}`,
        body
      )
      .then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

export const fetchSoftLaunchCountries = async (): Promise<CountryModel[]> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/country`).then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchGoalCategories = async (): Promise<GoalCategoryModel[]> => {
  return axios.get(`${API_URL}/api/game-dev/goal-categories`).then((res) => res.data);
};

export interface PostChangeGoalBodyInterface {
  goalCategoryId: number;
  goalValue: number;
}

export const postChangeGoal = async (
  gameId: string | number,
  formData: PostChangeGoalBodyInterface
): Promise<any> => {
  return axios
    .post(`${API_URL}/api/game-dev/game-goal/${String(gameId)}`, formData)
    .then((res) => res.data);
};

export const fetchMarketingStrategyDetails = async (
  gameId: string | number
): Promise<MarketingStrategyDetailsModel> => {
  return axios
    .get(`${API_URL}/api/game-dev/marketing-strategy/${String(gameId)}`)
    .then((res) => res.data);
};

export const executeStrategy = async (gameId: string | number) => {
  return axios
    .post(`${API_URL}/api/game-dev/marketing-strategy/${String(gameId)}/start-execution`)
    .then((res) => {
      return res.data;
    });
};
