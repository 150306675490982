import React from 'react';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';

type Props = {
  children: React.ReactNode;
  label: string;
  open: boolean;
  toggle: () => void;
};

const EditField = ({ children, label, open, toggle }: Props) => {
  return (
    <div className="py-8 border-bottom border-gray-200">
      <div className="row align-items-center gy-8">
        <div className="col-12 col-sm-auto col-xxl-3 mb-10 mb-sm-0">
          <p>{label}</p>
        </div>
        <div className="col">{children}</div>
        <div className="col-auto col-xxl-2 text-end">
          <button
            type="button"
            className="btn btn-icon h-20 svg-icon svg-icon-16 svg-icon-primary"
            onClick={toggle}
          >
            <SVG
              className="svg-icon svg-icon-18 svg-icon-gray-800"
              src={assetPath(open ? '/icons/close.svg' : '/icons/pen.svg')}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditField;
