//func to check if in sorted array there are numbers one after another
export const ifOneAfterAnother = (arr: number[]) => {
  if (arr.length < 2) {
    return false;
  }

  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i + 1] - arr[i] !== 1) {
      return false;
    }
  }

  return true;
};
