import React from 'react';
import clsx from 'clsx';
import style from './CardInfo.module.scss';
import { KTSVG } from '@shared/metronic/helpers';

type Props = {
  title: string | number;
  description: string;
  iconPath?: string;
  large?: boolean;
};

const defaultProps = {
  iconPath: '',
  large: false,
};

const CardInfo = ({ title, description, iconPath, large }: Props) => {
  return (
    <div
      className={clsx('p-20 h-full', style.card, {
        'py-md-40': large,
      })}
    >
      <div className="d-flex">
        {iconPath && (
          <div className={style.icon}>
            <KTSVG className="svg-icon-primary" path={iconPath} />
          </div>
        )}

        <div>
          <div className="fs-23 mb-4 fw-boldest">{title}</div>
          <div className={`${style.description} fs-14`}>{description}</div>
        </div>
      </div>
    </div>
  );
};

CardInfo.defaultProps = defaultProps;

export default CardInfo;
