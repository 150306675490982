import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error500 from './pages/Error500';
import Error404 from './pages/Error404';

const ErrorsModule: React.FC = () => {
  return (
    <Switch>
      <Route path="/error/404" exact={true} component={Error404} />
      <Route path="/error/500" exact={true} component={Error500} />
      <Redirect from="/error" exact={true} to="/error/404" />
      <Redirect to="/error/404" />
    </Switch>
  );
};

export default ErrorsModule;
