import React from 'react';
import RS from 'react-select';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SelectMultiItemModel } from '@shared/components';
import { customStyles } from '@shared/components/SelectMulti/styles';

type Props = {
  items: SelectMultiItemModel[];
  name: string;
  error?: string;
  placeholder?: string;
  label?: string;
  [x: string]: any;
};

const defaultProps = {
  error: '',
  placeholder: '',
  label: '',
};

const SelectMulti = ({ items, name, error, placeholder, label, ...rest }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div>
      {label && (
        <label htmlFor={`select-id-${name}`} className="fs-12 lh-f12 fw-bold mb-10">
          {label}
        </label>
      )}
      <RS
        className={clsx({
          'is-invalid': !!error,
        })}
        isClearable
        isMulti
        options={items}
        placeholder={placeholder || t('common:select_placeholder', 'Select...')}
        noOptionsMessage={() => t('common:select_no_options_placeholder', 'No options')}
        styles={customStyles}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

SelectMulti.defaultProps = defaultProps;

export default SelectMulti;
