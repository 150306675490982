import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as authCrud from '@app/modules/auth/redux/AuthCRUD';
import { useTranslation } from 'react-i18next';
import { translateErrorMessage } from '@shared/utils/api';

const RegisterConfirm = ({ setAlert }) => {
  const { t } = useTranslation(['common', 'auth']);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const token = query.get('token');

  useEffect(() => {
    if (token) {
      const fetch = async () => {
        try {
          await authCrud.registerConfirm(token);

          setAlert({
            type: 'success',
            content: t('auth:register.confirm_alert', 'Registration confirmed, you can login now'),
          });
        } catch ({
          response: {
            status,
            data: { error },
          },
        }) {
          const errorMsg = translateErrorMessage(error, t);

          if (status !== 404 && errorMsg) {
            setAlert({
              type: 'danger',
              content: errorMsg,
            });

            return;
          }

          setAlert({
            type: 'danger',
            content: t('common:alerts.error'),
          });
        }
      };

      fetch();
    }
  }, []);

  return <Redirect to={`/auth/login${token && '?alert'}`} />;
};

export default RegisterConfirm;
