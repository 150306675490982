import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Select } from '@shared/components';

export interface ConfigItemModel {
  id: number | string;
  name: string;
  icon?: React.ReactNode;
}

type Props = {
  page: number;
  total: number;
  changePage: (v: number) => void;
  perPage?: number;
  changePerPage?: (v: number) => void | null;
  config?: ConfigItemModel[];
};

const defaultProps = {
  changePerPage: null,
  perPage: 20,
  config: [
    {
      id: 20,
      name: '20',
    },
    {
      id: 50,
      name: '50',
    },
    {
      id: 100,
      name: '100',
    },
  ],
};

const Pagination = ({ page, total, changePage, perPage, changePerPage, config }: Props) => {
  const { t } = useTranslation(['common']);

  if (typeof changePerPage !== 'function' && total <= 1) return null;

  const prev = page > 1 ? page - 1 : page;
  const next = page < total ? page + 1 : page;
  let sliceStart = 1;
  let sliceEnd = 6;

  if (total > 6 && page > 2) {
    sliceStart = total - (page - 3) > 6 ? page - 2 : total - 4;
    sliceEnd = page + 2 < total ? page + 2 : total;
  }

  const pages = Array.from(new Array(total), (_, i) => ({
    value: i + 1,
    active: i + 1 === page,
  })).slice(sliceStart - 1, sliceEnd);

  const handleChange = (value: number) => {
    changePage(value);
  };

  const handlePerPageChange = (value: number) => {
    changePerPage(value);
  };

  return (
    <div className={clsx('my-24')}>
      <div className="row justify-content-between">
        {typeof changePerPage === 'function' && (
          <div className="col-12 col-md-6 mb-16 mb-md-0">
            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
              <p className="fs-14 lh-f14 me-12">
                {t('common:pagination.per_page', 'Results per page')}
              </p>
              <Select
                items={config}
                name="perPage"
                secondary
                small
                withoutPlaceholder
                onChange={(e) => handlePerPageChange(e.target.value)}
                value={perPage}
              />
            </div>
          </div>
        )}

        {total > 1 && (
          <div className="col">
            <ul className="pagination justify-content-center justify-content-md-end">
              <li
                className={clsx('page-item', {
                  disabled: page < 2,
                })}
              >
                <button type="button" className="page-action" onClick={() => handleChange(prev)}>
                  {t('common:pagination.prev', 'Previous')}
                </button>
              </li>
              {pages.map((p) => (
                <li
                  key={p.value}
                  className={clsx('page-item', {
                    active: p.active,
                  })}
                >
                  <button type="button" className="page-link" onClick={() => handleChange(p.value)}>
                    {p.value}
                  </button>
                </li>
              ))}
              <li
                className={clsx('page-item', {
                  disabled: page >= total,
                })}
              >
                <button type="button" className="page-action" onClick={() => handleChange(next)}>
                  {t('common:pagination.next', 'Next')}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

Pagination.defaultProps = defaultProps;

export default Pagination;
