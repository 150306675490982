import React, { ForwardedRef, forwardRef } from 'react';
import clsx from 'clsx';
import { KTSVG } from '@shared/metronic/helpers';

type Props = {
  onClear?: (() => void) | null;
  value?: string;
  placeholder?: string;
  label?: string;
  error?: string;
  large?: boolean;
  small?: boolean;
  secondary?: boolean;
};

const defaultProps = {
  onClear: null,
  value: '',
  placeholder: '',
  label: '',
  error: '',
  large: false,
  small: false,
  secondary: false,
};

const Trigger = forwardRef(
  (
    { onClear, value, placeholder, label, error, large, small, secondary, ...rest }: Props,
    ref: ForwardedRef<any>
  ): JSX.Element => {
    return (
      <div>
        {label && (
          <label htmlFor="" className="fs-12 lh-f12 fw-bold mb-10">
            {label}
          </label>
        )}
        <div className="input-group has-validation">
          <button
            type="button"
            ref={ref}
            {...rest}
            className={clsx(
              'form-control border-right-0 border-top-right-radius-0 border-bottom-right-radius-0 text-start',
              {
                'is-invalid': !!error,
                'form-control-lg': large,
                'form-control-sm': small,
                'form-control-solid': !secondary,
              }
            )}
          >
            {value || placeholder}
          </button>
          <span
            className={clsx('input-group-text ms-0 border-left-0', {
              'input-group-text-solid': !secondary,
            })}
          >
            {value && onClear ? (
              <button type="button" className="p-0" onClick={onClear}>
                <KTSVG
                  path="/asort/icons/close-secondary.svg"
                  className="svg-icon-12 svg-icon-gray-900"
                />
              </button>
            ) : (
              <KTSVG path="/icons/calendar.svg" className="svg-icon-16 svg-icon-gray-900" />
            )}
          </span>
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    );
  }
);

Trigger.defaultProps = defaultProps;

export default Trigger;
