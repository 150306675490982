import axios from 'axios';
import { CampaignModel } from '@app/modules/soft-launch/models/CampaignModel';
import { List } from '@shared/hooks';
import fakeStatistics from '@app/modules/soft-launch/fakeStatistics';
import { CreateCampaignRequestModel } from '@app/modules/soft-launch/models/CreateCampaignRequestModel';
import { ListParamsCampaignsModel } from '@app/modules/soft-launch/models/ListParamsCampaignsModel';
import { CampaignTypeModel } from '@app/modules/soft-launch/models/CampaignTypeModel';
import { CampaignMediaTypeModel } from '@app/modules/soft-launch/models/CampaignMediaTypeModel';
import { StatsModel } from '@shared/models/StatsModel';

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

export const fetchCampaigns = async (
  params: ListParamsCampaignsModel
): Promise<List<CampaignModel>> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/campaign`, { params }).then((res) => res.data);
  } catch (error) {
    return {
      records: [],
      total: 0,
    };
  }
};

export const fetchCampaign = async (id: number | string) => {
  return axios
    .get<CampaignModel>(`${API_URL}/api/game-dev/campaign/${String(id)}`)
    .then((res) => res.data);
};

export const removeCampaign = async (id: number | string) => {
  return axios.delete(`${API_URL}/api/game-dev/campaign/${String(id)}`).then((res) => res.data);
};

export const createCampaign = async (body: CreateCampaignRequestModel) => {
  return axios.post(`${API_URL}/api/game-dev/campaign`, body).then((res) => res.data);
};

export const patchCampaign = async (id: number | string, body: CreateCampaignRequestModel) => {
  return axios
    .patch(`${API_URL}/api/game-dev/campaign/${String(id)}`, body)
    .then((res) => res.data);
};

export const fetchCampaignsStatistics = async (): Promise<StatsModel[]> => {
  const { statistics } = await axios
    .get(`${API_URL}/api/game-dev/campaign/statistics?version=2`)
    .then((res) => res.data);

  return statistics;
};

export const fetchCampaignStatistics = async () => {
  return fakeStatistics;
};

export const fetchCampaignTypes = async (): Promise<CampaignTypeModel[]> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/campaign/type`).then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const fetchCampaignMediaTypes = async (
  goalId: number
): Promise<CampaignMediaTypeModel[]> => {
  try {
    return await axios
      .get(`${API_URL}/api/game-dev/campaign/media-file-type`, {
        params: {
          campaignGoal: goalId,
        },
      })
      .then((res) => res.data);
  } catch (error) {
    return [];
  }
};
