import React from 'react';
import CH from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { ApexOptions } from 'apexcharts';

type Props = {
  id: string;
  series: Array<any>;
  options?: ApexOptions;
  category?: boolean;
  [key: string]: any;
};

export const colors = ['#3E51E5', '#FD506F', '#50F4FD', '#06B98E', '#9672C2', '#40C3E6', '#172047'];

export const getColor = (index): string => {
  if (colors[index]) {
    return colors[index];
  }

  return '#a2a6b5';
};

export const initialChartOptions = {
  chart: {
    parentHeightOffset: 0,
    fontFamily: 'Red Hat Display", sans-serif',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: colors,
  legend: {
    show: false,
  },
  stroke: {
    width: 2,
  },
  markers: {
    size: 4,
    strokeWidth: 0,
  },
  tooltip: {
    x: {
      show: true,
      format: 'dd.MM.yy',
    },
  },
  annotations: {
    yaxis: [
      {
        strokeDashArray: 0,
        borderColor: '#A2A6B5',
      },
    ],
  },
  xaxis: {
    type: 'datetime',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: true,
    },
    labels: {
      style: {
        fontSize: '9px',
      },
      offsetY: -4,
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MM',
        day: 'dd.MM',
        hour: 'HH:mm',
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    show: true,
  },
  grid: {
    borderColor: '#ECEEFC',
  },
};

const Chart = ({ id, options, series, category, ...rest }: Props) => {
  const { t } = useTranslation(['common']);

  const assignOptions = JSON.parse(JSON.stringify(initialChartOptions));

  if (category) {
    assignOptions.xaxis.type = 'category';
  }

  return (
    <div className="position-relative">
      {!series.length && (
        <p className="position-absolute top-50 start-50 translate-middle fs-25 fw-bold">
          {t('common:alerts.no_data', 'No data')}
        </p>
      )}
      <CH options={Object.assign(assignOptions, options)} series={series} {...rest} />
    </div>
  );
};

export default Chart;
