export default [
  {
    id: 1,
    name: 'Users',
    data: [
      {
        x: '02/8/2017 GMT',
        y: 14,
      },
      {
        x: '02/9/2017 GMT',
        y: 4,
      },
      {
        x: '02/10/2017 GMT',
        y: 34,
      },
      {
        x: '02/11/2017 GMT',
        y: 22,
      },
      {
        x: '02/12/2017 GMT',
        y: 21,
      },
      {
        x: '02/13/2017 GMT',
        y: 43,
      },
      {
        x: '02/14/2017 GMT',
        y: 33,
      },
      {
        x: '02/15/2017 GMT',
        y: 22,
      },
      {
        x: '02/16/2017 GMT',
        y: 22,
      },
    ],
  },
  {
    id: 2,
    name: 'Downloads',
    data: [
      {
        x: '02/8/2017 GMT',
        y: 1,
      },
      {
        x: '02/9/2017 GMT',
        y: 13,
      },
      {
        x: '02/10/2017 GMT',
        y: 22,
      },
      {
        x: '02/11/2017 GMT',
        y: 32,
      },
      {
        x: '02/12/2017 GMT',
        y: 41,
      },
      {
        x: '02/13/2017 GMT',
        y: 31,
      },
      {
        x: '02/14/2017 GMT',
        y: 76,
      },
      {
        x: '02/15/2017 GMT',
        y: 106,
      },
      {
        x: '02/16/2017 GMT',
        y: 22,
      },
    ],
  },
  {
    id: 3,
    name: 'Revenue',
    data: [
      {
        x: '02/8/2017 GMT',
        y: 17,
      },
      {
        x: '02/9/2017 GMT',
        y: 23,
      },
      {
        x: '02/10/2017 GMT',
        y: 42,
      },
      {
        x: '02/11/2017 GMT',
        y: 32,
      },
      {
        x: '02/12/2017 GMT',
        y: 21,
      },
      {
        x: '02/13/2017 GMT',
        y: 21,
      },
      {
        x: '02/14/2017 GMT',
        y: 55,
      },
      {
        x: '02/15/2017 GMT',
        y: 44,
      },
      {
        x: '02/16/2017 GMT',
        y: 77,
      },
    ],
  },
  {
    id: 4,
    name: 'Budget',
    data: [
      {
        x: '02/8/2017 GMT',
        y: 22,
      },
      {
        x: '02/9/2017 GMT',
        y: 21,
      },
      {
        x: '02/10/2017 GMT',
        y: 42,
      },
      {
        x: '02/11/2017 GMT',
        y: 32,
      },
      {
        x: '02/12/2017 GMT',
        y: 21,
      },
      {
        x: '02/13/2017 GMT',
        y: 55,
      },
      {
        x: '02/14/2017 GMT',
        y: 68,
      },
      {
        x: '02/15/2017 GMT',
        y: 54,
      },
      {
        x: '02/16/2017 GMT',
        y: 65,
      },
    ],
  },
];
