import React from 'react';
import style from './Notifications.module.scss';
import Notification from './Notification/Notification';
import { selectors, Notification as NotificationType } from './notificationsSlice';
import { useAppSelector } from '@app/setup';

const Notifications = (): JSX.Element => {
  const items = useAppSelector(selectors.selectAll);

  return (
    <div className={style.wrapper}>
      {items.map(({ id, type, message, description }: NotificationType) => (
        <Notification key={id} id={id} type={type} message={message} description={description} />
      ))}
    </div>
  );
};

export default Notifications;
