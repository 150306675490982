import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chart, { ChartDataModel } from '@app/modules/game-checker/components/Chart/Chart';
import {
  addOne as addLoading,
  removeOne as removeLoading,
} from '@app/shared/features/loading/loadingSlice';
import * as services from '@app/modules/game-checker/services';
import { OtherScoreModel } from '@app/modules/game-checker/models/OtherScoreModel';
import Loading from '@app/shared/features/loading/Loading';
import { useDispatch } from 'react-redux';

type Props = {
  totalScore: number;
};

const defaultProps = {};

const ReportChart = ({ totalScore }: Props) => {
  const { t } = useTranslation(['gc']);
  const dispatch = useDispatch();
  const [otherScores, setOtherScores] = useState<OtherScoreModel[]>([]);

  useEffect(() => {
    fetchOtherScores();
  }, []);

  const fetchOtherScores = async () => {
    try {
      dispatch(addLoading({ id: 'FETCH_OTHER_SCORES' }));

      const response = await services.fetchOtherScores();

      setOtherScores(response);
    } catch (error) {
    } finally {
      dispatch(removeLoading('FETCH_OTHER_SCORES'));
    }
  };

  const chartCategories = useMemo((): string[] => {
    return otherScores.map((s) => s.label);
  }, [otherScores]);

  const chartData = useMemo((): ChartDataModel[] => {
    const scoreValue = totalScore;

    return [
      {
        name: t('gc:c_report_chart.chart_you', 'You'),
        data: otherScores.map((s) =>
          scoreValue >= s.minScore && scoreValue <= s.maxScore ? scoreValue : 0
        ),
      },
      {
        name: t('gc:c_report_chart.chart_others', 'Others'),
        data: otherScores.map((s) => {
          if (scoreValue >= s.minScore && scoreValue <= s.maxScore) {
            if (s.value < scoreValue) {
              return 0;
            } else {
              return s.value - scoreValue;
            }
          }

          return s.value;
        }),
      },
    ];
  }, [otherScores, totalScore]);

  return (
    <Loading items={['FETCH_OTHER_SCORES']}>
      <Chart categories={chartCategories} data={chartData} />
    </Loading>
  );
};

ReportChart.defaultProps = defaultProps;

export default ReportChart;
