import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as auth from '@app/modules/auth/redux/AuthRedux';
import { useDispatch } from 'react-redux';

const SocialConfirm = ({ setAlert }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(history.location.search);
  const token = query.get('jwtToken');
  const error = query.get('error');
  const errorMessage = query.get('errorMessage');

  useEffect(() => {
    if (token) {
      dispatch(auth.actions.login(token));
    }

    if (error) {
      setAlert({
        type: 'danger',
        content: errorMessage,
      });

      history.push('/auth/login?alert');
    }
  }, []);

  return <Redirect to={`/auth/login${token && '?alert'}`} />;
};

export default SocialConfirm;
