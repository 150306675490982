import React from 'react';
import styles from './SampleTactic.module.scss';
import {
  CurrentGameScore,
  Segment,
  Tactic,
  Type,
} from '@app/modules/game-checker/models/MarketingStrategyDetailsModel';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { assetApiPath, assetPath } from '@shared/utils/asset';
import { ifOneAfterAnother } from '@app/modules/products/shared/utils/ifOneAfterAnother';
import SVG from 'react-inlinesvg';
interface Props extends Tactic {
  showTooltip?: boolean;
}

const SampleTactic = ({
  author,
  authorAvatar,
  name,
  segments,
  stages,
  type,
  showTooltip = false,
}: Props) => {
  const { t } = useTranslation(['products', 'gc']);
  const formattedStages = stages.map((el: CurrentGameScore) => el.stageNumber).sort();

  return (
    <div
      className={clsx(styles.container, {
        [styles.borderTacticLiveOps]: type === Type.LiveOps,
        [styles.borderTacticMarketing]: type === Type.Marketing,
        [styles.containerHovered]: showTooltip,
      })}
    >
      <div className={styles.headerContainer}>
        {type === Type.LiveOps || type === Type.Marketing ? (
          <span
            className={clsx(styles.typeTacticWrapper, {
              [styles.typeTacticLiveOps]: type === Type.LiveOps,
              [styles.typeTacticMarketing]: type === Type.Marketing,
            })}
          >
            {t('gc:sample.tactice_marketing_type', '{{type}} Tactic', {
              type: type === Type.LiveOps ? 'Live-Ops' : 'Marketing',
            })}
          </span>
        ) : null}
        {/* <SVG src={assetPath('/icons/unliked.svg')} className={styles.likeIcon} /> */}
        {/* <SVG src={assetPath('/icons/liked.svg')} className={styles.likeIcon} /> */}
      </div>
      <div className={styles.descriptionContainer}>
        {name ? (
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: name }} />
        ) : null}
        {stages.length > 0 && !stages.find((el: CurrentGameScore) => el.stageNumber === null) ? (
          <span className={styles.stage}>
            {t('gc:dashboard.stage', 'Stage')}:&nbsp;
            <span>
              {formattedStages.length >= 2 && ifOneAfterAnother(formattedStages)
                ? t('products.stage.numbers', 'from {{first}} to {{second}}', {
                    first: formattedStages[0],
                    second: formattedStages[formattedStages.length - 1],
                  })
                : formattedStages.join(', ')}
            </span>
          </span>
        ) : null}
        {segments.length > 0 ? (
          <>
            <span className={styles.stage}>{t('gc:sample.segment', 'Segment')}:&nbsp;</span>
            <div className={styles.segmentListContainer}>
              {segments.map((segment: Segment) => (
                <div className={styles.segmentWrapper}>
                  <img
                    key={segment.id}
                    src={segment?.icon?.path ? assetApiPath(segment.icon.path) : ''}
                    className={styles.segmentImage}
                  />
                  <span className={styles.segmentName}>{segment?.name ?? ''}</span>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <div className={styles.authorContainer}>
        {authorAvatar?.path ? (
          <img src={assetApiPath(authorAvatar.path)} className={styles.icon} />
        ) : (
          <SVG src={assetPath('/icons/p-logo.svg')} className={styles.icon} />
        )}
        <div className={styles.signContainer}>
          <span className={styles.authorName}>{author ?? 'Playstrict'}</span>
          <span className={styles.created}>{t('gc:sample.created', 'created by')}</span>
        </div>
      </div>
    </div>
  );
};

export default SampleTactic;
