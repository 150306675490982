import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '@app/shared/metronic/layout/core';
import AuthInit from '@app/modules/auth/redux/AuthInit';
import { Routes } from '@app/routing/Routes';
import Notifications from '@app/shared/features/notifications/Notifications';
import LimitModal from '@shared/partials/LimitModal/LimitModal';

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <AuthInit>
            <Routes />
            <LimitModal />
          </AuthInit>
        </LayoutProvider>
        <Notifications />
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
