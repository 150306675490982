import React from 'react';
import { Datepicker } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { ChartGranularity } from '@shared/enums/ChartGranularity';
import { parseDatepickerDate } from '@shared/utils/date';
import clsx from 'clsx';
import { ChartFilters as CF } from '@shared/hooks';

type Props = {
  filters: CF;
  showDate?: boolean;
  dateType?: 'day' | 'month' | 'range';
  showGranularity?: boolean;
  hideGranularityItems?: ChartGranularity[];
};

const ChartFilters = ({
  filters,
  showDate = false,
  dateType = 'day',
  showGranularity = false,
  hideGranularityItems = [],
}: Props) => {
  const { t } = useTranslation(['common']);
  const granularityList = [
    {
      type: ChartGranularity.Day,
      title: t('common:components.chart.filters.granularity_day', 'day'),
    },
    {
      type: ChartGranularity.Week,
      title: t('common:components.chart.filters.granularity_week', 'week'),
    },
    {
      type: ChartGranularity.Month,
      title: t('common:components.chart.filters.granularity_month', 'month'),
    },
  ];

  if (!showGranularity && !showDate) return null;

  const dateSettings = () => {
    switch (dateType) {
      case 'month':
        return {
          placeholder: t('common:components.chart.filters.month_placeholder', 'Month'),
          dateFormat: 'MM.yyyy',
          showMonthYearPicker: true,
          showFullMonthYearPicker: true,
          selected: parseDatepickerDate(filters.values.date),
        };
      case 'range':
        return {
          placeholder: t('common:components.chart.filters.range_placeholder', 'Range'),
          dateFormat: 'dd.MM.yyyy',
          selectsRange: true,
          startDate: Array.isArray(filters.values.date) ? filters.values.date[0] : null,
          endDate: Array.isArray(filters.values.date) ? filters.values.date[1] : null,
        };
      default:
        return {
          placeholder: t('common:components.chart.filters.day_placeholder', 'Day'),
          dateFormat: 'dd.MM.yyyy',
          selected: parseDatepickerDate(filters.values.date),
        };
    }
  };

  const renderGranularity = (type: ChartGranularity, title: string, first = false) => (
    <li
      key={type}
      className={clsx({
        'border-start border-gray-100 ms-4 ps-4': !first,
      })}
    >
      <button
        type="button"
        onClick={() => filters.change('granularity', type)}
        className={clsx('fs-12 lh-f12 text-lowercase', {
          'text-gray-800': filters.values.granularity !== type,
          'text-primary fw-bold': filters.values.granularity === type,
        })}
      >
        {title}
      </button>
    </li>
  );

  return (
    <div className="row align-items-center justify-content-between justify-content-sm-end gy-10">
      {showGranularity && (
        <div className="col-auto">
          <ul className="list-none d-flex">
            {granularityList
              .filter((g) => !hideGranularityItems.includes(g.type))
              .map((g, index) => renderGranularity(g.type, g.title, index === 0))}
          </ul>
        </div>
      )}
      {showDate && (
        <div className="col-auto">
          <Datepicker
            name="date"
            onChange={(date) => filters.change('date', date)}
            {...dateSettings()}
          />
        </div>
      )}
    </div>
  );
};

export default ChartFilters;
