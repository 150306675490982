import React from 'react';

interface Props {
  pricingTableId: string;
  publishableKey: string;
  customerEmail: string;
}

const stripeEmbed = ({ pricingTableId, publishableKey, customerEmail }: Props) => {
  return {
    __html: `
      <stripe-pricing-table 
        pricing-table-id="${pricingTableId}"
        publishable-key="${publishableKey}"
        customer-email="${customerEmail}"
      >
      </stripe-pricing-table>`,
  };
};

const StripePricingTable: React.FC<Props> = ({ pricingTableId, publishableKey, customerEmail }) => (
  <div dangerouslySetInnerHTML={stripeEmbed({ pricingTableId, publishableKey, customerEmail })} />
);

export default StripePricingTable;
