import { useMemo } from 'react';
import {
  Liveop,
  MarketingStrategyDetailsModel,
  Product,
} from '@app/modules/game-checker/models/MarketingStrategyDetailsModel';
import {
  TacticAndProduct,
  isTactic,
} from '@app/modules/game-checker/pages/DashboardGame/DashboardGame';

export const getDescription = (item: TacticAndProduct): string => {
  return isTactic(item) ? item.tactic.description ?? '' : item.product.description ?? '';
};

export const sortAlphabetical = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const useSortedTacticsAndProducts = (marketingStrategyDetails: MarketingStrategyDetailsModel) => {
  const sortedArrayOfElements: (Liveop | Product)[] = useMemo(() => {
    if (!marketingStrategyDetails) {
      return [];
    }

    const elements = [
      ...marketingStrategyDetails.stages[0].elements,
      ...marketingStrategyDetails.liveops,
    ];

    return elements.sort((a: TacticAndProduct, b: TacticAndProduct) =>
      sortAlphabetical(getDescription(a), getDescription(b))
    );
  }, [marketingStrategyDetails]);

  return { sortedArrayOfElements };
};

export default useSortedTacticsAndProducts;
