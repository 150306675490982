import { FieldError } from 'react-hook-form';

export const translateFieldError = (error: FieldError | FieldError[], t): string => {
  if (Array.isArray(error)) {
    return translateFieldArrayError(error, t);
  } else {
    if (error.type === 'api') return error.message;

    return t(error.message);
  }
};

export const translateFieldArrayError = (errors: FieldError[], t): string => {
  const error = errors.filter(Boolean)[0];

  return error ? translateFieldError(error, t) : '';
};
