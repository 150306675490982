import React from 'react';
import ReactDOM from 'react-dom';

// i18n
import './i18n';

// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as _redux from '@app/setup';
import store, { persistor } from '@app/setup/redux/Store';

// Axios
import axios from 'axios';

// Apps
import { App } from './App';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import '@shared/metronic/assets/sass/style.scss';
import '@shared/metronic/assets/sass/style.react.scss';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = window.__RUNTIME_CONFIG__;
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <Provider store={store}>
    {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <App basename={PUBLIC_URL} />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
