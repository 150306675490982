import React, { useEffect, useRef } from 'react';
import { Carousel } from 'bootstrap';
import { assetPath } from '@shared/utils/asset';
import style from './Wrapper.module.scss';
import * as contentService from '@app/services/content';
import { assetApiPath } from '@shared/utils/asset';
import { useContent } from '@shared/hooks';

const Wrapper = ({ children }) => {
  const { content } = useContent(() => contentService.fetchContent('gamedev_auth_slider'));

  const carouselRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const element = carouselRef.current;
    if (!content || !element) {
      return;
    }

    const carousel = new Carousel(element);

    return () => {
      carousel.dispose();
    };
  }, [content]);

  const renderCarouselItemContent = (contentBlock) => {
    switch (contentBlock.type) {
      case 'image_slider_image':
        return (
          <div key={contentBlock.id} className={style.slideImage}>
            <img src={assetApiPath(contentBlock.file?.path)} alt="" />
          </div>
        );
      case 'image_slider_header':
        return (
          <div key={contentBlock.id} className="text-center">
            <p className={style.slideTitle}>{contentBlock.value}</p>
          </div>
        );
      case 'image_slider_text':
        return (
          <div key={contentBlock.id} className="text-center">
            <p
              className={style.slideDescription}
              dangerouslySetInnerHTML={{
                __html: contentBlock.value,
              }}
            />
          </div>
        );
    }
  };

  const renderCarouselItem = (contentBlock, index) => {
    if (contentBlock.type === 'image_slider_parent_item') {
      return (
        <div key={contentBlock.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
          <div className={style.slide}>
            {contentBlock?.contentBlocks.map((contentBlock) =>
              renderCarouselItemContent(contentBlock)
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ backgroundImage: `url(${assetPath('/asort/bg.jpg')}` }} className={style.bg}>
      <div className="wrapper-max">
        <div className="row g-0">
          <div className="col-xl-6 order-xl-1">
            <div className={style.content}>
              <div className="d-flex flex-column align-items-center w-full">
                <a href="/" className="mb-24 mb-xxxl-32 d-inline-block">
                  <img alt="Logo" src={assetPath('/logos/logo.png')} className="mh-40" />
                </a>
                {children}
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div
              className={style.hero}
              style={{ backgroundImage: `url(${assetPath('/asort/bg.jpg')}` }}
            >
              {content ? (
                <div
                  id="kt_carousel_1_carousel"
                  className="carousel carousel-custom slide"
                  data-bs-ride="carousel"
                  data-bs-interval="6000"
                  ref={carouselRef}
                >
                  <div className="carousel-inner">
                    {content?.contentBlocks.map((contentBlock, index) =>
                      renderCarouselItem(contentBlock, index)
                    )}
                  </div>
                  <ol className="carousel-indicators carousel-indicators-dots mt-4">
                    {content?.contentBlocks.map((contentBlock, index) => (
                      <li
                        key={contentBlock.id}
                        data-bs-target="#kt_carousel_1_carousel"
                        data-bs-slide-to={index}
                        className={index === 0 ? 'active' : ''}
                      />
                    ))}
                  </ol>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
