import { Action } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, takeLatest } from 'redux-saga/effects';
import { UserModel } from '../models/UserModel';
import { getUserByToken, getUserCurrentPlan } from '../redux/AuthCRUD';
import { refreshUserStorageLanguage } from '@shared/utils/user';
import { UserPlanModel } from '../models/UserPlanModel';
import { differenceInDays } from 'date-fns';

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetUserAvatar: '[Set User Avatar] Action',
  SetModuleExceeded: '[Set Module Exceeded] Action',
  CurrentPlanRequested: '[Request Current Plan] Action',
  SetUserCurrentPlan: '[Set User Current Plan] Action',
};

const initialAuthState: IAuthState = {
  user: undefined,
  plan: null,
  accessToken: undefined,
  isModuleExceeded: false,
};

export interface IAuthState {
  user?: UserModel;
  plan?: UserPlanModel;
  accessToken?: string;
  isModuleExceeded?: boolean;
}

export const reducer = persistReducer(
  { storage, key: 'app', whitelist: ['user', 'accessToken', 'plan', 'isModuleExceeded'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined };
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined };
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetUserAvatar: {
        const { avatar } = action.payload?.user;
        return {
          ...state,
          user: {
            ...state.user,
            avatar: avatar || null,
          },
        };
      }

      case actionTypes.SetModuleExceeded: {
        return {
          ...state,
          isModuleExceeded: action.payload.isModuleExceeded,
        };
      }

      case actionTypes.CurrentPlanRequested: {
        return { ...state, plan: null };
      }

      case actionTypes.SetUserCurrentPlan: {
        const plan = action.payload?.plan;
        if (plan) {
          const expiresAt = new Date(plan.expiresAt);
          const leftDays = differenceInDays(expiresAt, new Date()) + 1;
          plan.planDaysLeft = leftDays;
        }
        return { ...state, plan };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken: string) => ({ type: actionTypes.Login, payload: { accessToken } }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
  setUserAvatar: (avatar: string | null) => ({
    type: actionTypes.SetUserAvatar,
    payload: { user: { avatar } },
  }),
  setIsModuleExceeded: (isModuleExceeded: boolean) => ({
    type: actionTypes.SetModuleExceeded,
    payload: { isModuleExceeded },
  }),
  requestCurrentPlan: () => ({
    type: actionTypes.CurrentPlanRequested,
  }),
  setUserCurrentPlan: (plan: UserPlanModel) => ({
    type: actionTypes.SetUserCurrentPlan,
    payload: { plan },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const user = yield getUserByToken();

    refreshUserStorageLanguage(user.language);

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.CurrentPlanRequested, function* currentPlan() {
    const plan = yield call(getUserCurrentPlan);
    yield put(actions.setUserCurrentPlan(plan));
  });
}
