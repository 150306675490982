import React from 'react';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import clsx from 'clsx';

type Props = {
  id: string;
  title: string;
  children: React.ReactNode;
  open?: boolean;
};

const defaultProps = {
  open: true,
};

const SectionAccordion = ({ id, title, children, open }: Props) => {
  return (
    <div className="section p-md-40 mb-20 mb-xxxl-40">
      <div className="accordion accordion-icon-toggle" id={id}>
        <div className="mb-5">
          <div
            className={clsx(
              'accordion-header pb-12 mb-12 d-flex justify-content-between border-bottom',
              {
                collapsed: !open,
              }
            )}
            data-bs-toggle="collapse"
            data-bs-target={`#${id}_1`}
          >
            <h3 className="fs-18 lh-f18">{title}</h3>
            <span className="accordion-icon me-16">
              <SVG src={assetPath('/icons/arrow-accordion.svg')} />
            </span>
          </div>
          <div
            id={`${id}_1`}
            className={clsx('fs-6 collapse mt-32', {
              show: open,
            })}
            data-bs-parent={id}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionAccordion.defaultProps = defaultProps;

export default SectionAccordion;
