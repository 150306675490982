/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuComponent } from '@shared/metronic/assets/ts/components';
import { KTSVG } from '@shared/metronic/helpers';
import { useLayout } from '@shared/metronic/layout/core';
import { assetPath } from '@shared/utils/asset';

export function HeaderWrapper() {
  const { pathname } = useLocation();
  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [pathname]);

  return (
    <div
      id="kt_header"
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className="d-flex align-items-center d-lg-none ms-n3 me-2" title="Show aside menu">
            <div
              className="btn btn-icon w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            >
              <KTSVG path="/menu/menu.svg" className="svg-icon-2x mt-1" />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}

        {aside.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 justify-content-center">
            <Link to="/" className="d-lg-none ms-n10">
              <img alt="Logo" src={assetPath('/logos/logo.png')} className="h-24" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
