import React from 'react';
import clsx from 'clsx';
import style from './ButtonWithPopover.module.scss';

type Props = {
  id: number | string;
  buttonContent: string;
  popoverContent: React.ReactNode;
};

const ButtonWithPopover = ({ buttonContent, popoverContent, id }: Props) => {
  const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  return (
    <>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <button
          type="button"
          className={clsx(
            `btn btn-icon btn-primary btn-sm btn-circle ${style.wrapper}`,
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="top"
          data-kt-menu-flip="top"
        >
          {buttonContent}
        </button>
        <div
          className={`${style.menuWrapper} menu menu-sub menu-sub-dropdown mb-10`}
          data-kt-menu="true"
          id={`kt_menu-${id}`}
        >
          <div className="px-20 py-20">{popoverContent}</div>
        </div>
      </div>
    </>
  );
};

export default ButtonWithPopover;
