import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'common:fields.validation.required',
  },
  string: {
    email: 'common:fields.validation.email',
    url: 'common:fields.validation.url',
    max: 'common:fields.validation.max',
  },
});

export default yup;
