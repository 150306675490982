import { Action, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/setup';
import { put, select, takeLatest } from 'redux-saga/effects';
import { AnswerModel } from '@app/modules/game-checker/models/AnswerModel';
import update from 'immutability-helper';
import { QuestionType } from '@app/modules/game-checker/enums/QuestionType';

export interface ActionWithPayload<T> extends Action {
  payload?: T;
  key?: string;
}

export interface GCState {
  id?: number | null;
  answers: AnswerModel[];
}

const initialState: GCState = {
  id: null,
  answers: [],
};

export const actionTypes = {
  Reset: '[GC][RESET]',
  ChangeId: '[GC][CHANGE_ID]',
  SetId: '[GC][SET_ID]',
  SetAnswers: '[GC][SET_ANSWERS]',
  AddAnswer: '[GC][ADD_ANSWER]',
  ChangeAnswerValue: '[GC][CHANGE_ANSWER_VALUE]',
  ReplaceAnswer: '[GC][REPLACE_ANSWER]',
};

export const reducer = (state: GCState = initialState, action: ActionWithPayload<GCState>) => {
  switch (action.type) {
    case actionTypes.Reset: {
      return initialState;
    }
    case actionTypes.SetId: {
      return {
        ...state,
        id: action.payload.id,
      };
    }
    case actionTypes.SetAnswers: {
      return {
        ...state,
        answers: action.payload.answers,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  reset: () => ({ type: actionTypes.Reset }),
  setId: (id: number) => ({ type: actionTypes.SetId, payload: { id } }),
  changeId: (id: number) => ({
    type: actionTypes.ChangeId,
    payload: { id },
  }),
  setAnswers: (answers: AnswerModel[]) => ({ type: actionTypes.SetAnswers, payload: { answers } }),
  addAnswer: (answer: AnswerModel) => ({ type: actionTypes.AddAnswer, payload: { answer } }),
  changeAnswerValue: (index: number, value: any) => ({
    type: actionTypes.ChangeAnswerValue,
    payload: { index, value },
  }),
  replaceAnswer: (index: number, answer: AnswerModel) => ({
    type: actionTypes.ReplaceAnswer,
    payload: { index, answer },
  }),
};

const selfSelector = (state: RootState) => state.gc;
export const selectorGC = createSelector(selfSelector, (gc) => ({ ...gc }));
export const selectorGcId = createSelector(selfSelector, (gc) => gc.id);
export const selectorAnswers = createSelector(selfSelector, (gc) => gc.answers);

export function* saga() {
  yield takeLatest(
    actionTypes.ChangeId,
    function* changeGameSaga({ payload: { id } }: ActionWithPayload<{ id: number }>) {
      yield put(actions.reset());
      yield put(actions.setId(id));
    }
  );
  yield takeLatest(
    actionTypes.AddAnswer,
    function* addAnswerSage({ payload: { answer } }: ActionWithPayload<{ answer: AnswerModel }>) {
      const answers = yield select(selectorAnswers);

      yield put(actions.setAnswers([...answers, answer]));
    }
  );
  yield takeLatest(
    actionTypes.ChangeAnswerValue,
    function* changeAnswerValueSage({
      payload: { index, value },
    }: ActionWithPayload<{ index: number; value: any }>) {
      const answers = yield select(selectorAnswers);
      const answer = answers[index];

      switch (answer.question.type) {
        case QuestionType.YesNo:
          answer.userSelectedAnswerOption = value;
          break;
        case QuestionType.MultipleTextInputs:
          answer.userCustomAnswers = value;
          break;
        case QuestionType.Form:
          answer.userCustomAnswers = value;
          break;
        case QuestionType.SelectMultiple:
          answer.userSelectedAnswerOptions = value;
          break;
        case QuestionType.SelectCountries:
          answer.userCustomAnswers = value;
          break;
        case QuestionType.IntRange:
          answer.userCustomAnswer = value;
          break;
        case QuestionType.SelectSingle:
          answer.userSelectedAnswerOption = value;
          break;
        case QuestionType.SingleTextInput:
          answer.userCustomAnswer = value;
          break;
      }

      answer.changed = true;

      yield put(
        actions.setAnswers([...update(answers, { [index]: { $set: answer } }).slice(0, index + 1)])
      );
    }
  );
  yield takeLatest(
    actionTypes.ReplaceAnswer,
    function* replaceAnswerSage({
      payload: { index, answer },
    }: ActionWithPayload<{ index: number; answer: AnswerModel }>) {
      const answers = yield select(selectorAnswers);

      yield put(actions.setAnswers([...update(answers, { [index]: { $set: answer } })]));
    }
  );
}
