import React from 'react';
import clsx from 'clsx';

type Props = {
  title: string;
  children: React.ReactNode;
  withoutMargin?: boolean;
};

const defaultProps = {
  withoutMargin: false,
};

const Group = ({ title, children, withoutMargin }: Props) => {
  return (
    <div
      className={clsx({
        'mb-40': !withoutMargin,
      })}
    >
      <div className="pb-12 mb-12 border-bottom">
        <h3 className="fs-18 lh-f18">{title}</h3>
      </div>
      {children}
    </div>
  );
};

Group.defaultProps = defaultProps;

export default Group;
