import { useTranslation } from 'react-i18next';
import { Modal } from '@shared/partials';

interface StrategyChangeModalInterface {
  type: 'soft' | 'hard';
  show: boolean;
  clearModal: () => void;
  handleAccept: () => void;
}

const StrategyChangeModal = ({
  type,
  show,
  clearModal,
  handleAccept,
}: StrategyChangeModalInterface) => {
  const { t } = useTranslation(['common', 'gc']);

  return (
    <Modal show={show} handleClose={clearModal}>
      <div className="d-flex flex-column align-items-center">
        <h4 className="fs-14 lh-f25 mb-32 ">
          {t('gc:dashboard.modal_changes_header', 'Are you sure you want to make changes?')}
        </h4>
        <span className={`fs-12 lh-f25 + ${type === 'soft' ? 'mb-64' : 'mb-8'}`}>
          {type === 'soft'
            ? t(
                'gc:dashboard.modal_changes_soft_paragraph',
                'If you do this, your current strategy may change.'
              )
            : t(
                'gc:dashboard.modal_changes_hard_paragraph',
                'If you do this, you will cancel the current strategy and create a new one. '
              )}
        </span>
        {type === 'hard' ? (
          <span className="fs-12 lh-f25 mb-64">
            {t(
              'gc:dashboard.modal_changes_additional_paragraph',
              'Your canceled strategies you find in My Strategies.'
            )}
          </span>
        ) : null}
      </div>
      <div className="d-flex justify-content-between gap-40">
        <button type="button" onClick={handleAccept} className="btn btn-primary btn-sm flex-fill">
          {t('gc:dashboard.modal_yes', 'Yes')}
        </button>
        <button type="button" onClick={clearModal} className="btn btn-secondary btn-sm flex-fill">
          {t('gc:dashboard.modal_no', 'No')}
        </button>
      </div>
    </Modal>
  );
};

export default StrategyChangeModal;
