import clsx from 'clsx';
import React from 'react';
import { Modal as BSModal } from 'react-bootstrap-v5';

type Props = {
  children: React.ReactNode;
  show: boolean;
  handleClose: () => void;
  customBody?: boolean;
  styles?: string;
  dialogClassName?: string;
};

const defaultProps = {
  customBody: false,
};

const Modal = ({ children, show, handleClose, customBody, styles, dialogClassName }: Props) => {
  return (
    <BSModal
      className={clsx('modal fade', styles)}
      dialogClassName={dialogClassName}
      tabIndex={-1}
      show={show}
      aria-hidden="true"
      centered
      onHide={handleClose}
    >
      {customBody ? children : <div className="modal-body px-16 py-32 p-md-64">{children}</div>}
    </BSModal>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
