import React from 'react';
import { ChartLegend } from '@shared/components';
import { getColor } from '@shared/components/Chart/Chart';
import clsx from 'clsx';

export type ChartLayoutSummary = {
  title: string;
  value: string | number;
};

type Props = {
  children: React.ReactNode;
  legend?: React.ReactNode;
  filters?: React.ReactNode;
  summary?: ChartLayoutSummary;
  defaultLegend?: {
    title: string;
    series: any[];
  };
};

const ChartLayout = ({ children, legend, filters, summary, defaultLegend }: Props) => {
  return (
    <div className="section overflow-hidden h-full d-flex flex-column justify-content-between">
      {(legend || defaultLegend || filters) && (
        <div className="row justify-content-between align-items-start gx-8 gy-20">
          {legend && <div className="col-sm-auto">{legend}</div>}
          {defaultLegend && (
            <div className="col-sm-6">
              <ChartLegend
                title={defaultLegend.title}
                items={defaultLegend.series.map((s, index) => ({
                  color: getColor(index),
                  title: s.name,
                }))}
              />
            </div>
          )}
          {filters || summary ? (
            <div className="col-sm-6">
              {filters && filters}
              {summary && (
                <div
                  className={clsx('d-flex align-items-baseline justify-content-sm-end', {
                    'mt-10': filters,
                  })}
                >
                  <p className="fs-14 fw-bold me-10">{summary.title}</p>
                  <p className="fs-25">{summary.value}</p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
      {children}
    </div>
  );
};

export default ChartLayout;
