import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import type { RootState } from '@app/setup';

type Item = {
  id: string;
};

const adapter = createEntityAdapter<Item>();

const slice = createSlice({
  name: 'loading',
  initialState: adapter.getInitialState(),
  reducers: {
    addOne: adapter.addOne,
    removeOne: adapter.removeOne,
  },
});

export const { addOne, removeOne } = slice.actions;

export const loadingSelectors = adapter.getSelectors<RootState>((state) => state.loading);

export default slice.reducer;
