import styles from './Product.module.scss';

import React, { useMemo, useRef, useState } from 'react';
import { ProductModel, StageModel } from '../../models/ProductModel';
import * as services from '@app/modules/products/services';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import { cartActionTypes, selectorCartItems } from '@app/modules/cart/store';
import {
  addOne as addLoading,
  removeOne as removeLoading,
} from '@app/shared/features/loading/loadingSlice';
import { addOne as addNotification } from '@shared/features/notifications/notificationsSlice';
import apiErrorParser from '@shared/utils/apiErrorParser';
import { AlertType } from '@shared/enums/AlertType';
import { useTranslation } from 'react-i18next';
import { assetApiPath, assetPath } from '@shared/utils/asset';
import { useHover } from '@shared/hooks';
import { Modal } from '@shared/partials';
import AddToCartModal from '../AddToCartModal/AddToCartModal';
import { useAppSelector } from '@app/setup';
import clsx from 'clsx';
import { ifOneAfterAnother } from '../../shared/utils/ifOneAfterAnother';

type Props = {
  promoted?: boolean;
  product: ProductModel;
};

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';

const Product: React.FC<Props> = ({ promoted, product }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation(['gc', 'products']);
  const history = useHistory();
  const dispatch = useDispatch();
  const cartItems = useAppSelector(selectorCartItems);
  const svgClickHandled = useRef(false);
  const [ref, isHovered] = useHover<HTMLDivElement>();

  //TODO: move this function to reusable utill
  const formatter = useMemo(
    () => (currency: string) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
      }),
    [product]
  );

  const formattedStages = product.stages.map((el: StageModel) => el.stageNumber).sort();

  const goToProductDetails = () => {
    history.push(`/marketplace/list/${product.id}`);
  };

  const addToCart = async (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.stopPropagation();
    const foundElementFromCart = cartItems.find((el) => el.product.id === product.id);

    try {
      dispatch(addLoading({ id: ADD_PRODUCT_TO_CART }));
      const response = await services.addToCart([
        {
          product: product.id,
          qty: foundElementFromCart ? foundElementFromCart.qty + 1 : 1,
        },
      ]);

      if (response) {
        setShow(true);
        dispatch({ type: cartActionTypes.Fetch });
      }
    } catch (error) {
      const { message } = apiErrorParser(error, dispatch);

      dispatch(
        addNotification({
          id: 'product.addToCart',
          type: AlertType.Danger,
          message,
        })
      );
      dispatch(removeLoading(ADD_PRODUCT_TO_CART));
    }
  };

  const handleClose = () => {
    setShow(!show);
  };

  const handleAddToCart = (event: React.MouseEvent<SVGAElement, MouseEvent>) => {
    addToCart(event);
    svgClickHandled.current = true;
  };

  const handleGoToProductDetails = () => {
    if (!svgClickHandled.current) {
      goToProductDetails();
    }
    svgClickHandled.current = false;
  };

  return (
    <div
      ref={ref}
      className={clsx(styles.root, { [styles.rootPromoted]: promoted })}
      onClick={handleGoToProductDetails}
      role="button"
    >
      <div className={clsx(styles.imageWrapper, { [styles.imageWrapperPromoted]: promoted })}>
        <img
          src={product?.image?.path ? assetApiPath(product?.image?.path) : ''}
          className={clsx(styles.image, { [styles.imagePromoted]: promoted })}
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.headerWrapper}>
          <h1 className={styles.name}>{product?.name}</h1>
          <div
            className={clsx(styles.subjectWrapper, { [styles.subjectWrapperPromoted]: promoted })}
          >
            {product && product.categories.length > 0 ? (
              <span className={styles.category}>
                {t('products.category', 'Category')}:&nbsp;
                <span>{product.categories.map(({ name }) => name).join(', ')}</span>
              </span>
            ) : null}
            {product.stages.length > 0 &&
            !product.stages.find((el: StageModel) => el.stageNumber === null) ? (
              <span className={styles.stage}>
                {t('gc:dashboard.stage', 'Stage ')}:&nbsp;
                <span>
                  {formattedStages.length >= 2 && ifOneAfterAnother(formattedStages)
                    ? t('products.stage.numbers', 'from {{first}} to {{second}}', {
                        first: formattedStages[0],
                        second: formattedStages[formattedStages.length - 1],
                      })
                    : formattedStages.join(', ')}
                </span>
              </span>
            ) : null}
          </div>
        </div>
        <div className={styles.descriptionWrapper}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: product?.description }}
          />
        </div>
        <div className={styles.priceWrapper}>
          <span className={styles.price}>
            {formatter(product?.currency).format(Number(product?.formattedPrice))}
          </span>
          {isHovered ? (
            <SVG
              src={assetPath('/icons/addCart.svg')}
              className={styles.iconCart}
              onClick={handleAddToCart}
            />
          ) : null}
        </div>
      </div>
      <Modal show={show} handleClose={handleClose} customBody>
        <AddToCartModal
          product={product}
          quantity={1}
          formatter={formatter}
          handleClose={handleClose}
          noSummary
        />
      </Modal>
    </div>
  );
};
export default Product;
