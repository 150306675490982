import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import { RootState } from '@app/setup';
import { useEffect, useState } from 'react';
import { actions as authActions } from '@app/modules/auth';
import { useTranslation } from 'react-i18next';
import StripePricingTable from '../StripePricingTable/StripePricingTable';
import { getStripePricingTables } from '@app/services/stripePricingTables';
import { PlanLevels } from '@app/modules/auth/models/UserPlanModel';
import { PricingTableModel } from '@app/models/PricingTableModel';

const LimitModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const currentPlan = useSelector(({ auth }: RootState) => auth.plan);
  const isModuleExceeded = useSelector(({ auth }: RootState) => auth.isModuleExceeded);
  const isAuthorized = useSelector(({ auth }: RootState) => auth.user, shallowEqual);
  const [pricingTable, setPricingTable] = useState<PricingTableModel>(null);
  const planExpired = currentPlan?.planDaysLeft <= 0;

  const getPricingTables = async () => {
    const results = await getStripePricingTables();

    if (planExpired) {
      const fullTable = results.find((table) => table.level === '1');
      setPricingTable(fullTable);
      return;
    }

    if (currentPlan.level === PlanLevels.BASIC) {
      const secondLevelTable = results.find((table) => table.level === '2');
      setPricingTable(secondLevelTable);
      return;
    }

    if (currentPlan.level === PlanLevels.PRO) {
      const thirdLevelTable = results.find((table) => table.level === '3');
      setPricingTable(thirdLevelTable);
      return;
    }
  };

  const handleClose = () => {
    dispatch(authActions.setIsModuleExceeded(false));
  };

  useEffect(() => {
    if (isAuthorized && currentPlan) {
      getPricingTables();
    }
  }, [isAuthorized, currentPlan]);

  return (
    <Modal
      show={isModuleExceeded}
      handleClose={handleClose}
      dialogClassName="modal-xl modal-dialog-scrollable position-relative"
    >
      <h4 className="fs-23 lh-f25 text-center mb-32" style={{ margin: '0 auto', width: '85%' }}>
        {t(
          'alerts.limit_exceeded',
          'You have reached the limit of using this functionality within your subscription. Upgrade to a higher plan.'
        )}
      </h4>
      <StripePricingTable
        pricingTableId={pricingTable?.pricingTableId}
        publishableKey={pricingTable?.publishableKey}
        customerEmail={isAuthorized?.email}
      />
    </Modal>
  );
};

export default LimitModal;
