/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { MasterLayout } from '@app/shared/metronic/layout/MasterLayout';
import { PrivateRoutes } from '@app/routing/PrivateRoutes';
import { Logout, AuthModule } from '@app/modules/auth';
import ErrorsModule from '@app/shared/modules/errors/ErrorsModule';
import { RootState } from '@app/setup';

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual);

  const PublicGameCheckerModule = lazy(
    () => import('@app/modules/public-game-checker/PublicGameCheckerModule')
  );

  return (
    <Switch>
      <Route path="/p/game-checker" component={PublicGameCheckerModule} />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthModule />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsModule} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  );
};

export { Routes };
