export const translateErrorMessage = (error, t): string => {
  let msg = error?.message;

  if (msg?.startsWith('trans.')) {
    msg = t(`api_error:${msg}`);
  }

  if (!msg && error?.messageCode) {
    msg = t(`api_error:${error.messageCode}`);
  }

  return msg || '';
};
