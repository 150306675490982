import React from 'react';

type Props = {
  width?: number | string;
};

const defaultProps = {
  width: 0,
};

const ProgressBar = ({ width }: Props) => {
  return (
    <div className="progress">
      <div className="progress-bar" role="progressbar" style={{ width: `${width}%` }} />
    </div>
  );
};

ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
