import React from 'react';
import clsx from 'clsx';

type Props = {
  page?: boolean;
  small?: boolean;
  large?: boolean;
  white?: boolean;
};

const defaultProps = {
  page: false,
  small: false,
  large: false,
  white: false,
};

const Loader = ({ page, small, large, white }: Props) => {
  const spinner = () => (
    <span
      className={clsx(`spinner-border ${white ? 'text-white' : 'text-primary'} `, {
        'spinner-border-sm': small,
        'spinner-border-lg': large,
      })}
      role="status"
    />
  );

  return page ? <div className="text-center my-64 my-lg-96">{spinner()}</div> : <>{spinner()}</>;
};

Loader.defaultProps = defaultProps;

export default Loader;
