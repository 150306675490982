import React from 'react';
import clsx from 'clsx';
import type { FieldValues, UseFormRegister } from 'react-hook-form';

type Props = {
  type?: string;
  name: string;
  register?: UseFormRegister<FieldValues>;
  error?: string;
  placeholder?: string;
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  large?: boolean;
  xlarge?: boolean;
  small?: boolean;
  secondary?: boolean;
  [x: string]: any;
};

const defaultProps = {
  type: 'text',
  register: () => {},
  error: '',
  placeholder: '',
  label: '',
  description: '',
  large: false,
  xlarge: false,
  small: false,
  secondary: false,
};

const Input = ({
  type,
  name,
  register,
  error,
  placeholder,
  label,
  description,
  large,
  xlarge,
  small,
  secondary,
  className,
  ...rest
}: Props) => (
  <div>
    {label && (
      <label htmlFor={`input-id-${name}`} className="fs-12 lh-f12 fw-bold mb-10">
        {label}
      </label>
    )}
    <input
      id={`input-id-${name}`}
      type={type}
      name={name}
      placeholder={placeholder}
      className={clsx('form-control', {
        'is-invalid': !!error,
        'form-control-lg': large,
        'form-control-sm': small,
        'form-control-solid': !secondary,
        'py-18': xlarge,
      })}
      {...register(name)}
      {...rest}
    />
    {description && <div className="fs-12 lh-f12 text-gray-700 mt-4">{description}</div>}
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
);

Input.defaultProps = defaultProps;

export default Input;
