import React, { useState } from 'react';
import style from '@app/modules/game-checker/pages/Dashboard/ScoreThumb/ScoreThumb.module.scss';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import { GameScoreShortModel } from '@app/modules/game-checker/models/GameScoreShortModel';
import { assetApiPath } from '@shared/utils/asset';
import { formatDateToView } from '@shared/utils/date';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as services from '@app/modules/game-checker/services';
import { Loader, Badge, Avatar } from '@shared/components';
import { addOne as addNotification } from '@app/shared/features/notifications/notificationsSlice';
import { AlertType } from '@shared/enums/AlertType';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import slug from 'slug';

type Props = GameScoreShortModel & {
  short?: boolean;
  remove: () => void;
};

const defaultProps = {};

const ScoreThumb = ({
  id,
  game,
  warningsCount,
  suggestionsCount,
  maxScore,
  totalScore,
  createdAt,
  finished,
  remove,
}: Props) => {
  const { t } = useTranslation(['gc', 'common']);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const hints = [
    warningsCount
      ? {
          name: t('gc:dashboard.warnings_detected', '{{count}} warnings detected', {
            count: warningsCount,
          }),
          danger: true,
        }
      : null,
    suggestionsCount
      ? {
          name: t('gc:dashboard.suggestions_detected', '{{count}} new suggestions to improve', {
            count: suggestionsCount,
          }),
          danger: false,
        }
      : null,
  ].filter(Boolean);

  const handleDownloadPdf = async () => {
    try {
      setLoading(true);

      const response = await services.downloadPdf(id);

      const file = new Blob([response], {
        type: 'application/pdf',
      });

      saveAs(file, `${slug(game.name)}.pdf`);
    } catch (error) {
      dispatch(
        addNotification({
          id: 'gc.dashboard.downloadPdf',
          type: AlertType.Danger,
          message: t('common:alerts.error'),
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const renderAction = () => {
    if (finished) {
      return (
        <div className="flex-shrink-0">
          <button
            type="button"
            className={clsx(style.download, 'ms-12 ms-md-48 svg-icon svg-icon-primary')}
            onClick={handleDownloadPdf}
            disabled={loading}
          >
            {loading ? <Loader /> : <SVG src={assetPath('/icons/download.svg')} />}
          </button>
        </div>
      );
    }

    return (
      <div className="flex-shrink-0">
        <button
          type="button"
          className={clsx(style.remove, 'ms-12 ms-md-48 svg-icon svg-icon-danger')}
          onClick={remove}
          disabled={loading}
        >
          {loading ? <Loader /> : <SVG src={assetPath('/icons/trash.svg')} />}
        </button>
      </div>
    );
  };

  return (
    <div className={clsx(style.wrapper, 'py-20')}>
      <div className="row align-items-center">
        <div className="col-xxl-4">
          <Link
            to={finished ? `/game-checker/dashboard/${id}` : `/game-checker/new/${game.id}/${id}`}
            className="d-flex align-items-center"
          >
            <Avatar
              avatar={assetApiPath(game.thumbnailCover)}
              type="game"
              name={game.name}
              className={clsx(style.avatar, 'me-12 me-md-20 flex-shrink-0')}
            />
            <div className="text-body">
              <p className="fs-16 lh-f16 fw-bold">{game.name}</p>
              {!finished && <Badge>{t('gc:dashboard.draft_badge', 'Draft')}</Badge>}
            </div>
          </Link>
        </div>
        <div className="col-xxl-8 mt-20 mt-xxl-0">
          <div className="row align-items-center">
            <div className="col-12 col-md-3">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <p>{formatDateToView(createdAt)}</p>
                </div>
                <div className="col-auto">
                  <p className="fw-bold">
                    {totalScore} / {maxScore}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 offset-md-1 mt-20 mt-md-0">
              <div className="d-flex align-items-center justify-content-between">
                <div className="flex-grow-1">
                  {hints.length ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hints
                          .map((h) =>
                            h.danger ? `<span class="text-danger">${h.name}</span>` : h.name
                          )
                          .join(', '),
                      }}
                    />
                  ) : null}
                </div>
                {renderAction()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreThumb.defaultProps = defaultProps;

export default ScoreThumb;
