import { AsideMenuItemWithSub } from '@shared/metronic/layout/components/aside/AsideMenuItemWithSub';
import { AsideMenuItem } from '@shared/metronic/layout/components/aside/AsideMenuItem';
import { useTranslation } from 'react-i18next';
import { RootState } from '@app/setup';
import { useSelector } from 'react-redux';

export function Menu() {
  const { t } = useTranslation(['common']);
  const currentPlan = useSelector(({ auth }: RootState) => auth.plan);

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/menu/dashboard.svg"
        title={t('common:aside_menu.dashboard', 'Dashboard')}
        fontIcon=""
      />
      <AsideMenuItemWithSub
        to="/games"
        title={t('common:aside_menu.games', 'Games')}
        icon="/menu/games.svg"
        fontIcon=""
      >
        <AsideMenuItem
          to="/games/list"
          title={t('common:aside_menu.game_list', 'List')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/games/new"
          title={t('common:aside_menu.game_new', 'New')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to="/game-checker"
        icon="/menu/game-checker.svg"
        title={t('common:aside_menu.growth_generator', 'Growth generator')}
        fontIcon=""
      />
      <AsideMenuItemWithSub
        to="/soft-launch"
        title={t('common:aside_menu.soft_launch', 'Soft Launch')}
        icon="/menu/soft-launch.svg"
        fontIcon=""
      >
        <AsideMenuItem
          to="/soft-launch/list"
          title={t('common:aside_menu.soft_launch_list', 'List')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/soft-launch/new"
          title={t('common:aside_menu.soft_launch_new', 'New')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/marketplace"
        icon="/menu/marketplace.svg"
        title={t('common:aside_menu.marketplace', 'Marketplace')}
        fontIcon=""
      >
        <AsideMenuItem
          to="/marketplace/list"
          title={t('common:aside_menu.marketplace_all', 'All products')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/marketplace/history"
          title={t('common:aside_menu.marketplace_history', 'Purchase history')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/reports"
        title={t('common:aside_menu.reports', 'Reports')}
        icon="/menu/reports.svg"
        fontIcon=""
      >
        <AsideMenuItem
          to="/reports/dashboard"
          title={t('common:aside_menu.reports_overview', 'Overview')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/reports/engagement"
          title={t('common:aside_menu.reports_engagement', 'Engagement')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/reports/monetization"
          title={t('common:aside_menu.reports_monetization', 'Monetization')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/marketing-strategy"
        title={t('common:aside_marketing_strategy', 'Marketing Strategy')}
        icon="/menu/marketing_strategy.svg"
      >
        <AsideMenuItem
          to="/marketing-strategy/my-strategies"
          title={t('common:aside_menu.marketing_strategy_my_strategies', 'My strategies')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/marketing-strategy/tactics/library"
          title={t('common:aside_menu.marketing_strategy_tactics_library', 'Tactics library')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/digitalfirst"
        title={t('common:aside_menu.digitalfirst', 'DigitalFirst.ai')}
        icon="/menu/digitalfirst.svg"
      >
        <AsideMenuItem
          to="/digitalfirst/dashboard"
          title={t('common:aside_menu.digitalfirst_dashboard', 'All Funnels')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/digitalfirst/tactics"
          title={t('common:aside_menu.digitalfirst_tactics', 'Tactics')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/digitalfirst/my-tactics"
          title={t('common:aside_menu.digitalfirst_my-tactics', 'My Tactics')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/finances"
        title={t('common:aside_menu.finances', 'Finances')}
        icon="/menu/finances.svg"
        fontIcon=""
      >
        <AsideMenuItem
          to="/finances/dashboard"
          title={t('common:aside_menu.finances_overview', 'Overview')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/finances/anti-fraud"
          title={t('common:aside_menu.finances_anti_fraud', 'Anti-fraud')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/finances/transactions-report"
          title={t('common:aside_menu.finances_transactions-report', 'Transactions report')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/finances/withdraw"
          title={t('common:aside_menu.finances_withdraw', 'Withdraw')}
          hasBullet={false}
        />
        <AsideMenuItem
          to="/finances/settings"
          title={t('common:aside_menu.finances_settings', 'Settings')}
          hasBullet={false}
        />
      </AsideMenuItemWithSub>
      {currentPlan?.permissionHelpCenter ? (
        <AsideMenuItem
          to={window.__RUNTIME_CONFIG__.REACT_APP_HELP_CENTER_URL}
          icon="/menu/help-center.svg"
          title={t('common:aside_menu.help_center', 'Help Center')}
          fontIcon=""
          externalBlank
        />
      ) : null}
    </>
  );
}
