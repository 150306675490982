import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as authCrud from '@app/modules/auth/redux/AuthCRUD';
import { ButtonAction, Input } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { translateFieldError } from '@shared/utils/forms';
import { translateErrorMessage } from '@shared/utils/api';
import yup from '@app/yup-i18n';
import { useHistory } from 'react-router';

type Inputs = {
  email: string;
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required().email(),
  })
  .required();

const Forgot = ({ setAlert }) => {
  const { t } = useTranslation(['common', 'auth']);
  const history = useHistory();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async ({ email }) => {
    try {
      await authCrud.requestPassword(email);

      setAlert({
        type: 'success',
        content: t('auth:forgot.success_alert', 'Success! The password was changed'),
      });

      history.push('/auth/login?alert');
    } catch ({
      response: {
        status,
        data: { error },
      },
    }) {
      const errorMsg = translateErrorMessage(error, t);

      if (status !== 404 && errorMsg) {
        setAlert({
          type: 'danger',
          content: errorMsg,
        });

        return;
      }

      setAlert({
        type: 'danger',
        content: t('common:alerts.error'),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="w-full">
      <div className="mb-20">
        <Input
          name="email"
          register={register}
          error={errors?.email && translateFieldError(errors.email, t)}
          placeholder={t('auth:forgot.email_placeholder', 'Email')}
          large
        />
      </div>
      <div className="row justify-content-center mt-32">
        <div className="col-sm-8">
          <ButtonAction type="submit" className="w-full" loading={isSubmitting} large>
            {t('auth:forgot.submit_button', 'Send request')}
          </ButtonAction>
        </div>
      </div>
    </form>
  );
};

export default Forgot;
