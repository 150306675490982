import React from 'react';
import style from './FooterLink.module.scss';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';

type Props = {
  to: string;
  title: string;
  iconPath: string;
  secondary?: boolean;
};

const defaultProps = {
  secondary: false,
};

const FooterLinkExternal = ({ to, title, iconPath, secondary }: Props) => {
  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={clsx('h-100 d-flex align-items-center d-lg-block', style.link, {
        [style.linkSecondary]: secondary,
      })}
    >
      <div className={style.icon}>
        <SVG src={assetPath(iconPath)} />
      </div>
      <p className={clsx('fw-boldest ms-12 ms-lg-0 fs-14 fs-lg-16 mt-lg-12', style.title)}>
        {title}
      </p>
    </a>
  );
};

FooterLinkExternal.defaultProps = defaultProps;

export default FooterLinkExternal;
