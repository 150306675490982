import React from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import style from './StrengthBar.module.scss';

const StrengthBar = ({ value }) => {
  return (
    <PasswordStrengthBar
      password={value}
      shortScoreWord=""
      scoreWords={[]}
      barColors={['#3E51E50D', '#06B98E', '#06B98E', '#06B98E', '#06B98E']}
      className={style.passwordStrength}
    />
  );
};

export default StrengthBar;
