import { CartModel } from '@app/models/CartModel';
import { Action, createSelector } from '@reduxjs/toolkit';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { clearCartItems, getCartItems, removeCartItem } from './services';
import { RootState } from '@app/setup';
import {
  addOne as addLoading,
  removeOne as removeLoading,
} from '@app/shared/features/loading/loadingSlice';
import { ADD_PRODUCT_TO_CART } from '../products/components/Product/Product';

export interface ActionWithPayload<T> extends Action {
  payload?: T & {
    id?: number;
  };
}

export interface CartState {
  cartItems?: CartModel[];
}

const initialState: CartState = {
  cartItems: [],
};

export const cartActionTypes = {
  Fetch: 'cart/fetchCartItems',
  Reset: 'cart/resetCartItems',
  Set: 'cart/setCartItems',
  RemoveItem: 'cart/removeCartItem',
};

export const FETCH_CART = 'FETCH_CART';

export const reducer = (state: CartState = initialState, action: ActionWithPayload<CartState>) => {
  switch (action.type) {
    case cartActionTypes.Reset: {
      return initialState;
    }
    case cartActionTypes.Set: {
      return {
        cartItems: action.payload?.cartItems,
      };
    }
    case cartActionTypes.RemoveItem: {
      const cartItems = state.cartItems || [];
      const itemId = action.payload?.id;
      return {
        cartItems: cartItems.filter((item) => item.product.id !== itemId),
      };
    }
    default:
      return state;
  }
};

export const setCartItems = (cartItems: CartModel[]) => ({
  type: cartActionTypes.Set,
  payload: { cartItems },
});

const selfSelector = (state: RootState) => state.cart;
export const selectorCartItems = createSelector(selfSelector, (cart) => cart.cartItems);

function* fetchCartItems() {
  try {
    yield put(addLoading({ id: FETCH_CART }));
    const cartItems = yield call(getCartItems);
    yield put(setCartItems(cartItems));
    yield put(removeLoading(ADD_PRODUCT_TO_CART));
    yield put(removeLoading(FETCH_CART));
  } catch (error) {}
}

function* resetCartItems() {
  try {
    yield call(clearCartItems);
    yield put(setCartItems([]));
  } catch (error) {}
}

function* removeCartItemSaga(action: ActionWithPayload<{ id: number }>) {
  try {
    const { id } = action.payload || {};
    if (!id) {
      return;
    }
    yield call(removeCartItem, { id });
  } catch (error) {}
}

function* watchFetchCartItems() {
  yield takeLatest(cartActionTypes.Fetch, fetchCartItems);
}

function* watchResetCartItems() {
  yield takeLatest(cartActionTypes.Reset, resetCartItems);
}

function* watchRemoveCartItem() {
  yield takeLatest(cartActionTypes.RemoveItem, removeCartItemSaga);
}

export function* cartSaga() {
  yield all([watchFetchCartItems(), watchResetCartItems(), watchRemoveCartItem()]);
}
