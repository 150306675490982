export interface MarketingStrategyDetailsModel {
  status: MarketingStrategyStatus;
  liveops: (Liveop | Product)[];
  stages: Stage[];
  gameScore: GameScore;
  goalCalculatorResult: GoalCalculatorResult;
  currentGameScore: CurrentGameScore;
  sumOfAchievedGoalAmount: number;
}

export interface CurrentGameScore {
  stageNumber: number;
  name: Name;
}

export enum MarketingStrategyStatus {
  Proposed = 'proposed',
  InExecution = 'in_execution',
  Archived = 'archived',
}

export enum Name {
  Launch = 'Launch',
  Planning = 'Planning',
  PostLaunch = 'Post-Launch',
  PreLaunch = 'Pre-Launch',
  PreProduction = 'Pre-Production',
  Production = 'Production',
  Testing = 'Testing',
}

export interface GameScore {
  totalScore: number;
  maxScore: number;
  gameStage: CurrentGameScore;
  createdAt: Date;
}

export interface GoalCalculatorResult {
  id: number;
  goalCategory: GoalCategory;
  goalValue: number;
}

export interface GoalCategory {
  id: number;
  referenceName: string;
  name: string;
  unit: string;
  summable: boolean;
}

export interface Liveop {
  executionStatus: ExecutionStatus;
  elementType: ElementType;
  tactic: Tactic;
}

export enum ElementType {
  Tactic = 'tactic',
  Product = 'product',
}

export enum ExecutionStatus {
  InProgress = 'in_progress',
  Done = 'done',
  Skipped = 'skipped',
}

export interface Tactic {
  type: Type;
  id: number;
  name: string;
  stages: CurrentGameScore[];
  description: string;
  image: null;
  segments: Segment[];
  author: null;
  authorAvatar: AuthoAvatar | null;
}

export interface AuthoAvatar {
  path: string;
}

export interface Segment {
  id: number;
  referenceName: string;
  name: string;
  icon: Icon;
}

export interface Icon {
  path: string;
}

export enum Type {
  LiveOps = 'live_ops',
  Marketing = 'marketing',
}

export interface Stage {
  stage: CurrentGameScore;
  elements: Liveop[];
}

export interface Product {
  productStatus: string;
  elementType: string;
  product: ProductClass;
}

export interface ProductClass {
  categories: {
    name: string;
  }[];
  description: string;
  formattedPrice: string;
  currency: string;
  id: number;
  name: string;
  price: number;
  image: Image;
  stages: Stage[];
}

export interface Image {
  path: string;
}

export interface Stage {
  stageNumber: number;
  name: string;
}
