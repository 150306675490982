import React from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import styles from './Arrows.module.scss';
import SVG from 'react-inlinesvg';
import { assetPath } from '@shared/utils/asset';
import clsx from 'clsx';

function Arrow({
  direction,
  children,
  disabled,
  onClick,
}: {
  direction: 'left' | 'right';
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx(styles.arrowButton, {
        [styles.leftBorder]: direction === 'left',
        [styles.rightBorder]: direction === 'right',
      })}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()} direction="left">
      <SVG src={assetPath('/icons/arrow_flat.svg')} />
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(!visibleElements.length && isLastItemVisible);
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()} direction="right">
      <SVG src={assetPath('/icons/arrow_flat.svg')} className={styles.arrowRight} />
    </Arrow>
  );
}
