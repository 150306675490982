import axios from 'axios';

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

interface ListParamsItem {
  id: number;
}

export const getCartItems = async () => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/cart`).then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const clearCartItems = async () => {
  try {
    return await axios.delete(`${API_URL}/api/game-dev/cart`).then((res) => res.data);
  } catch (error) {
    return [];
  }
};

export const removeCartItem = async ({ id }: ListParamsItem) => {
  try {
    return await axios.delete(`${API_URL}/api/game-dev/cart/items/${id}`);
  } catch (error) {
    return error;
  }
};

export const checkout = async (): Promise<{ url: string }> => {
  try {
    return await axios.get(`${API_URL}/api/game-dev/cart/checkout`).then((res) => res.data);
  } catch (error) {
    return error;
  }
};
