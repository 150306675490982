import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useLayout } from '@shared/metronic/layout/core';
import { DrawerComponent } from '@shared/metronic/assets/ts/components';
import { CartIcon } from '@shared/components';

const Content: React.FC = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();

  //location.pathname.indexOf stands for if url includes this fragment of url
  const isCart = useMemo(
    () =>
      location.pathname === '/cart' ||
      location.pathname === '/marketplace/thank-you' ||
      location.pathname.indexOf('/game-checker/dashboard') == 0,
    [location]
  );
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div
      id="kt_content_container"
      className={clsx(classes.contentContainer.join(' '), 'position-relative')}
    >
      {children}
      {!isCart ? <CartIcon /> : null}
    </div>
  );
};

export { Content };
