import { useEffect, useState, useRef } from 'react';

type THook<T extends HTMLElement> = [React.RefObject<T>, boolean];

const useHover = <T extends HTMLElement>(): THook<T> => {
  const [hovered, setHovered] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleMouseEnter = (): void => setHovered(true);
    const handleMouseLeave = (): void => setHovered(false);
    const node = ref && ref.current;

    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [ref]);

  return [ref, hovered];
};

export default useHover;
