import styles from './CartIcon.module.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { assetApiPath, assetPath } from '@shared/utils/asset';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState, useAppSelector } from '@app/setup';
import { CartModel } from '@app/models/CartModel';
import {
  FETCH_CART,
  cartActionTypes,
  selectorCartItems,
  setCartItems,
} from '@app/modules/cart/store';
import Counter from '@shared/components/Counter/Counter';
import { addToCart } from '@app/modules/products/services';
import { loadingSelectors } from '@shared/features/loading/loadingSlice';
import { ADD_PRODUCT_TO_CART } from '@app/modules/products/components/Product/Product';
import { addOne as addNotification } from '@shared/features/notifications/notificationsSlice';
import Loader from '../Loader/Loader';
import apiErrorParser from '@shared/utils/apiErrorParser';
import { AlertType } from '@shared/enums/AlertType';
import { checkout } from '@app/modules/cart/services';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type ItemsProps = {
  item: CartModel;
  formatter: Intl.NumberFormat;
  removeItem: (id: number) => void;
  onUpdate: (count: number) => void;
};

const CartIcon = () => {
  const { t } = useTranslation('products');
  const [show, setShow] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthorized = useSelector(({ auth }: RootState) => auth.user, shallowEqual);
  const cartItems = useAppSelector(selectorCartItems);
  const activeItems = useSelector(loadingSelectors.selectIds);
  const isLoading = useMemo(() => activeItems.includes(ADD_PRODUCT_TO_CART), [activeItems]);
  const isFetchCartLoading = useMemo(() => activeItems.includes(FETCH_CART), [activeItems]);
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: cartItems[0]?.product?.currency || 'USD',
      }),
    [cartItems]
  );
  const overlayRef = useRef(null);

  const handleOnMouseEnter = () => {
    if (cartItems.length === 0) return;
    setShow(true);
  };

  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const removeItem = (id: number) => {
    dispatch({ type: cartActionTypes.RemoveItem, payload: { id } });
  };

  const goToCheckout = async () => {
    try {
      setIsCheckoutLoading(true);
      const response = await checkout();

      if (response.url) {
        window.location.replace(response.url);
      }
    } catch (error) {
      const { message } = apiErrorParser(error, dispatch);

      dispatch(
        addNotification({
          id: 'cart.update',
          type: AlertType.Danger,
          message,
        })
      );
      setIsCheckoutLoading(false);
    }
  };

  const totalPrice = useMemo(() => {
    return cartItems.reduce((acc, item) => {
      const price = parseFloat(item.product.formattedPrice.replace(',', '.'));
      return acc + price * item.qty;
    }, 0);
  }, [cartItems]);

  const goToCart = () => {
    if (cartItems.length === 0) return;
    history.push('/cart');
  };

  const handleProductQuantityUpdate = useCallback(
    async (count: number, id: number) => {
      try {
        const updatedProductIds = cartItems.map(({ product, qty }) => {
          return {
            product: product.id,
            qty: product.id === id ? count : qty,
          };
        });
        const newCartItems = cartItems.map(({ product, qty }) => ({
          product,
          qty: product.id === id ? count : qty,
        }));

        dispatch(setCartItems(newCartItems));
        await addToCart(updatedProductIds);
      } catch (error) {
        const { message } = apiErrorParser(error, dispatch);

        dispatch(
          addNotification({
            id: 'cart.update',
            type: AlertType.Danger,
            message,
          })
        );
      }
    },
    [cartItems]
  );

  useEffect(() => {
    if (isAuthorized) {
      dispatch({ type: cartActionTypes.Fetch });
    }
  }, [isAuthorized]);

  const popover = useMemo(
    () => (
      <Popover
        id="popover-contained"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        style={{ width: '630px', maxWidth: 'unset' }}
      >
        <Popover.Body className={styles.popover}>
          <span className={styles.header}>{t('products.cart_contents', 'Cart contents')}</span>
          <div className={styles.products}>
            {isFetchCartLoading ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              cartItems?.map((item) => (
                <Item
                  key={item.product.id}
                  item={item}
                  formatter={formatter}
                  removeItem={removeItem}
                  onUpdate={(count: number) => handleProductQuantityUpdate(count, item.product.id)}
                />
              ))
            )}
          </div>
          <div className={styles.summary}>
            <span>
              {t('products.total_items', 'Total {{count}} items', { count: cartItems?.length })}:
              <span className={styles.price}>{formatter.format(totalPrice)}</span>
            </span>
            <button
              className="btn btn-primary btn-sm"
              onClick={goToCheckout}
              disabled={isCheckoutLoading || isFetchCartLoading}
            >
              {t('products.go_to_checkout', 'Go to checkout')}
            </button>
          </div>
        </Popover.Body>
      </Popover>
    ),
    [cartItems, isCheckoutLoading, isFetchCartLoading]
  );

  if (cartItems?.length === 0 && !location.pathname.includes('marketplace')) return <></>;

  return (
    <OverlayTrigger show={show} container={overlayRef} placement="bottom-end" overlay={popover}>
      <div
        className={clsx(styles.root, { [styles.noItems]: cartItems.length === 0 })}
        ref={overlayRef}
        onClick={goToCart}
        role="button"
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <div className={styles.cart}>
          <SVG src={assetPath('/icons/cart.svg')} />
          <span className={styles.quantity}>{isLoading ? <Loader /> : cartItems?.length}</span>
        </div>
        <span className={styles.price}>{formatter.format(totalPrice)}</span>
      </div>
    </OverlayTrigger>
  );
};

const Item: React.FC<ItemsProps> = ({ item, formatter, removeItem, onUpdate }) => {
  return (
    <div className={styles.item}>
      <div
        className={styles.closeButton}
        onClick={() => removeItem(item.product.id)}
        role="button"
      />
      <img src={assetApiPath(item.product.image?.path)} className={styles.cartImg} />
      <div className={styles.name}>
        <Link to={`/marketplace/list/${item.product.id}`}>{item.product.name}</Link>
      </div>
      <Counter quantity={item.qty} className={styles.counter} onUpdate={onUpdate} />
      <div className={styles.price}>
        {formatter.format(Number(item.product.formattedPrice) * item.qty)}
      </div>
    </div>
  );
};

export default CartIcon;
