import axios from 'axios';
import { AuthModel } from '@app/modules/auth/models/AuthModel';
import { UserModel } from '@app/modules/auth/models/UserModel';
import { UserPlanModel } from '../models/UserPlanModel';

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/game-dev/user/current-user`;
export const LOGIN_URL = `${API_URL}/api/game-dev/auth/login`;
export const GET_PACKETS = `${API_URL}/api/game-dev/subscription`;
export const GET_CURRENT_PLAN = `${API_URL}/api/game-dev/purchased-subscription`;
export const REGISTER_URL = `${API_URL}/api/game-dev/auth/register`;
export const REGISTER_CONFIRM_URL = `${API_URL}/api/game-dev/auth/confirm-register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/api/game-dev/auth/reset-password`;

export function login(username: string, password: string) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(
  nick: string,
  email: string,
  password: string,
  repassword: string,
  regulations: boolean
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    nick,
    email,
    password: {
      password,
      repassword,
    },
    regulations,
  });
}

export function registerConfirm(token: string) {
  return axios.put(`${REGISTER_CONFIRM_URL}/${token}`);
}

export function requestPassword(email: string) {
  return axios.post<{}>(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken(): Promise<UserModel> {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL).then((res) => res.data);
}

export async function getUserCurrentPlan(): Promise<UserPlanModel> {
  return axios.get(GET_CURRENT_PLAN).then((res) => res.data[0]);
}
