import React, { useState } from 'react';
import CH from 'react-apexcharts';

export interface ChartDataModel {
  name: string;
  data: number[];
}

enum Position {
  Top = 'top',
}

enum Align {
  Left = 'left',
}

type Props = {
  categories: string[];
  data: ChartDataModel[];
  borderRadius?: number;
};

const defaultProps = {
  borderRadius: 5,
};

const Chart = ({ categories, data, borderRadius }: Props) => {
  const [options] = useState({
    chart: {
      id: 'basic-bar',
      fontFamily: 'Red Hat Display", sans-serif',
      stacked: true,
      toolbar: {
        show: false,
      },
      foreColor: '#172047',
      parentHeightOffset: 0,
      offsetX: -10,
    },
    colors: ['#06B98E', 'rgba(23, 32, 71, 0.2)'],
    legend: {
      position: Position.Top,
      horizontalAlign: Align.Left,
      offsetX: -20,
      itemMargin: {
        horizontal: 16,
      },
      markers: {
        width: 12,
        height: 12,
        radius: 50,
        offsetY: 2,
        offsetX: -2,
      },
    },
    annotations: {
      yaxis: [
        {
          strokeDashArray: 0,
          borderColor: '#A2A6B5',
        },
      ],
    },
    plotOptions: {
      bar: {
        borderRadius: [borderRadius, borderRadius],
        columnWidth: '15%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: '#A2A6B5',
          fontSize: '9px',
        },
        offsetY: -6,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        offsetX: 6,
        offsetY: 2,
        style: {
          colors: '#A2A6B5',
          fontSize: '9px',
        },
      },
    },
    grid: {
      borderColor: '#ECEEFC',
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          xaxis: {
            labels: {
              rotateAlways: true,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: [4, 4],
              columnWidth: '25%',
            },
          },
        },
      },
    ],
  });

  const [series] = useState(data);

  return (
    <>
      <CH options={options} series={series} type="bar" height={240} />
    </>
  );
};

Chart.defaultProps = defaultProps;

export default Chart;
