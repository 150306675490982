import React, { useState } from 'react';
import clsx from 'clsx';
import type { FieldValues, UseFormRegister } from 'react-hook-form';
import { KTSVG } from '@shared/metronic/helpers';
import StrengthBar from './StrengthBar/StrengthBar';

type Props = {
  name: string;
  register?: UseFormRegister<FieldValues>;
  error?: string;
  placeholder?: string;
  label?: string;
  large?: boolean;
  small?: boolean;
  secondary?: boolean;
  strengthBar?: boolean;
  strengthBarValue?: string;
  [x: string]: any;
};

const defaultProps = {
  register: () => {},
  error: '',
  placeholder: '',
  label: '',
  large: false,
  small: false,
  secondary: false,
  strengthBar: false,
  strengthBarValue: '',
};
const InputPassword = ({
  name,
  register,
  error,
  placeholder,
  label,
  large,
  small,
  secondary,
  strengthBar,
  strengthBarValue,
  ...rest
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      {label && (
        <label htmlFor={`input-id-${name}`} className="fs-12 lh-f12 fw-bold mb-10">
          {label}
        </label>
      )}
      <div className="input-group has-validation">
        <input
          id={`input-id-${name}`}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          className={clsx(
            'form-control border-right-0 border-top-right-radius-0 border-bottom-right-radius-0',
            {
              'is-invalid': !!error,
              'form-control-lg': large,
              'form-control-sm': small,
              'form-control-solid': !secondary,
            }
          )}
          {...register(name)}
          {...rest}
        />
        <span
          className={clsx('input-group-text ms-0 border-left-0', {
            'input-group-text-solid': !secondary,
          })}
        >
          <button type="button" onClick={() => setShowPassword(!showPassword)}>
            <KTSVG path="/icons/eye.svg" className="svg-icon-16 svg-icon-gray-600" />
          </button>
        </span>
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
      {strengthBar && (
        <div className="mt-8">
          <StrengthBar value={strengthBarValue || ''} />
        </div>
      )}
    </div>
  );
};

InputPassword.defaultProps = defaultProps;

export default InputPassword;
