import React from 'react';
import { assetPath } from '@shared/utils/asset';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';

type Props = {
  children: React.ReactNode;
  type?: 'success' | 'info' | 'warning' | 'danger';
  className?: string;
  button?: React.ReactNode;
  icon?: boolean;
};

const defaultProps = {
  type: 'danger',
  className: '',
  button: null,
  icon: true,
};

const Alert = ({ children, type, className, button, icon }: Props) => {
  return (
    <div
      className={clsx(
        'alert border border-dashed d-flex align-items-center min-h-58',
        `bg-light-${type} border-${type}`,
        className
      )}
      role="alert"
    >
      {icon && (
        <div className="me-10 flex-shrink-0">
          <SVG src={assetPath(`/icons/alerts/${type}.svg`)} />
        </div>
      )}

      <div
        className={clsx(
          'fw-bold w-full flex-column flex-md-row d-flex align-items-center justify-content-between'
        )}
      >
        {children}
        {button && <div className="mt-12 mt-md-0">{button}</div>}
      </div>
    </div>
  );
};

Alert.defaultProps = defaultProps;

export default Alert;
