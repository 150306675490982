import styles from './PricingTableModal.module.scss';
import { FC, useState, useEffect, useMemo } from 'react';
import Modal from '@shared/partials/Modal/Modal';
import StripePricingTable from '@shared/partials/StripePricingTable/StripePricingTable';
import { useSelector } from 'react-redux';
import { RootState } from '@app/setup';
import { getStripePricingTables } from '@app/services/stripePricingTables';
import { useTranslation } from 'react-i18next';
import { PricingTableModel } from '@app/models/PricingTableModel';
import { PlanLevels } from '@app/modules/auth/models/UserPlanModel';

type Props = {
  show: boolean;
  handleClose?: () => void;
};

const PricingTableModal: FC<Props> = ({ show, handleClose = null }) => {
  const { t } = useTranslation(['plan']);
  const currentPlan = useSelector(({ auth }: RootState) => auth.plan);
  const user = useSelector(({ auth }: RootState) => auth.user);
  const [pricingTable, setPricingTable] = useState<PricingTableModel>(null);
  const isPlanExpiring = useMemo(
    () => currentPlan?.planDaysLeft <= 7 && currentPlan?.planDaysLeft > 0,
    [currentPlan?.planDaysLeft]
  );
  const planExpired = currentPlan?.planDaysLeft <= 0;

  const getPricingTables = async () => {
    const results = await getStripePricingTables();

    if (isPlanExpiring) {
      const currentTable = results.find((table) => Number(table.level) === currentPlan.level);
      setPricingTable(currentTable);
      return;
    }

    if (!currentPlan || planExpired) {
      const fullTable = results.find((table) => table.level === '1');
      setPricingTable(fullTable);
      return;
    }

    if (currentPlan.level === PlanLevels.BASIC) {
      const secondLevelTable = results.find((table) => table.level === '2');
      setPricingTable(secondLevelTable);
      return;
    }

    if (currentPlan.level === PlanLevels.PRO || currentPlan.level === PlanLevels.ADVANCED) {
      const thirdLevelTable = results.find((table) => table.level === '3');
      setPricingTable(thirdLevelTable);
      return;
    }
  };

  const getHeaderText = () => {
    if (!currentPlan) {
      return t('plan.new_plan', `Let's find the right plan for you`);
    }
    if (isPlanExpiring) {
      return t('plan.renew_your_plan', `Renew your {{plan}} account`, { plan: currentPlan.name });
    }
    return t('plan.upgrade_plan', 'Upgrade your {{plan}} account', { plan: currentPlan.name });
  };

  useEffect(() => {
    getPricingTables();
  }, []);

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      dialogClassName="modal-xl modal-dialog-scrollable position-relative"
    >
      <h4 className="fs-25 lh-f25 text-center mb-32">{getHeaderText()}</h4>
      {handleClose && <div className={styles.closeButton} onClick={handleClose} role="button" />}
      <StripePricingTable
        pricingTableId={pricingTable?.pricingTableId}
        publishableKey={pricingTable?.publishableKey}
        customerEmail={user?.email}
      />
    </Modal>
  );
};

export default PricingTableModal;
