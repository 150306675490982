import { format, parse, differenceInDays, formatDistance, addDays } from 'date-fns/esm';
import { DatepickerValue } from '@shared/components/Datepicker/Datepicker';

export const parseDate = (v: Date | string): Date => {
  if (typeof v === 'string') {
    return parse(v, 'yyyy-MM-dd HH:mm:ss', new Date());
  }

  return v;
};

export const formatDateToApi = (v: Date | string, withoutTime = false): string => {
  return format(parseDate(v), withoutTime ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss');
};

export const formatDateToView = (v: Date | string): string => {
  if (v) {
    return format(parseDate(v), 'dd/MM/yyyy');
  }

  return '';
};

export const getDaysBetweenDate = (
  startDate: Date | string,
  endDate: Date | string,
  zero = false
): number => {
  const days = differenceInDays(parseDate(endDate), parseDate(startDate));

  if (days < 0 && zero) {
    return 0;
  }

  return days;
};

export const distanceInDays = (v: Date | string): string => {
  return formatDistance(parseDate(v), new Date(), { addSuffix: true });
};

export const getTime = (v: Date | string): number => {
  return parseDate(v).getTime();
};

export const addDaysToDate = (v: Date | string, amount: number): Date => {
  return addDays(parseDate(v), amount);
};

export const getFirstAndLastDateOfMonth = (v: Date | string): [Date, Date] => {
  const date = parseDate(v);

  return [
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ];
};

export function getMonthDays(month: number, year: number): Date[];
export function getMonthDays(month: number, year: number, dateFormat?: string): string[];

export function getMonthDays(month: number, year: number, dateFormat?: string): Date[] | string[] {
  const [startDate, endDate] = getFirstAndLastDateOfMonth(new Date(year, month));

  if (dateFormat) {
    return new Array(endDate.getDate()).fill(null).map((number, index) => {
      return format(new Date(year, month, index + 1), dateFormat);
    });
  }

  return new Array(endDate.getDate()).fill(null).map((number, index) => {
    return new Date(year, month, index + 1);
  });
}

export function parseDatepickerDate(value: DatepickerValue): Date | null;
export function parseDatepickerDate(
  value: DatepickerValue,
  range: boolean
): [Date | null, Date | null] | null;

export function parseDatepickerDate(value: DatepickerValue, range?: boolean): DatepickerValue {
  if (range) {
    return value;
  }

  return value;
}
