import { GoalCategoryModel } from '@app/modules/game-checker/models/GoalCategoryModel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as services from '@app/modules/game-checker/services';
import apiErrorParser from '@shared/utils/apiErrorParser';
import { Modal } from '@shared/partials';
import { ButtonAction, Input, Select } from '@shared/components';
import StrategyChangeModal from '../StrategyChangeModal/StrategyChangeModal';
import yup from '@app/yup-i18n';

interface ChangeGoalModalInterface {
  gameId: number;
  gameName: string;
  show: boolean;
  clearModal: () => void;
}

const schema = yup.object().shape({
  goalCategoryId: yup.number().required(),
  goalValue: yup.number().integer().min(0).required(),
});

const ChangeGoalModal = ({ gameId, gameName, show, clearModal }: ChangeGoalModalInterface) => {
  const { t } = useTranslation(['common', 'gc']);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [goalCategories, setGoalCategories] = useState<GoalCategoryModel[]>([]);
  const [goalValue, setGoalValue] = useState<string>('');
  const [selectedGoalCategory, setSelectedGoalCategory] = useState<GoalCategoryModel | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    fetchGoalCategories();
  }, []);

  const handleConfirmSubmit = async () => {
    try {
      const responseValid = await schema.isValid({
        goalCategoryId: selectedGoalCategory.id,
        goalValue,
      });
      if (!responseValid) {
        await services.postChangeGoal(gameId, {
          goalCategoryId: selectedGoalCategory.id,
          goalValue: Number(goalValue),
        });
      } else {
        setShowConfirmModal(true);
      }
    } catch (error) {
      const { errors } = apiErrorParser(error);
      setError(errors[0].message);
    }
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
    clearModal();
    setError('');
  };

  const handleApproveConfirmModal = async () => {
    await services.postChangeGoal(gameId, {
      goalCategoryId: selectedGoalCategory.id,
      goalValue: Number(goalValue),
    });
    window.location.reload();
  };

  const handleDisapproveConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const fetchGoalCategories = async () => {
    try {
      const response = await services.fetchGoalCategories();
      setGoalCategories(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeInputGoalValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trimStart(); // Remove leading spaces
    if (/^[1-9]\d*$/.test(newValue) || newValue === '') {
      setGoalValue(newValue);
    }
  };

  return (
    <>
      <Modal customBody show={show} handleClose={handleCloseModal}>
        <div className="modal-body p-md-24">
          <div className="d-flex flex-column align-items-center">
            <h2 className="pb-24 fs-18">{t('gc:dashboard.modal_goal_header', 'Your Goal')}</h2>
            <span className="fs-9 pb-8">{t('gc:dashboard.modal_goal_paragraph', 'for game')}</span>
            <h6>{gameName}</h6>
            <div className="d-flex align-items-start py-16">
              <div className="col-8 px-8">
                <Select
                  name="change-goal-category"
                  items={goalCategories}
                  label={t('gc:dashboard.modal_goal_label', 'Select a target category')}
                  placeholder={''}
                  onChange={({ target: { value } }) =>
                    setSelectedGoalCategory(
                      goalCategories.find((el: GoalCategoryModel) => el.id === +value)
                    )
                  }
                  error={error}
                />
              </div>
              <div className="d-flex col-4 align-items-center flex-row">
                <div>
                  <Input
                    value={goalValue}
                    name="change-goal-value"
                    type="number"
                    label={t('gc:dashboard.modal_goal_value', 'Enter the value')}
                    onChange={handleChangeInputGoalValue}
                  />
                </div>
                <span className="d-lex px-8 align-self-end">
                  {selectedGoalCategory
                    ? goalCategories.find(
                        (el: GoalCategoryModel) => el.id === +selectedGoalCategory.id
                      )?.unit
                    : null}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex gap-16 justify-content-end mt-26">
            <button className="btn btn-outline mr-12 btn-sm" onClick={handleCloseModal}>
              {t('gc:dashboard.modal_goal_cancel', 'Cancel')}
            </button>
            <ButtonAction
              type="submit"
              onClick={handleConfirmSubmit}
              disabled={!selectedGoalCategory?.id || !goalValue || Number(goalValue) <= 0}
              className="btn btn-primary btn-sm"
            >
              {t('gc:dashboard.modal_goal_save', 'Save')}
            </ButtonAction>
          </div>
        </div>
      </Modal>
      <StrategyChangeModal
        type="soft"
        show={showConfirmModal}
        clearModal={handleDisapproveConfirmModal}
        handleAccept={handleApproveConfirmModal}
      />
    </>
  );
};

export default ChangeGoalModal;
