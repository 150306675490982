import { useEffect } from 'react';
import { useLocation } from 'react-router';

export interface KompassifyTourModel {
  path: string;
  id: string;
}

const useKompassify = (tours: KompassifyTourModel[] = []) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const handleLoad = (e) => {
      if (e.data.TYPE === 'KOMPASSIFY_BOOT_LOADER_IS_READY') {
        if (pathname.includes('dashboard')) {
          const tourId = tours.find((item) => item.path === '/dashboard')?.id;
          if (tourId) {
            //@ts-ignore
            window?.kompassifyLaunchTour(tourId, 0, true);
          }
        }
      }
    };

    window.addEventListener('message', handleLoad);
  }, []);
};

export default useKompassify;
