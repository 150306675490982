import React from 'react';
import style from '@app/modules/game-checker/components/Score/Score.module.scss';
import clsx from 'clsx';
import { Avatar } from '@shared/components';
import { GameScoreShortModel } from '@app/modules/game-checker/models/GameScoreShortModel';
import { assetApiPath } from '@shared/utils/asset';
import { formatDateToView } from '@shared/utils/date';
import { useTranslation } from 'react-i18next';

type Props = GameScoreShortModel;

const defaultProps = {};

const Score = ({ game, totalScore, maxScore, createdAt }: Props) => {
  const { t } = useTranslation(['gc']);

  return (
    <div
      className={clsx(
        style.score,
        'position-relative overflow-hidden p-12 p-sm-20 px-xl-12 px-xxxl-20 h-full'
      )}
    >
      <div
        className={clsx(
          style.content,
          'd-flex flex-column justify-content-between position-relative z-index-1'
        )}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Avatar
              avatar={assetApiPath(game?.thumbnailCover)}
              type="game"
              name={game?.name}
              className={style.avatar}
            />
          </div>
          <div className="text-end">
            <p className="text-success">{t('gc:c_score.title', 'Your score')}</p>
            <p className="fs-40 lh-f40 fs-sm-55 lh-sm-f55 fs-xl-32 lh-xl-f32 fs-xxl-55 lh-xxl-f55">
              {totalScore}
              <span
                className={clsx(
                  style.maxScore,
                  'fs-18 lh-f18 fs-sm-32 lh-sm-f32 fs-xl-18 lh-xl-f18 fs-xxl-32 lh-xxl-f32'
                )}
              >
                / {maxScore}
              </span>
            </p>
          </div>
        </div>
        <div>
          <h2 className="fs-18 lh-f18 mb-2">{game?.name}</h2>
          <p className={clsx(style.date, 'fs-12 lh-f12')}>
            {t('gc:c_score.result', 'Result on')}: {formatDateToView(createdAt)}
          </p>
        </div>
      </div>
    </div>
  );
};

Score.defaultProps = defaultProps;

export default Score;
