import React, { useEffect } from 'react';
import { ImageInputComponent } from '@shared/metronic/assets/ts/components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  setAvatar: (v, c?) => void;
  name: string;
  previewPath: string | null;
  emptyPath: string;
  accept?: string;
  description?: string | null;
};

const defaultProps = {
  accept: '.png, .jpg, .jpeg',
  description: null,
};

const AvatarEdit = ({ setAvatar, name, previewPath, emptyPath, accept, description }: Props) => {
  const { t } = useTranslation(['common']);

  useEffect(() => {
    const avatar = ImageInputComponent.createInsance();

    avatar.on('kt.imageinput.changed', function () {
      const file = avatar.getInputElement();

      setAvatar(file.files[0]);
    });

    avatar.on('kt.imageinput.canceled', function () {
      setAvatar(null, true);
    });

    avatar.on('kt.imageinput.removed', function () {
      setAvatar(null);
    });
  }, []);

  return (
    <div>
      <div
        className={clsx('image-input image-input-outline mb-12', {
          'image-input-empty': !previewPath,
        })}
        data-kt-image-input="true"
        style={{ backgroundImage: `url(${emptyPath}` }}
      >
        {previewPath ? (
          <div
            className="image-input-wrapper w-125px h-125px"
            style={{
              backgroundImage: `url(${previewPath}`,
            }}
          />
        ) : (
          <div className="image-input-wrapper w-125px h-125px" />
        )}
        <label
          className="btn btn-icon btn-circle btn-active-color-primary w-32 h-32 bg-white shadow"
          data-kt-image-input-action="change"
        >
          <i className="bi bi-pencil-fill fs-12" />

          <input type="file" name={name} accept={accept} />
          <input type="hidden" name={`${name}_remove`} />
        </label>

        <span
          className="btn btn-icon btn-circle btn-active-color-primary w-32 h-32 bg-white shadow"
          data-kt-image-input-action="cancel"
        >
          <i className="bi bi-x fs-23" />
        </span>
        <span
          className="btn btn-icon btn-circle btn-active-color-primary w-32 h-32 bg-white shadow"
          data-kt-image-input-action="remove"
        >
          <i className="bi bi-x fs-23" />
        </span>
      </div>
      <p className="fs-12 text-gray-400 mt-n12 fw-bold">
        {description
          ? description
          : t('common:avatar_edit.allowed_file_type', 'Allowed file types: png, jpg, jpeg.')}
      </p>
    </div>
  );
};

AvatarEdit.defaultProps = defaultProps;

export default AvatarEdit;
