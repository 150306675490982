export enum QuestionType {
  YesNo = 'YES_NO',
  MultipleTextInputs = 'MULTIPLE_TEXT_INPUTS',
  SelectMultiple = 'SELECT_MULTIPLE',
  Form = 'FORM',
  SelectCountries = 'SELECT_COUNTRIES',
  IntRange = 'INT_RANGE',
  SelectSingle = 'SELECT_SINGLE',
  SingleTextInput = 'SINGLE_TEXT_INPUT',
  Marketing = 'CUSTOM_STEP',
}
