import React from 'react';
import clsx from 'clsx';
import style from './ButtonIcon.module.scss';
import { Loader } from '@shared/components';
import SVG from 'react-inlinesvg';

type Props = {
  type?: 'button' | 'submit';
  variant?: 'primary' | 'danger';
  iconPath: string;
  disabled?: boolean;
  loading?: boolean;
  [x: string]: any;
};

const defaultProps = {
  type: 'button',
  variant: 'primary',
  disabled: false,
  loading: false,
};

const ButtonIcon = ({ type, variant, iconPath, disabled, loading, ...rest }: Props) => {
  return (
    <button
      type="button"
      className={clsx(style.btn, {
        [style.primary]: variant === 'primary',
        [style.danger]: variant === 'danger',
      })}
      disabled={loading}
      {...rest}
    >
      {loading ? <Loader /> : <SVG src={iconPath} />}
    </button>
  );
};

ButtonIcon.defaultProps = defaultProps;

export default ButtonIcon;
