import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addOne as addLoading,
  removeOne as removeLoading,
} from '@shared/features/loading/loadingSlice';
import { FileModel } from '@shared/models/FileModel';

export interface ContentBlockModel {
  id: number;
  type: string;
  value: string | null;
  file: FileModel | null;
  sort: number;
  contentBlocks: ContentBlockModel[];
}

export interface ContentModel {
  id: number;
  title: string;
  contentBlocks: ContentBlockModel[];
}

const useContent = (fetchCallback: () => Promise<ContentModel>, loadingId = 'FETCH_CONTENT') => {
  const dispatch = useDispatch();
  const [content, setContent] = useState<ContentModel>(null);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: loadingId }));

        const response = await fetchCallback();

        setContent(response);
      } catch (error) {
      } finally {
        dispatch(removeLoading(loadingId));
      }
    })();
  }, []);

  return {
    content,
  };
};

export default useContent;
