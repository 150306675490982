import React from 'react';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '@shared/metronic/helpers';
import { useLayout } from '@shared/metronic/layout/core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  off?: boolean;
  externalBlank?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  off = false,
                                          externalBlank = false,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  const handleClick = (path) => {
    if (document.body.hasAttribute('data-kt-aside-minimize')) {
      document.body.removeAttribute('data-kt-aside-minimize');
    } else {
      if(externalBlank) {
        window.open(to, '_blank', 'noopener,noreferrer')
      } else {
        history.push(path);
      }
    }
  };

  return (
    <div className="menu-item">
      <button
        type="button"
        className={clsx('menu-link without-sub', { active: isActive, 'is-off': off })}
        onClick={() => handleClick(to)}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)} />}
        <span className="menu-title">{title}</span>
      </button>
      {children}
    </div>
  );
};

export { AsideMenuItem };
