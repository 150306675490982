import React from 'react';
import style from '@app/modules/game-checker/components/Hint/Hint.module.scss';
import clsx from 'clsx';
import { assetPath } from '@shared/utils/asset';
import SVG from 'react-inlinesvg';
import { HintModel } from '@app/modules/game-checker/models/HintModel';

type Props = HintModel & {
  suggestion: boolean;
};

const defaultProps = {
  suggestion: false,
};

const Hint = ({ name, description, link, suggestion }: Props) => {
  return (
    <div
      className={clsx('d-flex p-12 p-sm-20 p-lg-12 p-xl-20 h-full', {
        [style.warning]: !suggestion,
        [style.suggestion]: suggestion,
      })}
    >
      <div
        className={clsx(
          'd-flex justify-content-center align-items-center flex-shrink-0 me-12 me-sm-20 me-lg-12 me-xl-20',
          style.icon,
          {
            [style.warningIcon]: !suggestion,
            [style.suggestionIcon]: suggestion,
          }
        )}
      >
        <SVG src={assetPath(`/icons/${suggestion ? 'bulb' : 'warning'}.svg`)} />
      </div>
      <div>
        <h3 className="mb-10">{name}</h3>
        <p className="fs-12 lh-f12">
          <span className="me-4">{description}</span>
          {link && (
            <a href={link} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

Hint.defaultProps = defaultProps;

export default Hint;
