import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as authCrud from '@app/modules/auth/redux/AuthCRUD';
import { ButtonAction, Input, InputPassword } from '@shared/components';
import { Trans, useTranslation } from 'react-i18next';
import { translateFieldError } from '@shared/utils/forms';
import { translateErrorMessage } from '@shared/utils/api';
import yup from '@app/yup-i18n';

type Inputs = {
  nick: string;
  email: string;
  password: string;
  repassword: string;
  regulations: boolean;
};

const schema = yup
  .object()
  .shape({
    nick: yup.string().required(),
    email: yup.string().required().email(),
    password: yup.string().required(),
    repassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'common:fields.validation.password_must_match'),
    regulations: yup.mixed().oneOf([true], 'common:fields.validation.regulations'),
  })
  .required();

const Register = ({ setAlert }) => {
  const { t } = useTranslation(['common', 'auth']);

  const {
    handleSubmit,
    register,
    watch,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const watchPassword = watch('password');

  const onSubmit = async ({ nick, email, password, repassword, regulations }) => {
    try {
      await authCrud.register(nick, email, password, repassword, regulations);

      setAlert({
        type: 'success',
        content: t(
          'auth:register.success_alert',
          'You have been registered, check your email to confirm registration'
        ),
      });

      reset();
    } catch ({
      response: {
        status,
        data: { error },
      },
    }) {
      if (error) {
        let { data: errorFields } = error;
        const errorMsg = translateErrorMessage(error, t);

        if (status !== 404 && errorFields) {
          if (errorFields.password) {
            errorFields = {
              ...errorFields,
              ...errorFields.password,
            };
          }

          Object.keys(errorFields).forEach((v) => {
            setError(
              // @ts-ignore
              v,
              {
                type: 'api',
                message: errorFields[v][0],
              },
              { shouldFocus: true }
            );
          });
        }

        if (status !== 404 && errorMsg) {
          setAlert({
            type: 'danger',
            content: errorMsg,
          });

          return;
        }
      }

      setAlert({
        type: 'danger',
        content: t('common:alerts.error'),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="w-full">
      <div className="mb-20">
        <Input
          name="nick"
          register={register}
          error={errors?.nick && translateFieldError(errors.nick, t)}
          placeholder={t('auth:register.nick_placeholder', 'Nick')}
        />
      </div>
      <div className="mb-20">
        <Input
          name="email"
          register={register}
          error={errors?.email && translateFieldError(errors.email, t)}
          placeholder={t('auth:register.email_placeholder', 'Email')}
        />
      </div>
      <div className="mb-20">
        <InputPassword
          name="password"
          register={register}
          error={errors?.password && translateFieldError(errors.password, t)}
          placeholder={t('auth:register.password_placeholder', 'Password')}
          strengthBar
          strengthBarValue={watchPassword}
        />
      </div>
      <div className="mb-20">
        <InputPassword
          name="repassword"
          register={register}
          error={errors?.repassword && translateFieldError(errors.repassword, t)}
          placeholder={t('auth:register.repassword_placeholder', 'Repeat password')}
        />
      </div>
      <div className="mb-20">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            id="regulations"
            {...register('regulations')}
          />
          <label className="form-check-label" htmlFor="regulations">
            <Trans
              i18nKey="auth:register.regulations"
              defaults="I accept the <termsLink>terms of use</termsLink> and <privacyLink>privacy policy</privacyLink>."
              components={{
                termsLink: (
                  <a
                    href={t(
                      'auth:register.regulations_termsLink',
                      'https://blog.playstrict.com/terms'
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
                privacyLink: (
                  <a
                    href={t(
                      'auth:register.regulations_privacyLink',
                      'https://blog.playstrict.com/privacy'
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </label>
        </div>
        {errors?.regulations && (
          <div className="invalid-feedback d-block">
            {translateFieldError(errors.regulations, t)}
          </div>
        )}
      </div>
      <div className="row justify-content-center mt-32">
        <div className="col-sm-9">
          <ButtonAction type="submit" className="w-full" loading={isSubmitting}>
            {t('auth:register.submit_button', 'Sign up')}
          </ButtonAction>
        </div>
      </div>
    </form>
  );
};

export default Register;
