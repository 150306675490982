import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { assetApiPath } from '@shared/utils/asset';
import { AlertType } from '@shared/enums/AlertType';
import { FileModel } from '@shared/models/FileModel';
import { addOne as addNotification } from '@shared/features/notifications/notificationsSlice';
import Uppy, { Restrictions, UppyFile } from '@uppy/core';
import { useUppy, Dashboard, DragDrop, FileInput } from '@uppy/react';
import ImageEditor from '@uppy/image-editor';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import clsx from 'clsx';

type Props = {
  initialFiles: FileModel[];
  onChangeFiles: (files: UppyFile[]) => void;
  restrictions?: Restrictions & {
    minWidth?: number | null;
    maxWidth?: number | null;
    width?: number | null;
    height?: number | null;
    square?: boolean;
  };
  error?: string;
  label?: string;
  placeholder?: string;
  [x: string]: any;
};

const defaultProps = {
  restrictions: {},
  error: '',
  label: '',
  placeholder: '',
};

async function getImgDimension(imgFile): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(imgFile.data);
    const img = new Image();
    img.onload = function () {
      URL.revokeObjectURL(img.src);
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.src = url;
  });
}

const UploadImage = ({
  initialFiles,
  onChangeFiles,
  restrictions,
  error,
  label,
  placeholder,
}: Props) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const uppy = useUppy(() => {
    return new Uppy({
      restrictions,
      locale: {
        strings: {
          save: t('common:upload_image_save_button', 'Save'),
          cancel: t('common:upload_image_cancel_button', 'Cancel'),
        },
      },
    })
      .use(ImageEditor, {
        id: 'ImageEditor',
        quality: 1,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: true,
        },
      })
      .on('files-added', async (files) => {
        await validateDimensions(files);
        handleChangeFiles();
      })
      .on('file-removed', () => {
        handleChangeFiles();
      })
      .on('restriction-failed', (file, error) => {
        dispatch(
          addNotification({
            id: `upload.${new Date().getTime()}`,
            type: AlertType.Danger,
            message: error.message,
          })
        );
      })
      .on('file-editor:complete', (file) => {
        if (file.meta.apiId) {
          uppy.setFileMeta(file.id, {
            apiEdited: true,
          });
        }

        handleChangeFiles();
      });
  });

  const validateDimensions = async (files: UppyFile[]) => {
    for (const file of files) {
      const dimensions = await getImgDimension(file);
      let valid = true;

      if (restrictions.minWidth && dimensions.width < restrictions.minWidth) {
        valid = false;
      }

      if (restrictions.maxWidth && dimensions.width > restrictions.maxWidth) {
        valid = false;
      }

      if (restrictions.width && dimensions.width !== restrictions.width) {
        valid = false;
      }

      if (restrictions.height && dimensions.height !== restrictions.height) {
        valid = false;
      }

      if (restrictions.square && dimensions.width !== dimensions.height) {
        valid = false;
      }

      if (!valid) {
        dispatch(
          addNotification({
            id: `upload.${new Date().getTime()}`,
            type: AlertType.Danger,
            message: file.name,
            description: t('common:upload_file_dimensions_alert', 'Bad file dimensions'),
          })
        );

        uppy.removeFile(file.id);
      }
    }
  };

  const fillInitialFiles = async () => {
    try {
      for (const file of initialFiles) {
        const blob = await fetch(assetApiPath(file.path)).then((response) => response.blob());

        uppy.addFile({
          name: file.userOriginalFilename,
          type: blob.type || file.mimeType,
          data: blob,
          source: 'api',
          meta: {
            apiId: file.id,
            apiEdited: false,
          },
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    fillInitialFiles();
  }, []);

  const handleChangeFiles = () => {
    onChangeFiles(uppy.getFiles());
    forceUpdate();
  };

  return (
    <div>
      {label && <label className="fs-12 lh-f12 fw-bold mb-10">{label}</label>}
      <div
        className={clsx('custom-uppy', {
          'custom-uppy--single': uppy.getFiles().length === 1,
        })}
      >
        {uppy.getFiles().length ? (
          <div>
            <Dashboard
              uppy={uppy}
              plugins={['ImageEditor']}
              width="100%"
              height="360px"
              hideUploadButton
              hideCancelButton
            />
            {restrictions.maxNumberOfFiles !== 1 && (
              <div className="mt-10">
                <FileInput
                  uppy={uppy}
                  locale={{
                    strings: {
                      chooseFiles: t('common:upload_image_add_more_button', 'Add more'),
                    },
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <DragDrop
            width="100%"
            height="360px"
            note={placeholder}
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: t(
                  'common:upload_image_drop_placeholder',
                  'Drop files here or click to upload.'
                ),
              },
            }}
          />
        )}
      </div>
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};

UploadImage.defaultProps = defaultProps;

export default UploadImage;
