import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { translateFieldError } from '@shared/utils/forms';
import * as auth from '@app/modules/auth/redux/AuthRedux';
import * as authCrud from '@app/modules/auth/redux/AuthCRUD';
import { ButtonAction, Input, InputPassword } from '@shared/components';
import { translateErrorMessage } from '@shared/utils/api';
import yup from '@app/yup-i18n';

type Inputs = {
  username: string;
  password: string;
};

const schema = yup
  .object()
  .shape({
    username: yup.string().required().email(),
    password: yup.string().required(),
  })
  .required();

const Login = ({ setAlert }) => {
  const { t } = useTranslation(['common', 'auth']);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async ({ username, password }) => {
    try {
      const response = await authCrud.login(username, password);

      dispatch(auth.actions.login(response.data.token));
    } catch ({
      response: {
        status,
        data: { error },
      },
    }) {
      const errorMsg = translateErrorMessage(error, t);

      if (status !== 404 && errorMsg) {
        setAlert({
          type: 'danger',
          content: errorMsg,
        });

        return;
      }

      setAlert({
        type: 'danger',
        content: t('common:alerts.error'),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="w-full">
      <div className="mb-20">
        <Input
          name="username"
          register={register}
          error={errors?.username && translateFieldError(errors.username, t)}
          placeholder={t('auth:login.email_placeholder', 'Email')}
          large
        />
      </div>
      <div className="mb-20">
        <InputPassword
          name="password"
          register={register}
          error={errors?.password && translateFieldError(errors.password, t)}
          placeholder={t('auth:login.password_placeholder', 'Password')}
          large
        />
      </div>
      <div className="mb-20 text-end">
        <Link to="/auth/forgot-password" className="fs-12 lh-f12">
          {t('auth:login.forgot_link', 'Forgot your password?')}
        </Link>
      </div>
      <div className="row justify-content-center mt-32">
        <div className="col-sm-9">
          <ButtonAction type="submit" className="w-full" loading={isSubmitting} large>
            {t('auth:login.submit_button', 'Log in')}
          </ButtonAction>
        </div>
      </div>
    </form>
  );
};

export default Login;
