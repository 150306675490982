import React from 'react';
import style from '@app/modules/game-checker/shared/Report/Revshare/Revshare.module.scss';
import clsx from 'clsx';
import { assetPath } from '@shared/utils/asset';
import { useTranslation } from 'react-i18next';

const Revshare = () => {
  const { t } = useTranslation(['gc']);

  return (
    <div
      className={clsx(style.revshare, 'p-20 py-40')}
      style={{ backgroundImage: `url(${assetPath('/images/revshare-bg.png')})` }}
    >
      <div
        className={clsx(
          style.content,
          'd-flex flex-column justify-content-between h-full text-white'
        )}
      >
        <p className="fs-25 lh-f25 fw-bold">
          {t('gc:c_report.revshare_title', 'RevShare program')}
        </p>
        <p>
          {t(
            'gc:c_report.revshare_description',
            'To apply, you must obtain a Growth Generator result of at least 80/100.'
          )}
        </p>
      </div>
    </div>
  );
};

export default Revshare;
